import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Container, Button, Grid, IconButton, TextField } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { checkemail } from './Model';
import ModalBoxForChangeEmail from './ModalBoxForChangeEmail';
const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    heights: {
        height: '55px',
        backgroundColor: '#1890FF',
        borderRadius: '0px',
        '&:focus,&:hover': {
            backgroundColor: '#3C82B7'
        }
    },
    heightfield: {
        height: '55px',
        '&:focus': {
            borderColor: '#3C82B7'
        }
    }
});

const ChangeMobileNumber: React.FC = () => {
    const classes = useStyles();
    const [rows, setrows] = React.useState<any>([]);
    const [load, setload] = React.useState<true | false>(false);
    const [email, setemail] = React.useState<string>('');
    const [uploaddata, setuploaddata] = React.useState<any>({
        id: 'dksd',
        phone: '5632',
        name: 'saksjkaj'
    })
    const [open, setopen] = React.useState<false | true>(false);

    const submithandler = () => {
        setload(true);
        let newarr: any = [];
        checkemail(email).then((response: any) => {
            const newrow = response;
            if (response.length > 0) {
                newrow.map((row: any) => {
                    let newdata = {
                        id: row.id,
                        firstName: row.firstName,
                        lastName: row.lastName,
                        phone: row.phone,
                        email: row.email,
                        birthDate: row.birthDate
                    }
                    newarr.push(newdata);
                })
                setload(false);
            }

            setrows(newarr);
            setload(false);
        }).catch((e) => {
            setload(false);
        })
        // setload(false);
    }

    const editbtn = (id: any, phone: any, name: any) => {
        setopen(true);
        setuploaddata({
            id: id,
            phone: phone,
            name: name,
            email: email
        })
    }

    React.useEffect(() => {
        if (!open) {
            setrows([]);
            setload(false);
            setemail('');
        }
    }, [open])


    return (
        <form onSubmit={(e)=>{
            e.preventDefault();
        }} >
        <Container maxWidth='lg'>
            <ModalBoxForChangeEmail {...uploaddata} open={open} setopen={setopen} classes={classes} />

            <Grid container spacing={3} justify='flex-end'>
                <Grid item xl={4} lg={4} md={5} >
                    <TextField fullWidth className={classes.heightfield} id="outlined-basic" value={email} onChange={(e) => {
                        setemail(e.target.value);
                    }} label="Email" variant="outlined"
                    />
                </Grid>
                <Grid item>
                    <Button variant="contained" type="submit" color="primary" className={classes.heights}
                        onClick={() => {
                            submithandler();
                            setload(true);
                        }}
                    >
                        Search
                    </Button>
                </Grid>
            </Grid>
            <TableContainer style={{ marginTop: "30px" }} component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center"><b>Name</b></TableCell>
                            <TableCell align="center"><b>E-Mail</b></TableCell>
                            <TableCell align="center"><b>Phone Number</b></TableCell>
                            <TableCell align="center"><b>DOB</b></TableCell>
                            <TableCell align="center"><b>Action</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            !load || rows.length ?
                                (
                                    rows.length > 0 ? rows.map((row: any) => (
                                        <TableRow key={row.id}>
                                            <TableCell align="center">{row.firstName + ' ' + row.lastName}</TableCell>
                                            <TableCell align="center">{row.email}</TableCell>
                                            <TableCell align="center">{row.phone}</TableCell>
                                            <TableCell align="center">{row.birthDate}</TableCell>
                                            <TableCell align="center">
                                                <IconButton aria-label="Edit" onClick={() => { editbtn(row.id, row.phone, row.firstName + ' ' + row.lastName) }}>
                                                    <EditIcon color='primary' />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )) : (
                                        <TableRow key=''>
                                            <TableCell colSpan={5} align="center">No Data Found</TableCell>
                                        </TableRow>)
                                ) :
                                (
                                    <TableRow key=''>
                                        <TableCell colSpan={5} align="center"><CircularProgress color="primary" /></TableCell>
                                    </TableRow>
                                )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Container >
        </form>
    );
}

export default ChangeMobileNumber;
