import * as React from 'react';

import { useAdminDispatch } from '../../provider'
import {
    Button,
    Card,
    Form,
    Layout,
    Select,
    Space,
    Table,
    Typography,
} from 'antd';
import { addNpDestionation, getAdminConfigDaywise, getCountry, getNpDestionation } from '../../api';
import { styles } from '../AppointmentList/styles';
import { npDestTableColumn } from '../AppointmentList/constants';
import styled from 'styled-components';
import { Breakpoints } from '../../../../dictionaries';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert'
import MoreActions from './MoreActions';

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;


const HeaderBox = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
 

  @media (max-width: ${Breakpoints.sm}px) {
    flex-direction: column;
    align-items: start;
  }
`;


const Listing : React.FC = () => {

	const { SetServiceData} = useAdminDispatch();
	const [getdata, setgetdata] = React.useState(false);
	const [rowdata, setrowdata] = React.useState<any>([]);
	const [loading, setLoading] = React.useState(false);
	const [loadings, setLoadings] = React.useState(false);
    const [alertbox, setalertbox] = React.useState<{ type: string, msg: string, open: true | false }>({ type: '', msg: '', open: false })

    
    const [locations, setLocations] = React.useState<any>(null);
    const [addLocations, setAddLocations] = React.useState<any>(null);
	// const classes = stylesForListing();
    
	React.useEffect(() => {
		getdatas();
        getNpDest();
	}, []);

    const getdatas = async() => {
        const country =  await getCountry();
       
        
        if(country?.data?.status === 200 ) {
            console.log(country?.data?.data,country?.data?.data?.length)
            setLocations(country?.data?.data)
        }
    }

    const getNpDest = async() =>{
        getNpDestionation().then((res:any) => {
            if(res?.data?.status === 200){
                SetServiceData(res?.data?.data);
				setrowdata(res?.data?.data);
				setgetdata(true);
				getAdminConfigDaywiseColumn();
			}
			else{
				console.log(res);
			}
		}).catch(err => { console.log(err); })
    }

    const handleSearch=()=>{
        
    };
	
	const [renderTableColumn, setRenderTableColumn] = React.useState<any[]>([]); 

	const renderActionsMenu = (action: any, record: any) => (
        <MoreActions
            record={record}
            isNpDest={true}
            getNpDest={getNpDest}
            handleSearch={handleSearch}
        />
    );

	async function getAdminConfigDaywiseColumn() {
		setLoading(true);
		const tableColumnData = npDestTableColumn(renderActionsMenu);
		setRenderTableColumn(tableColumnData);
		let response = await getAdminConfigDaywise({
		  email: JSON.parse(window.localStorage.getItem('userDetails')!).email,
		});
  
		if (response.data.status == 200) {
		  if (response.data.data.length > 0) {
			if (
			  response.data.data[0].daywiseconfig.hideandshowcolumn.length > 0
			) {
			  setRenderTableColumn(
				npDestTableColumn(renderActionsMenu)
			  );
			  setLoading(false);
			}
		  } else {
			const tableColumnData = npDestTableColumn(renderActionsMenu);
			setRenderTableColumn(tableColumnData);
			setLoading(false);
		  }
		} else {
		  const tableColumnData = npDestTableColumn(renderActionsMenu);
		  setRenderTableColumn(tableColumnData);
		  setLoading(false);
		}
	  }

	React.useEffect(() => {
		
		getAdminConfigDaywiseColumn();
		
	  }, []);
	
      const handleClose = () => {
        setalertbox({ open: false, type: '', msg: '' })
      }

      function Alert(props: any) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
      }

	return (
        <Layout
            style={{
                marginTop: '100px',
                backgroundColor: '#FFF',
                border: '1px solid #f3eeee',
                boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
            }}
        >
            <Snackbar
                open={alertbox.open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                autoHideDuration={3000}
            >
                <Alert onClose={handleClose} severity={alertbox.type}>
                    {alertbox.msg}
                </Alert>
            </Snackbar>
            <div
                style={{
                    borderRadius: '3px',
                    background: 'linear-gradient(60deg, #00acc1, #00acc1)',
                    boxShadow:
                        '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)',
                    margin: '0px 25px',
                    marginTop: '-25px',
                }}
            >
                <Title level={4} style={{ margin: '24px 16px', color: '#FFF' }}>
                    NP Destinations
                </Title>
            </div>
            <Content style={styles.content}>
                <Space
                    direction="vertical"
                    style={styles.spaceWidth}
                    size="middle"
                >
                    <Card bodyStyle={styles.headCards}>
                        <HeaderBox style={{ width: '80%',margin:'auto' }}>
                            <Form.Item
                                label="Location"
                                name="location"
                                style={{ width: '50%', marginBottom: 'auto' }}
                            >
                                <Select
                                    style={{ width: '100%' }}
                                    disabled={loading}
                                    allowClear
                                    placeholder="Please select"
                                    showArrow
                                    onChange={async (value: any) => {
                                        const locData: any = await locations.find(
                                            (e: any) => e.value === value
                                        );
                                        console.log(locData);
                                        setAddLocations(locData);
                                    }}
                                >
                                    {locations?.map((location: any) => (
                                        <Option
                                            key={location.value}
                                            value={location.value}
                                        >
                                            {location.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Button
                                type="primary"
                                loading={loadings}
                                onClick={async () => {
                                    if (
                                        addLocations !== null &&
                                        addLocations !== undefined
                                    ) {
                                        setLoadings(true);
                                        console.log('datas', addLocations);
                                        const addData: any = await addNpDestionation(
                                            addLocations
                                        );
                                        console.log('addData', addData);
                                        if (addData?.data?.status === 200) {
                                            setalertbox({
                                                open: true,
                                                type: 'success',
                                                msg:
                                                    'NP location added successfully',
                                            });
                                            getNpDest();
                                            setLoadings(false);
                                        } else {
                                            setLoadings(false);
                                            setalertbox({
                                                open: true,
                                                type: 'error',
                                                msg: 'Location already exists',
                                            });
                                            console.log(
                                                'datas',
                                                addData?.data?.message
                                            );
                                        }
                                    }
                                }}
                            >
                                + Add NP destination location
                            </Button>
                        </HeaderBox>
                    </Card>
                    <Card>
                        <div
                            style={{
                                padding: '25px 75px',
                                width: '80%',
                                margin: 'auto',
                            }}
                        >
                            <Table
                                size="small"
                                columns={renderTableColumn as any}
                                bordered
                                dataSource={(rowdata as any) || []}
                                pagination={{
                                    pageSizeOptions: ['10', '25', '50', '100'],
                                    showSizeChanger: true,
                                }}
                                loading={loading}
                                //scroll={{ x: 1500 }}
                                rowKey={(row: any) => row.key}
                                //  rowSelection={rowSelection}
                                //  rowClassName={(record: any) => record.testType === 'Rapid Test'? classes.rapid: record.testType === 'Express Test'? classes.express:record.testType==='Antigen Test'?classes.antigen:classes.standard
                                //  }
                            />
                        </div>
                    </Card>
                </Space>
            </Content>
        </Layout>
    );
}
export default Listing;




