import * as React from 'react';
import { Formik, Form, } from 'formik';
import { Container, Grid, Paper, Typography, CircularProgress } from '@material-ui/core';
import Select from '../../../shared/components/form/Select';
import { stylesForListing } from './styles';
import { useAdminState, useAdminDispatch } from './../../provider'
import { slotstartdatefor30Value, slotdurationValue, slotcapacity,   editSlotConfigurationDummy } from './Model';
import styled from 'styled-components';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { getNewSlots } from './../../../admin/api';
import { setContext } from '@sentry/react';
import { Radio } from 'antd';


const ButtonContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
flex-wrap: nowrap;
padding-top: 15px;
padding-bottom: 15px;
width: 100%;
justify-content: space-between;
justify-content: left;
gap: 20%;
};
@media (min-width: 450px) { flex-direction: row; }
`;
const Confirmbtn = styled.button`
width: 50%;
border: 0;
align-items: center;
text-align: center;
border: 1px solid #1890ff;
line-height: 1.6;
padding: 5px;
font-size: 14px;
font-weight: bold;
cursor: pointer;
display: inline-block;
background-color: #1890ff;
color: #ffff;
border-radius: 4px;
`;

const Returnbtn = styled.button`
  width: 50%;
  border: 0;
  align-items: center;
  text-align: center;
  border: 1px solid  #1890ff;
  line-height: 1.6;
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
  background-color: #fff;
  color: #1890ff;
  border-radius: 4px;
`;

const Box = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height:510px;
`;

const ErrorText = styled.div`
  font-size: 15px;
  margin-bottom: 15px;
  color: red;
  opacity: 1;
  transition: opacity 0.15s ease-in-out;
`;
  
const Editlocation: React.FC = () =>{
  const {editLocation } = useAdminState();
  const {  goToNextStep,goToPrevStep } = useAdminDispatch();
  const [isNp, setIsNp] = React.useState(false)
  const {updateAdminSlots} = useAdminDispatch()
  const [loader, setloader] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>('');
  const [testTypeDropdown, ] = React.useState<any>([
		{
			label:'Standard PCR Test',
			value:'standard'
		},
		{
			label:'Express PCR Test',
			value:'express'
		},
		{
			label:'Rapid PCR Test',
			value:'rapid'
		},
		{
			label:'Antigen Test',
			value:'antigen'
		},
  	]);
	const [myTest, setMyTest] = React.useState<any>(testTypeDropdown[0].value);
	const [data, setData] = React.useState<any>([
	{
	  startTime:'' ,
	  endTime:'' ,
	  periodDuration: '',
	  available: 24,
	  day: 0,
	  isDone:false
	},
	{
	  startTime:'' ,
	  endTime:'' ,
	  periodDuration: '',
	  available: 24,
	  day: 1,
	  isDone:false
	},
	{
	  startTime:'' ,
	  endTime:'' ,
	  periodDuration: '',
	  available: 24,
	  day: 2,
	  isDone:false
	},
	{
	  startTime:'' ,
	  endTime:'' ,
	  periodDuration: '',
	  available: 24,
	  day: 3,
	  isDone:false
	},
	{
	  startTime:'' ,
	  endTime:'' ,
	  periodDuration: '',
	  available: 24,
	  day: 4,
	  isDone:false
	},
	{
	  startTime:'' ,
	  endTime:'' ,
	  periodDuration: '',
	  available: 24,
	  day: 5,
	  isDone:false
	},
	{
	  startTime:'' ,
	  endTime:'' ,
	  periodDuration: '',
	  available: 24,
	  day: 6,
	  isDone:false
	}
	]);
    const [countData,setcountData]=React.useState(0)

	const getSlotsData = async () => {
		setloader(true)
		let datas: any = {
			testType: myTest,
			locationId:editLocation?.qbenchCustomerId
		}
		// const slotsDetail: any = await getSlotsConfigurationss(datas)
		const slotsDetail: any = await getNewSlots(datas)
		let slotList = [];
        let count: any = 0;

		for(let i=0;i<=6;i++) {
			let len = slotsDetail?.data[0]?.slotsByDay[i]?.length
			let start = slotsDetail?.data[0]?.slotsByDay[i][0]?.startTime
			let end = slotsDetail?.data[0]?.slotsByDay[i][len - 1]?.endTime
			let avl = slotsDetail?.data[0]?.slotsByDay[i][0]?.available
			let period = slotsDetail?.data[0]?.slotsByDay[i][0]?.periodDuration
            console.log('check1',start);
		
			slotList.push({
				startTime: start && start!==undefined && start!==null ? start : '',
				endTime:  end && end!==undefined && end!==null ? end : '',
				periodDuration: period && period!==undefined && period!==null ? period : '',
				available:   avl && avl!==undefined && avl!==null ? avl : 0,
				day: i,
				isDone:start && start!==undefined && start!==null ? true : false
			});

            if(start && start!==undefined && start!==null) count++
            
            setcountData(count)
                
		}
		console.log('slotList',slotList);
		setData(slotList);
        setIsNp(editLocation?.isNpDestination || false);
		setloader(false);
  	}
	  
	  const getNewAPI = async () => {
        const response = await getNewSlots({
    
            testType: myTest,
             locationId: editLocation?.qbenchCustomerId
         })
         updateAdminSlots(response.data);
    }
	  

	React.useEffect(()=>{
		const content = document.getElementsByClassName('jss3');
		if(content && content.length) {
			content[0].scrollTop = 0;
		}
		
		getNewAPI()
		if(editLocation?.qbenchCustomerId && myTest )
		getSlotsData()
		
	},[editLocation, myTest])

  	const classes = stylesForListing();
	  

	 
  
	return (
		<Container maxWidth='md'>
			<Grid container justify='center'>
				<Grid xl={8} lg={8} md={10} sm={12} xs={12} item >
					<Paper elevation={0}>
						<Formik
							initialValues={{ 
                                data: data, 
                                countData:countData 
                            }}
							onSubmit={async (e)=>{
                                let err: any = false;
                                for (let item of e.data) {
                                    let hasErr = false;
                                    
                                    if(item.isDone === true) {
                                        if( item.startTime === '' || item.startTime === undefined || item.startTime === null ) {
                                            hasErr = true;
                                            setError('Start time is missing');
                                            console.log('checking', 2)
                                        }
                                        else if( item.endTime === '' || item.endTime === undefined || item.endTime === null) {
                                            hasErr = true;
                                            setError('End time is missing');
                                            console.log('checking', 3)
                                        }
                                        else if( item.periodDuration === '' || item.periodDuration === undefined || item.periodDuration === null) {
                                            hasErr = true;
                                            setError('Period duration is missing');
                                            console.log('checking', 4)
                                        }
                                        else if( item.available === 0 || item.available === undefined || item.available === null) {
                                            hasErr = true;
                                            setError('Available slot is missing');
                                            console.log('checking', 5)
                                        }

                                        if(hasErr === true) {
                                            err = true;
                                            break;
                                        }
                                    } else{
                                        item.startTime = '' 
                                        item.endTime = '' 
                                        item.periodDuration =  ''
                                        item.available =  0
                                        item.isDone = false
                                    }

                                    
                                }
                                
                                if(!err){
                                    setError('');
                                    setLoading(true);
                                    await editSlotConfigurationDummy(data, myTest, editLocation?.qbenchCustomerId, editLocation?.cPartnerID, isNp).then((resdata: any) => {
										if (resdata.status) {
											console.log('succ', resdata)
                                            setLoading(false);
                                            goToNextStep();
											// setNext(true)
										}
										else {
											console.log('error')
                                            setLoading(false);
										}
										}).catch(err => {
                                            setLoading(false);
										});
                                    
                                }
                            }}
							enableReinitialize
						>
							<Form>
								<Typography 
									variant="subtitle1"
									color="initial" 
									className={classes.fontweight700}
								>
									
									Location Name: {editLocation?.name}
								</Typography>
								<Typography 
									variant="subtitle2" 
									color="initial"
								>
									<b>Edit timeslot configuration</b>
									<br></br>
									Hours of operation and timeslots are configured by Test Type. First select the test type and then modify the attributes as needed.
								</Typography>
								<br />

								<Typography
									variant="subtitle2"
									color="initial"
									className={classes.fontweight700}
								>
										Test Type*
								</Typography>
								<Select name='myTest'
									options={testTypeDropdown}
                                    defaultValue={testTypeDropdown[0]} 
									onChange={(e) => {
										console.log('checking2',e);
										setMyTest(e);
									}} 
								/>
								
								
								{loader ? (
                                    <Box>
                                        <CircularProgress/> 
                                    </Box>
                                )
								:
                                // countData === 0 ? (
                                //     <Box>
                                //         <h4>No data available</h4>
                                //     </Box>
                                // )
                                // : 
								(
									<div>
										<Typography 
											variant="subtitle2" 
											color="initial"  
											className={classes.fontweight700}
										>
											Hours of Operation *
										</Typography>

										<Table className={classes.table} id={myTest} >
											<TableHead>
												<TableRow>
													<TableCell>Open?</TableCell>
													<TableCell>Day</TableCell>
													<TableCell>Start Time</TableCell>
													<TableCell >End Time</TableCell>
													<TableCell>Minutes per Slot</TableCell>
													<TableCell># Of Slots</TableCell>
												</TableRow>
											</TableHead>

											

											<TableBody>
											
												{data.map((item: any,i: any) => {
													return (
														<TableRow>
															<TableCell>  
																<input 
																	type="checkbox" 
																	defaultChecked={data[i]['isDone'] === false ? false : true} 
																	onChange={(e) => {
                                                                        if(e.target.checked){
                                                                            data[i] = {
                                                                                "startTime": data[i].startTime,
                                                                                "endTime": data[i].endTime,
                                                                                "periodDuration": data[i].periodDuration ? '30' : '',
                                                                                "available":  data[i].available ? data[i].available  : 0,
                                                                                "day": i,
                                                                                "isDone":e.target.checked
                                                                            }
                                                                            console.log('check',data[i])
                                                                        } else{
                                                                            data[i] = {
                                                                                "startTime": '',
                                                                                "endTime": '',
                                                                                "periodDuration": '',
                                                                                "available":  0,
                                                                                "day": i,
                                                                                "isDone":e.target.checked
                                                                            }
                                                                            console.log('check',data[i])
                                                                        }
																	}} 
																/>
															</TableCell>
															<TableCell>
															{(item.day === 0 ? 'Sun' : item.day === 1 ? 'Mon' : item.day === 2 ? 'Tues' : item.day === 3 ? 'Wednes' : item.day === 4 ? 'Thurs' : item.day === 5 ? 'Fri' : item.day === 6 ? 'Satur' : '')}day
															</TableCell>
															<TableCell>
																<select
																	defaultValue={data[i]['startTime'] === '' && data[i]['startTime']!==undefined ? '' : data[i]['startTime']} 
																	onChange={(e) => {
																		data[i] = {
																			"startTime": e.target.value,
																			"endTime": data[i].endTime,
																			"periodDuration": data[i].periodDuration,
																			"available": data[i].available ? data[i].available : 0,
																			"day": i,
                                                                            "isDone":data[i].isDone
																		}
																		
																	}}
																>
																{slotstartdatefor30Value.map((slot: any) => 
																	<option value={slot.value} selected={slot.value == data[i]['startTime']}>
																		{slot.label}
																	</option>
																)}
																</select> 
															
															</TableCell>
															<TableCell>
									
																<select
																	defaultValue={data[i]['endTime'] === '' && data[i]['endTime'] !== undefined ? '' : data[i]['endTime']} 
																	onChange={(e) => {
																		data[i] = {
																			"startTime": data[i].startTime,
																			"endTime": e.target.value,
																			"periodDuration": data[i].periodDuration,
																			"available": data[i].available ? data[i].available : 0,
																			"day": i,
                                                                            "isDone":data[i].isDone
																		}
																		// handleHoursOfOperation(e.target.value, myTest, item, 'startTime')
																	}}
																>
																{slotstartdatefor30Value.map((slot) =>
																	<option value={slot.value} selected={data[i].endTime}>
																		{slot.label}
																	</option>)}
																</select> 
															</TableCell>
															<TableCell>
																<select
																	defaultValue={data[i]['periodDuration'] === '' && data[i]['periodDuration'] !==undefined ? '' : data[i]['periodDuration']}
																	onChange={(e) => {
																		data[i] = {
																			"startTime": data[i].startTime,
																			"endTime":  data[i].endTime,
																			"periodDuration": e.target.value,
																			"available": data[i].available ? data[i].available : 0,
																			"day": i,
                                                                            "isDone":data[i].isDone
																		}
																	}}
																>
																{slotdurationValue.map((slot) =>
																	<option value={slot.value} selected={slot.value === data[i].periodDuration}>
																		{slot.label}
																	</option>
																)}
																</select> 
															</TableCell>
															<TableCell>
																<select
																	defaultValue={data[i]['available'] === 0 && data[i]['available']!==undefined ? 0 :  data[i]['available']} 
																	onChange={(e) => {
																		data[i] = {
																			"startTime": data[i].startTime,
																			"endTime":  data[i].endTime,
																			"periodDuration": data[i].periodDuration,
																			"available": parseInt(e.target.value),
																			"day": i,
                                                                            "isDone":data[i].isDone
																		}
																		// handleHoursOfOperation(e.target.value, myTest, item, 'startTime')
																	}}
																	// onChange={(e) => handleHoursOfOperation(e.target.value, myTest, item, 'capacity')}
																>
																{slotcapacity(30).map((slot: any) =>
																	<option value={slot.value} selected={slot.value === data[i]['available']}>
																		{slot.label}
																	</option>
																)}
																</select> 
															</TableCell>
														</TableRow>
													)
													
												})}
											</TableBody>
										</Table>

										<br />
                                        <Typography 
											variant="subtitle2" 
											color="initial"  
											className={classes.fontweight700}
										>
											Does this location provide Nasopharyngeal (NP) testing ?
										</Typography>
                                        <Radio.Group onChange={()=>setIsNp(!isNp)} value={isNp}>
                                            <Radio value={true}>Yes</Radio>
                                            <Radio value={false}>No</Radio>
                                        </Radio.Group>
										<br /><br />
									</div>
								)
								}
									
                                {error && <ErrorText >{error}</ErrorText>}

								<ButtonContainer >
									<Returnbtn type="button" onClick={() => goToPrevStep()} >  Back  </Returnbtn>
									<Confirmbtn type="submit">  
										{loading === true ? 'Processing...' : 'Next'}  
									</Confirmbtn>
								</ButtonContainer>
							</Form>
						</Formik>
					</Paper>
				</Grid>
			</Grid>
		</Container>
	);


}

export default Editlocation;