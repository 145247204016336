export const invoceColums =[
  'Check In',
  'Notes',
  'Test Type',
  'Appt Date',
  'Appt Time',
  'PNR #',
  'Flight #',
  'Confirmation No',
  'Status',
  'No Show',
  'Collection Method',
  'VIP Slot',
  'Rapid',
  'Sample ID',
  'First Name',
  'Last Name',
  'Email',
  'DOB',
  'Phone Number',
  'Airline',
  'Travel Type',
  'Destination',
  'Flight Date and Time',
  'Classroom',
  'Results',
  'Price'
]
export const initialSelect =[
  'Test Type',
  'Appt Date',
  'Appt Time',
  'PNR #',
  'Flight #',
  'Confirmation No',
  'Status',
  'First Name',
  'Last Name',
  'Results',
  'Price'
]