import { format, isBefore, parse } from 'date-fns';
import xlsx from 'node-xlsx';
import { saveAs } from 'file-saver';
import { firebase } from '../../firebase';
import { config } from '../../config';
import { FirebaseLocation } from './models';
import { getPeriodsFromSchedule, Last7Days } from '../../utils';
import { Location } from './models';
import { filter, orderBy, uniqBy } from 'lodash';
import axios from 'axios';
const periods = getPeriodsFromSchedule(config.schedule);
const Reportperiods = getPeriodsFromSchedule(config.Reportschedule);

export const getAllOpenedLocations = () =>
    firebase
        .firestore()
        .collection(config.firestoreCollections.locations)
        .get()
        .then((snapshot) => {
            let locationArray: any = [];
            snapshot.docs
                .map((doc) => doc.data())
                .map((loc: any) => locationArray.push(loc));
            locationArray = orderBy(locationArray, ['name'], 'asc');
            return locationArray;
        });
export const getOpenedLocations = () =>
  firebase
    .firestore()
    .collection(config.firestoreCollections.locations)
    .where('isOpened', '==', true)
    .get()
    .then((snapshot) => {
      let locationArray: any = [];
      snapshot.docs
        .map((doc) => doc.data() as FirebaseLocation)
        .map((loc: any) => {
          if (isBefore(loc.visibleSince.toDate(), new Date())) {
            locationArray.push({
              ...loc,
              startDate: format(loc.startDate.toDate(), config.dateFormat),
            });
          }
        });
      locationArray = orderBy(locationArray, ['name'], ['asc']);
      locationArray = uniqBy(locationArray, function (e: any) {
        return e.qbenchCustomerId;
      });
      return locationArray;
    });

function generateReport(appointments: any, locationName?: string) {
  const data = [
    ['Date', 'First Name', 'Last Name', 'Phone', 'Flight Date And Time'],
    ...appointments.reduce((acc: any[], appt: any) => {
      const a = acc;
      const row = [
        `${appt.date} ${
          appt.slot?.period !== undefined
            ? Reportperiods[appt.slot?.period].label
            : ''
        }`,
        appt.firstName,
        appt.lastName,
        appt.phone,
        appt.departureDateAndTime,
      ];
      a.push(row);
      return a;
    }, []),
  ];
  const buffer = xlsx.build([{ name: 'mySheetName', data: data, options:{} }]);
  const blob = new Blob([buffer]);
  saveAs(blob, `report${locationName ? `-${locationName}` : ''}.xlsx`);
}

export const generateAppointmentsReport = async (location: Location) => {
  const pastDates: Array<any> = Last7Days();

  const today = new Date();
  const previousday = new Date(today);
  previousday.setDate(previousday.getDate() - 7);
  const previousdate = format(previousday, 'LL/dd/yyyy');

  firebase
    .firestore()
    .collection(config.firestoreCollections.qbenchacknowledgement)
    .where('slot.date', '<=', previousdate)
    .get()
    .then((snapshot) => {
      const res = snapshot.docs.map((doc) => doc.data());

      const apptsByDate = res.sort((a, b) => {
        if (a.date === b.date) {
          const aPeriod = periods[a.slot.period];
          const bPeriod = periods[b.slot.period];

          if (aPeriod && bPeriod) {
            return aPeriod.startTime.getTime() - bPeriod.startTime.getTime();
          }
        }

        const aDate = parse(a.date, config.dateFormat, new Date());
        const bDate = parse(b.date, config.dateFormat, new Date());

        return aDate.getTime() - bDate.getTime();
      });

      var output = filter(apptsByDate, (user) => {
        return user.slot?.locationId == location.qbenchCustomerId; // && user.slot.date >= todate
      });

      generateReport(output, location.name);
    });
};

export const generateAllAppointmentsReport = async (location: Location) => {
  const pastDates: Array<any> = Last7Days();
  firebase
    .firestore()
    .collection(config.firestoreCollections.qbenchacknowledgement)
    .where('location.qbenchCustomerId', '==', location.qbenchCustomerId)
    .get()
    .then((snapshot) => {
      const res = snapshot.docs.map((doc) => doc.data());

      const apptsByDate = res.sort((a, b) => {
        // if (a.date === b.date) {
        //   const aPeriod = periods[a.slot.period];
        //   const bPeriod = periods[b.slot.period];

        //   if (aPeriod && bPeriod) {
        //     return aPeriod.startTime.getTime() - bPeriod.startTime.getTime();
        //   }
        // }

        const aDate = parse(a.date, config.dateFormat, new Date());
        const bDate = parse(b.date, config.dateFormat, new Date());

        return aDate.getTime() - bDate.getTime();
      });

      generateReport(apptsByDate, location.name);
    });
};

export const downloadAllReports = async () => {
  try {
    firebase
      .firestore()
      .collection('locations')
      .where('isOpened', '==', true)
      .get()
      .then((snapshot: any) => {
        const periods = getPeriodsFromSchedule(config.schedule);

        snapshot.docs.forEach((doc: any) => {
          const location = doc.data();
          firebase
            .firestore()
            .collection('appointments')
            .where('location.qbenchCustomerId', '==', location.qbenchCustomerId)
            .get()
            .then((snapshot: any) => {
              const res = snapshot.docs.map((doc: any) => doc.data());

              const apptsByDate = res.sort((a: any, b: any) => {
                if (a.date === b.date) {
                  const aPeriod = periods[a.slot.period];
                  const bPeriod = periods[b.slot.period];

                  if (aPeriod && bPeriod) {
                    return (
                      aPeriod.startTime.getTime() - bPeriod.startTime.getTime()
                    );
                  }
                }

                const aDate = parse(a.date, config.dateFormat, new Date());
                const bDate = parse(b.date, config.dateFormat, new Date());

                return aDate.getTime() - bDate.getTime();
              });

              generateReport(apptsByDate, location.name);
            });
        });
      });
  } catch (err) {
    console.error('checkResults', err);
  }
};

export const getAllAppointmentsCreatedOnDate = async (date?: string) =>
  firebase
    .functions()
    .httpsCallable('getAllAppointmentsCreatedOnDate')(date)
    .then((res) => generateReport(res.data));

export const getApptReport = (data:any) => {
  return firebase.functions().httpsCallable('generateAllAppointmentsReport')(data);
};
// export const getApptReport = async (location: any) => {
//   axios({
//     method: 'post',
//     url: `${config.baseURL}/generateAllAppointmentsReport`,
//     data: { data: { locationId: location.qbenchCustomerId } },
//   }).then(function (response: any) {
//     if (response.status == 200) {
//       generateApptReport(response.data.result.appointments);
//       return true;
//     }
//   });
// };

export const generateApptReport = (appointments: any, locationName?: string) => {
  const data = [
    [
      'FirstName',
      'LastName',
      'PatientIdentifier',
      'DOB',
      'Gender',
      'Race',
      'Ethnicity',
      'Patient_Street_Address',
      'Apartment_Number',
      'City',
      'State',
      'Zipcode',
      'PatientPhoneNumber',
      'Patient Email',
      'OrderingFacility',
      'Referring Physician',
      'Physician address',
      'Physician City',
      'Physician State',
      'Physician Zipcode',
      'Physician phone number',
      'Accession #',
      'Specimen Collection Date',
      'Specimen Received Date',
      'Specimen Type',
      'Test reported date',
      'Test Code_LOINC',
      'Test name',
      'Result',
      'PerformingFacility',
      'CLIA',
      'Pregnancy Status',
    ],
    ...appointments.reduce((acc: any[], appt: any) => {
      const a = acc;
      const row = [
        `${
            appt.samples !== undefined
                ? appt.samples['X_PATIENT_FIRST_NAME']
                : ''
        }`,
        `${
            appt.samples !== undefined
                ? appt.samples['X_PATIENT_LAST_NAME']
                : ''
        }`,
        '',
        `${appt.samples !== undefined ? appt.samples['X_PATIENT_DOB'] : ''}`,
        appt.sex,
        appt.race,
        appt.ethnicity,
        `${
          appt.address !== undefined
            ? appt.address['address']
            : ''
        }`,

        `${appt.address !== undefined ? appt.address['address'] : ''}`,
        `${appt.address !== undefined ? appt.address['city'] : ''}`,
        `${appt.address !== undefined ? appt.address['state'] : ''}`,
        `${appt.address !== undefined ? appt.address['zipCode'] : ''}`,
        appt.phone,
        appt.email,
        'Work Site Lab',
        '',
        '',
        '',
        '',
        '',
        '',
        appt.custom_formatted_id,
        `${appt.date}`,
        `${
          appt.samples !== undefined &&
          appt.samples !== null &&
          appt.samples.time_of_collection !== undefined &&
          appt.samples.time_of_collection !== null
            ? appt.samples.time_of_collection
            : ''
        }`,
        `${
          appt.samples !== undefined &&
          appt.samples !== null &&
          appt.samples.accessioning_type !== undefined && 
          appt.samples.accessioning_type !== null
            ? appt.samples.accessioning_type['value']
            : ''
        }`,
        `${
          appt.tests !== undefined &&
          appt.tests !== null &&
          appt.tests.complete_date !== undefined &&
          appt.tests.complete_date !== null &&
          appt.tests.complete_date !== ''
            ? appt.tests.complete_date ?? ''
            : ''
        }`,
          `${
            appt.tests !== undefined &&
            appt.tests !== null &&
            appt.tests.assay !== undefined &&
            appt.tests.assay !== null &&
            appt.tests.assay['title'] !== undefined &&
            appt.tests.assay['title'] !== null
              ? appt.tests.assay['title'] ===
                'Atila iAMP NAAT RT-PCR COVID-19 Detection'
                ? '94500-6'
                : appt.tests.assay['title'] ===
                  'Quidel Lyra  NAAT RT-PCR COVID-19 Detection'
                ? '94559-2'
                : appt.tests.assay['title'] ===
                  'Biorad DD PCR'
                ? '94533-7'
                : appt.tests.assay['title'] ===
                  'LumiraDx SARS-CoV-2 RNA STAR NAAT RT-PCR Detection'
                ? '94559-2'
                : appt.tests.assay['title'] ===
                  'SELGO COVID-19 SARS-Cov-2 by NAAT RT-PCR Detection'
                ? '94500-6'
                : appt.tests.assay['title'] ===
                  'Accula NAAT SARS-CoV-2 (COVID-19) Rapid RT-PCR Testing'
                ? '95409-9'
                : appt.tests.assay['title'] ===
                  'Cepheid Xpert® Xpress SARS-CoV-2 NAAT RT-PCR'
                ? '94500-6'
                : appt.tests.assay['title'] ===
                  'Abbott BinaxNOW COVID-19 Ag CARD'
                ? '94558-4'
                : appt.tests.assay['title'] ===
                  'INDICAID COVID-19 Antigen Test'
                ? '94558-4'
                 :appt.tests.assay['title'] ===
                'Celltrion DiaTrustTM COVID-19 Ag Rapid Antigen Test'?
                '95209-3'
                : ''
              : ''
          }`,
        `${appt.accessioning_type !== undefined &&
          appt.accessioning_type !== null
          ? appt.accessioning_type : ''
        }`,

        `${appt.tests !== undefined &&
          appt.tests !== null 
          ? appt.tests['results'] : ''
        }`,
        `${
            appt.location !== undefined &&
            appt.location !== null &&
            appt.location.performingfacility !== undefined &&
            appt.location.performingfacility !== null
              ? appt.location.performingfacility
              : ''
          }`,
        `${
        appt.location !== undefined &&
        appt.location !== null &&
        appt.location.CLIA !== undefined &&
        appt.location.CLIA !== null
            ? appt.location.CLIA
            : ''
        }`,
        'NA',
      ];

      a.push(row);

      return a;
    }, []),
  ];

  const buffer = xlsx.build([{ name: 'mySheetName', data: data, options:{} }]);
  const blob = new Blob([buffer]);

  saveAs(
    blob,
    `LAC_WorkSiteLab_Neg_${format(new Date(), config.fileDateFormat)}.xlsx`
  );
}

export const getSlotsByLocationId = async (locationId: any) =>
  firebase
    .functions()
    .httpsCallable('getSlotsByLocationId')(locationId)
    .then((res: any) => {
      if (res.data) {
        return res.data.slots[0].slots;
      } else {
        return [];
      }
    });

// export const getSlotsConfigurations = async () =>
//   firebase.functions().httpsCallable('getSlotsConfigurations')();

  export const getDiscountAmountDetails = async (selectedFromDate: any,selectedToDate:any) => {
    try {
      return firebase
        .functions()
        .httpsCallable('getDiscountAmountDetails')({
          fromdate: selectedFromDate,
          todate:selectedToDate
        })
        .then((res: any) => {
          return res;
        });
    } catch (error) {}
  };
  

export const generateInsuranceAndStripeReport=(appointments: any) =>{
  const data = [
      ['Date','Billing Type', 'Location','Amount'],
      ...appointments.reduce((acc: any[], appt: any) => {
          const a = acc;
              const row = [
                  appt.date,
                  `${appt.paymentIntentId.length >= 25 ? 'Credit Card':'Insurance' } `,
                  appt.location?.name,
                  appt.amount && appt.amount || 0
              ];
              a.push(row);
          return a;
      }, []),
  ];
  const buffer = xlsx.build([{ name: 'mySheetName', data: data, options:{} }]);
  const blob = new Blob([buffer]);
  saveAs(blob, "revenue_report.xlsx");
}

export const getInsurancePersonsDetails = async (params: any) =>
  firebase
    .functions()
    .httpsCallable('getInsurancePersonsDetails')(params);

export const generateInsuranceAndStripeReportCount=(appointments: any) =>{
  const data = [
      ['Date','Location Name', 'Credit Card','Insurance'],
      ...appointments.reduce((acc: any[], appt: any) => {
          const a = acc;
              const row = [
                  appt.date,
                  appt.locationName,
                  appt.isStripePayment,
                  appt.isInsurance,
              ];
              a.push(row);
          return a;
      }, []),
  ];
  const buffer = xlsx.build([{ name: 'mySheetName', data: data, options:{} }]);
  const blob = new Blob([buffer]);
  saveAs(blob, "appointmentCount_report.xlsx");
}

export const getNewSlots = async (params: any) => {
  return firebase
    .functions()
    .httpsCallable('getNewSlots')(params);
}

export const getEmployeeCoupon = async (data: any) => {
  return firebase.functions().httpsCallable('getEmployeeCoupon')(data);
}