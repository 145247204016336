
import React, { useState } from 'react';
import { Button, Popconfirm } from 'antd';
import { useAdminDispatch, useAdminState } from '../../../provider';
import { getAppointmentdata, updateIscheckedIn, userCheckin } from '../../../api';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { parse } from 'date-fns';
import { config } from '../../../../../../src/config';
import CheckInModal from './CheckInModal';
import PrintSampleLabels from './PrintSampleLabels';
import moment from 'moment';
function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface Props {
    qbenchId: number[];
    disabledCheckin: boolean;
    rowCheckIn: boolean;
    handlePrintModal: () => void;
    selectedRowKeys: any;
    params: any;
    SerachAppointment: (params: any) => void;
    childComp?: React.ReactNode;
    handleSearch?: () => void;
}

enum SendState {
    Idle,
    InProgress,
    Success,
    Error,
}

const stateToLabelMap = {
    [SendState.InProgress]: 'Processing ...',
    [SendState.Error]: 'Check In',
    [SendState.Success]: 'Checked In',
    [SendState.Idle]: 'Check In',
};

const Check_In: React.FC<Props> = ({
    qbenchId,
    disabledCheckin,
    rowCheckIn,
    handlePrintModal,
    selectedRowKeys,
    params,
    SerachAppointment,
    handleSearch,
    childComp,
}) => {
    const [state, setState] = useState<SendState>(SendState.Idle);
    const [loading, setLoading] = useState(false);
    const [btnloading, setBtnLoading] = useState<boolean>(false);
    const [alertbox, setalertbox] = React.useState({
      open: false,
      type: '',
      msg: '',
    });
    const { getPrintSampleLabels, appointments } = useAdminState();
    const { setEditAppointment, SetPrintSampleLabel,setAppointments } = useAdminDispatch();
    const [printModalOpen, setPrintModalOpen] = React.useState(false);
  
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [multipleCheckIn, setmultipleCheckIn] = useState(false)
    let selectRowdata: any = getPrintSampleLabels;
  
    const handleModalCancel = () => {
      setBtnLoading(false);
      setState(SendState.Idle);
      setEditAppointment(null);
      SetPrintSampleLabel([]);
    };
    const handleModalConfirm = async () => {
      //setIsModalClosed(true);
      try {
        if (qbenchId) {
            setalertbox({
                open: true,
                type: 'info',
                msg: 'Check-In Process is in progress...',
            });
          const qbenchIdData: any = await userCheckin({ qbenchIds: qbenchId });
          if (qbenchIdData.status === 200)
          setState(SendState.Success);
          await Promise.all(
              selectRowdata.map(async (rowData: any) => {
                  return await updateIscheckedIn({
                      id: rowData.id,
                      qbench: null,
                      autoCheckIn: false,
                  });
              })
          );
        }
        handlePrintModal();
        SerachAppointment(params);
        setState(SendState.Success);
        setBtnLoading(false);

        let tableDatas: any = appointments;
        tableDatas = appointments?.map((data: any) => {
            let rowData: any = data;
            if (qbenchId.includes(data.qbenchId) && data.isCanceled !== true) {
                let intreval = (data.testType === 'Standard Test') ? 24 : data.testType === 'Express Test' ? 12 : 0;
                const checkInTime = moment(new Date()).add(18.5, 'hours').format('MM/DD/YYYY hh:mm A');
                const checkInExpTime =
                    intreval > 0
                        ? moment(new Date()).add(intreval + +18.5, 'hours').format('MM/DD/YYYY hh:mm A')
                        : null;
                rowData = {
                    ...rowData,
                    isCheckedIn: true,
                    checkInExpTime: checkInTime,
                    checkInTime: checkInExpTime,
                };
            }
            return rowData;
        });
        setAppointments(tableDatas);
      } catch (error) {
        console.log(error);
        setBtnLoading(false);
        setState(SendState.Idle);
        setalertbox({ open: true, type: 'error', msg: ' Internal Error ' });
      }
    };
    const handleConfirm = async () => {
      setState(SendState.InProgress);
      console.log('selectRowdatacheckin', params);
  
      setModalVisible(true);
      //setIsModalClosed(false);
      try {
        setBtnLoading(true);
        setState(SendState.InProgress);
  
        // fetching the appointment data
        if (selectRowdata[0]?.confirmationId) {
          await getAppointmentdata({
            confirmationId: selectRowdata[0]?.confirmationId,
          }).then(async (response) => {
            if (response.data.length > 0) {
              if (response.data && response.data.length) {
                response.data[0]?.minors?.map((e: any) => {
                  e.birthDate =
                    e.birthDate !== null &&
                    e.birthDate !== undefined &&
                    Object.keys(e.birthDate).length > 0
                      ? parse(e.birthDate, config.dateFormat, new Date())
                      : new Date();
                });
              }
              setEditAppointment(response.data);
            }
            else
            {
              setalertbox({open:true,type:'warning',msg:'Appointment is Not Found in the Collection'});
              setBtnLoading(false)
              setState(SendState.Idle);
              setModalVisible(false);
            }
          });
        }
      } catch (error) {
        console.log(error);
        setBtnLoading(false);
        setState(SendState.Idle);
        setalertbox({ open: true, type: 'error', msg: ' Internal Error ' });
      }
    };
  
    function renderCheckInButton() {
      if (rowCheckIn && state === SendState.Idle) {
        return (
            <>
                <Popconfirm
                    title={'Are you sure you want to check this customer in?'}
                    onConfirm={handleConfirm}
                    okText="Yes"
                    cancelText="Cancel"
                    disabled={disabledCheckin}
                >
                    <Button
                        block
                        style={{
                            width: '100px',
                            backgroundColor: disabledCheckin
                                ? '#979797'
                                : '#1890ff',
                            color: 'white',
                        }}
                        disabled={
                            disabledCheckin ||
                            stateToLabelMap[state] === 'Checked In'
                        }
                    >
                        {stateToLabelMap[state]}
                    </Button>
                </Popconfirm>
            </>
        );
      } else if (
        rowCheckIn === false &&
        selectRowdata.length > 1
      ) {
        return (
          <>
            <Button
              block
              style={{
                width: '120px',
                backgroundColor: disabledCheckin ? '#979797' : '#1890ff',
                color: 'white',
              }}
              onClick={() => {
                setmultipleCheckIn(true);
                setState(SendState.InProgress);
                handleModalConfirm();
              }}
              disabled={disabledCheckin}
            >
              {stateToLabelMap[state]}
            </Button>
          </>
        );
      }
    }
    return (
        <>
            {rowCheckIn === false &&
                selectRowdata.length > 1 &&
                multipleCheckIn && (
                    <PrintSampleLabels
                        btnShow={false}
                        open={printModalOpen}
                        setOpen={(data) => setPrintModalOpen(data)}
                        handleSearch={handleSearch}
                    />
                )}
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={alertbox.open}
                autoHideDuration={6000}
                onClose={() => setalertbox({ open: false, type: '', msg: '' })}
            >
                <Alert
                    onClose={() =>
                        setalertbox({ open: false, type: '', msg: '' })
                    }
                    severity={alertbox.type}
                >
                    {alertbox.msg}
                </Alert>
            </Snackbar>
            {btnloading ? 'Processing...' : renderCheckInButton()}
            {modalVisible && childComp && (
                <CheckInModal
                    setModalVisible={setModalVisible}
                    modalVisible={modalVisible}
                    handleModalConfirm={handleModalConfirm}
                    handleModalCancel={handleModalCancel}
                    childComp={childComp}
                />
            )}
        </>
    );
  };
  
  export default Check_In;
  