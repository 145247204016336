import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { cloneDeep, isEqual } from 'lodash';

import { useStepsState, useStepsDispatch } from '../../providers/schedule/provider';
import { releaseReservation, reserveSlot } from '../../providers/schedule/api';
import { Slot } from '../../models';

import Modal from '../../../shared/components/Modal';
import Slots from '../../../shared/components/Slots';
import { colors } from '../../../../styles/colors';
import { Button } from '../../../shared/components/styled';
import { Minor } from '../../../shared/models';
import { format } from 'date-fns';
import { config } from '../../../../config';

const Container = styled.div`
  max-width: 700px;
`;

const Title = styled.h2`
  color: ${colors.darkBlue};
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 13px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 240px;
  margin: 30px -5px 0;

  & > * {
    margin: 0 5px;
  }
`;

interface Props {
    showModal: boolean;
    closeModal: () => void;
}

const ChangeDateTimeModal: React.FC<Props> = ({ showModal, closeModal }) => {

    const [ loading, setLoading ] = useState(false);

    const { form } = useStepsState();
    const { updateFormValues } = useStepsDispatch();
    const appointment = { form };
    const { slot, minors, date, reservationId } = form;
    const rollbackAppt = useMemo(() => cloneDeep(appointment), [showModal]);
    let newSlot: Slot | null = slot;

    return (
        <Modal closeOnEsc={false} closeOnOverlayClick={false} open={showModal} onClose={closeModal}>
            <Container>
                <Title>Change date & time</Title>
                <Formik
                    initialValues={{ slot }}
                    onSubmit={() => {
                        updateFormValues({ slot: newSlot });
                        closeModal();
                    }}
                >
                    {() => (
                        <Form>
                            <Slots
                                alwaysShowDatePicker
                                appointment={form}
                                isWalkUpTest={form?.isWalkUpTest}
                                onFieldUpdate={(update) => { 
                                    newSlot = { ...update.slot } as Slot;
                                    updateFormValues({ slot: update.slot });
                                }}
                                onChangeDate={(newDepartureDate) => {

                                    setLoading(true);

                                    // if (slot !== undefined || slot !== null) {

                                    //     releaseReservation(
                                    //         slot?.period,
                                    //         minors.length + 1,
                                    //         slot?.locationId,
                                    //         date,
                                    //         reservationId,
                                    //         false
                                    //     )

                                    //     updateFormValues({
                                    //         slot: undefined,
                                    //     })

                                    // }
                                    updateFormValues({
                                        slot: undefined,
                                        date: format(newDepartureDate, config.dateFormat),
                                        reservationId: null
                                    });
                                    setLoading(false);
                                }}
                            />
                            <ButtonsContainer>
                                <Button
                                    libraryType="default"
                                    disabled={loading}
                                    size="sm"
                                    type="button"
                                    onClick={async () => {
                                        let form: any = rollbackAppt?.form
                                        updateFormValues({ ...form });
                                        let prevSlot: Slot | null = rollbackAppt.form.slot;
                                        let numberOfGuests: number =
                                            rollbackAppt?.form.minors?.length + 1;
                                        if (prevSlot && !isEqual(prevSlot, newSlot)) {
                                            await reserveSlot(prevSlot, newSlot, numberOfGuests, form.isRapidTest, form.reservationId, false, form)
                                                .then((res) => {
                                                    console.log("res", res);
                                                    if (res.data.status == 200) {
                                                        updateFormValues({
                                                            reservationId: res.data.reservationId,
                                                            reservationCount: res.data.numberOfGuests
                                                        })
                                                        closeModal();
                                                    } else if (res.data.status == 400) {
                                                        updateFormValues({
                                                            slot: undefined
                                                        })
                                                        closeModal();
                                                    }
                                                })
                                        }
                                        closeModal();
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button disabled={loading}  libraryType="primary" size="sm" type="submit">
                                    Change
                                </Button>
                            </ButtonsContainer>
                        </Form>
                    )}
                </Formik>
            </Container>
        </Modal>
    );
};

export default ChangeDateTimeModal;
