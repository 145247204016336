import axios from "axios";
import { config } from "../../../../config";
import { firebase } from '../../../../firebase';
export interface data{

}


export const checkemail = async(email:string)=>
    firebase
        .firestore()
        .collection(config.firestoreCollections.userdetails)
        .where('email', '==', email.toLowerCase())
        .get()
        .then((snapshot) => {
            const userDetails: any = snapshot.docs.map(
                (doc: any) => doc.data()
            );
            return userDetails
        })



export const updateMobile = async (id: string,mobile:string) => 
    firebase
    .functions()
    .httpsCallable('UserDetailsUpdateAsPerEmail')({id, mobile});

