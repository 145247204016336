import React, { useEffect, useState } from 'react'
import Modal from '../../../shared/components/Modal';
import { List,  Button } from 'antd';
import QBenchUpdateModal from './QBenchUpdateModal';
import { getAssayList, getQbenchCustomers } from './API';
import { Typography } from '@material-ui/core';

interface IProps {
    showQBenchModal: boolean;
    setShowQBenchModal: Function;
    QBdata: any;
    handleDrawerToggle:any;
}

const QBenchModal = ({
    showQBenchModal,
    setShowQBenchModal,
    QBdata,
    handleDrawerToggle,
}: IProps) => {
    const [openUpdCustomer, setOpenUpdCustomer] = useState(false);
    const [openUpdAssay, setOpenUpdAssay] = useState(false);
    const [openUpdRapidAssay, setOpenUpdRapidAssay] = useState(false);
    const [openUpdAntigenAssay, setOpenUpdAntigenAssay] = useState(false);
    const [assayDropdown, setAssayDropdown] = React.useState<any>();
    const [customerDropdown, setCustomerDropdown] = React.useState<any>();
    const [selectedCustomer, setSelectedCustomer] = useState<any>([]);
    const [selectedAssay, setSelectedAssay] = useState<any>([]);
    const [selectedRapidAssay, setSelectedRapidAssay] = useState<any>([]);
    const [selectedAntigenAssay, setSelectedAntigenAssay] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const data = [
        {
            title: 'QBench Customer ID',
            value:
                selectedCustomer.length == 0
                    ? 'Loading...'
                    : selectedCustomer[0].label,
            btnText: 'Update Customer',
            btnFunc: setOpenUpdCustomer,
            btnValue: openUpdCustomer,
        },
        {
            title: 'Standard/Express Assay',
            value:
                selectedAssay.length == 0
                    ? 'Loading...'
                    : selectedAssay[0].label,
            btnText: 'Update Assay',
            btnFunc: setOpenUpdAssay,
            btnValue: openUpdAssay,
        },
        {
            title: 'Rapid Assay',
            value:
                selectedRapidAssay.length == 0
                    ? 'Loading...'
                    : selectedRapidAssay[0].label,
            btnText: 'Update Assay',
            btnFunc: setOpenUpdRapidAssay,
            btnValue: openUpdRapidAssay,
        },
        {
            title: 'Antigen Assay',
            value:
                selectedAntigenAssay.length == 0
                    ? 'Loading...'
                    : selectedAntigenAssay[0].label,
            btnText: 'Update Assay',
            btnFunc: setOpenUpdAntigenAssay,
            btnValue: openUpdAntigenAssay,
        },
    ];
    const getQbenchData = async () => {
        await getQbenchCustomers().then((response: any) => {
            const data: any = [];
            if (response?.data?.result) {
                for (const customer of response.data.result?.data?.data)
                    if (!customer.deleted)
                        data.push({
                            label: customer.customer_name,
                            record: customer.id,
                        });

                setCustomerDropdown(data);
                setSelectedCustomer(() => {
                    let res = data.filter(
                        (item: any) => item.record === QBdata.qbenchCustomerId
                    );
                    return res;
                });
            }
        });

        await getAssayList().then((response: any) => {
            const data: any = [];
            if (response?.data?.result?.assayList) {
                for (const assay of response.data.result.assayList?.data)
                    data.push({ label: assay.method, record: assay.id });
                setAssayDropdown(data);
                setSelectedAssay(() => {
                    let res =
                        QBdata.qbenchExpressAssayId &&
                        QBdata.qbenchExpressAssayId != null
                            ? data.filter(
                                  (item: any) =>
                                      item.record ===
                                      QBdata.qbenchExpressAssayId
                              )
                            : data.filter((item: any) => item.record === 6);
                    return res;
                });
                setSelectedRapidAssay(() => {
                    let res =
                        QBdata.qbenchRapidAssayId &&
                        QBdata.qbenchRapidAssayId != null
                            ? data.filter(
                                  (item: any) =>
                                      item.record === QBdata.qbenchRapidAssayId
                              )
                            : data.filter((item: any) => item.record === 6);
                    return res;
                });
                setSelectedAntigenAssay(() => {
                    let res =
                        QBdata.qbenchAntigenAssayId &&
                        QBdata.qbenchAntigenAssayId != null
                            ? data.filter(
                                  (item: any) =>
                                      item.record ===
                                      QBdata.qbenchAntigenAssayId
                              )
                            : data.filter((item: any) => item.record === 18);
                    return res;
                });
            }
        });
    };

    const handleCancel = () => {
        setShowQBenchModal(false);
    };

    useEffect(() => {
        if (
            QBdata.qbenchCustomerId != null &&
            QBdata.qbenchCustomerId != 0 &&
            QBdata.qbenchCustomerId != undefined
        ) {
            getQbenchData();
            console.log('QBdata::', QBdata);
        }
    }, []);
    return (
        <Modal
            maxWidth={1100}
            open={showQBenchModal}
            onClose={handleCancel}
            closeOnOverlayClick={false}
            closeOnEsc
        >
            <h2>
                <b>Update QBench Connections</b>
            </h2>
            <hr />
            <br />
            <Typography variant="h6" color="initial">
                {'Location - ' + QBdata.name}
            </Typography>
            <br />
            {QBdata.qbenchCustomerId === null ||
            QBdata.qbenchCustomerId === 0 ||
            QBdata.qbenchCustomerId === undefined ? (
                <Typography variant="h6" color="initial">
                    {'This Qbench Customer Id is not found in database '}
                </Typography>
            ) : (
                <>
                    <List
                        loading={false}
                        itemLayout="horizontal"
                        dataSource={data}
                        renderItem={(item) => (
                            <List.Item
                                actions={[
                                    <Button
                                        type="primary"
                                        onClick={() => item.btnFunc(true)}
                                    >
                                        {item.btnText}
                                    </Button>,
                                ]}
                            >
                                <List.Item.Meta
                                    title={<h3>{item.title}</h3>}
                                    style={{ maxWidth: '220px' }}
                                />
                                <List.Item.Meta title={<h3>{item.value}</h3>} />
                            </List.Item>
                        )}
                    />
                </>
            )}

            <br />
            <br />
            <Button
                danger
                disabled={loading}
                style={{ float: 'right' }}
                onClick={handleCancel}
            >
                Close
            </Button>
            <br />
            {selectedCustomer.length === 1 && (
                <QBenchUpdateModal
                    title={'Update QBench Customer ID'}
                    showQBenchUpdateModal={openUpdCustomer}
                    setShowQBenchUpdateModal={setOpenUpdCustomer}
                    QBcustomerData={customerDropdown}
                    selectedData={QBdata.qbenchCustomerId}
                    QBdata={QBdata}
                    updData={selectedCustomer[0]}
                    isAssay={false}
                    isUpdatingForRapid={false}
                    isUpdatingForAntigen={false}
                    setConfirmedUpdatedData={setSelectedCustomer}
                    handleDrawerToggle={handleDrawerToggle}
                />
            )}
            {selectedAssay.length === 1 && (
                <QBenchUpdateModal
                    title={'Update QBench Assay for Standard/Express'}
                    showQBenchUpdateModal={openUpdAssay}
                    setShowQBenchUpdateModal={setOpenUpdAssay}
                    QBcustomerData={assayDropdown}
                    selectedData={QBdata.qbenchExpressAssayId}
                    QBdata={QBdata}
                    updData={selectedAssay[0]}
                    isAssay={true}
                    isUpdatingForRapid={false}
                    isUpdatingForAntigen={false}
                    setConfirmedUpdatedData={setSelectedAssay}
                    handleDrawerToggle={handleDrawerToggle}
                />
            )}
            {selectedRapidAssay.length === 1 && (
                <QBenchUpdateModal
                    title={'Update QBench Assay for Rapid'}
                    showQBenchUpdateModal={openUpdRapidAssay}
                    setShowQBenchUpdateModal={setOpenUpdRapidAssay}
                    QBcustomerData={assayDropdown}
                    selectedData={QBdata.qbenchRapidAssayId}
                    QBdata={QBdata}
                    updData={selectedRapidAssay[0]}
                    isAssay={true}
                    isUpdatingForRapid={true}
                    isUpdatingForAntigen={false}
                    setConfirmedUpdatedData={setSelectedRapidAssay}
                    handleDrawerToggle={handleDrawerToggle}
                />
            )}
            {selectedAntigenAssay.length === 1 && (
                <QBenchUpdateModal
                    title={'Update QBench Assay for Antigen'}
                    showQBenchUpdateModal={openUpdAntigenAssay}
                    setShowQBenchUpdateModal={setOpenUpdAntigenAssay}
                    QBcustomerData={assayDropdown}
                    selectedData={QBdata.qbenchAntigenAssayId}
                    QBdata={QBdata}
                    updData={selectedAntigenAssay[0]}
                    isAssay={true}
                    isUpdatingForRapid={false}
                    isUpdatingForAntigen={true}
                    setConfirmedUpdatedData={setSelectedAntigenAssay}
                    handleDrawerToggle={handleDrawerToggle}
                />
            )}
        </Modal>
    );
};

export default QBenchModal
