import * as React from 'react';
import { Button, Checkbox, Col, Form, Grid, Row, Select } from 'antd';
import { addDays, format, isBefore } from 'date-fns';
import Loader from '../Loader';
import CardHeader from '../../../admin/components/AppointmentList/Card/CardHeader';
import CardBody from '../../../admin/components/AppointmentList/Card/CardBody';
import Card from '../../../admin/components/AppointmentList/Card/Card.js';
import { Box, Typography } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import AntdRangePicker from '../AntDDatePicker/AntdRangePicker';
import { useSharedState } from '../../provider';
import { Field, Formik, Form as ColumnFilterForm } from 'formik';
import Modal from '../../../shared/components/Modal';
import {
    getInvoiceData,
    getNpDestionation,
    invoiceType,
} from './ApiAndFunctios';
import { initialSelect, invoceColums } from './utils';
import { useMediaQuery } from '../../../admin/provider';
import DownloadLinkCSV from '../../../admin/components/AppointmentList/components/DownloadCSV';
const { Option } = Select;
function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export interface InvoiceReportProps {}
export default function InvoiceReport(props: InvoiceReportProps) {
    const { allLocations } = useSharedState();
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const sleep = (ms: any) => new Promise((r) => setTimeout(r, ms));
    const isLargeScreen = useMediaQuery('(min-width: 600px)');
    const [initialColumsData, setInitialColumsData] =
        React.useState(invoceColums);
    const [tempColumns, setTempColumns] = React.useState(initialSelect);
    const [finalTempColumns, setFinalTempColumns] =
        React.useState(initialSelect);
    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleCancel = () => {
        setFinalTempColumns(tempColumns);
        setIsModalVisible(false);
    };

    const [formData, setFormData] = React.useState<invoiceType>({
        firstName: '',
        lastName: '',
        middleName: '',
        confirmationId: '',
        phone: '',
        birthDate: '',
        email: '',
        date: '',
        locationQbenchId: [],
        sampleId: 0,
        thirdPartyAppointments: '',
        toggle: '',
        status: '',
        specialDaywise: '',
        isWeeklyTesting: false,
        allSchedulerLite: false,
        allAppointments: null,
        payingMethod: '',
    });
    const AllUniqLocation = allLocations?.filter(
        (v: any, i: any, a: any) =>
            a.findIndex(
                (v2: any) => v2.qbenchCustomerId === v.qbenchCustomerId
            ) === i
    );
    const [loader, setloader] = React.useState(false);
    const [searched, setSearched] = React.useState(false);
    const [result, setResult] = React.useState<any[]>([]);
    const [alertbox, setalertbox] = React.useState({
        open: false,
        type: '',
        msg: '',
    });
    const [dates, setDates] = React.useState<any>(null);
    const [npDestination, setNpDestination] = React.useState<any>([]);
    React.useEffect(() => {
        getNpDestionation()
            .then((res: any) => {
                setNpDestination(
                    res?.data?.data?.map((e: any) => e?.value || '') || []
                );
            })
            .catch((err: any) => {});
    }, []);

    const [errorDates, setErrorDates] = React.useState<any[]>([]);
    const fetchDataforRangeDate: any = async (
        count: number,
        finalData: any[],
        arr: any[]
    ) => {
        if (count >= arr.length) {
            return finalData;
        }
        await getInvoiceData({ ...formData, date: arr[count] })
            .then(async (response: any) => {
                finalData.push(...response.data);
                await fetchDataforRangeDate(count + 1, finalData, arr);
            })
            .catch(async (err) => {
                setloader(false);
                setSearched(true);
                setalertbox({
                    open: true,
                    type: 'error',
                    msg:
                        'Something went wrong while searching ' +
                        arr[count] +
                        ' data.',
                });
                console.log('errdate', arr[count]);
                let errdate: any[] = errorDates;
                errdate.push(arr[count]);
                setErrorDates(errdate);
                // await fetchDataforRangeDate(count + 1, finalData, arr);
            });
        return finalData;
    };
    const priceCalculate = (
        antigen = false,
        rapid = false,
        express = false,
        panel = false,
        isNpDes = false
    ) => {
        if (panel) {
            return 150;
        } else if (antigen) {
            return 75;
        } else if (rapid) {
            if (isNpDes) return 300;
            return 250;
        } else if (express) {
            if (isNpDes) return 225;
            return 150;
        } else {
            if (isNpDes) return 150;
            return 90;
        }
    };
    const handleSearch = async () => {
        if (
            !formData.locationQbenchId.length ||
            !formData.date ||
            formData.allAppointments === null ||
            !tempColumns.length
        ) {
            setalertbox({
                open: true,
                type: 'warning',
                msg: !tempColumns.length
                    ? 'Please Select the Columns'
                    : 'All Fields are required*',
            });
            return false;
        }
        try {
            setSearched(false);
            setloader(true);
            let date = new Date(formData.date.split(', ')[0]);
            setInitialColumsData(tempColumns);
            setFinalTempColumns(tempColumns);
            let arr: Array<any> = [];
            arr.push(format(new Date(date), 'MM/dd/yyyy'));
            while (
                isBefore(new Date(date), new Date(formData.date.split(', ')[1]))
            ) {
                date = new Date(addDays(date, 1));
                arr.push(format(new Date(date), 'MM/dd/yyyy'));
            }
            let finalData: any[] = await fetchDataforRangeDate(0, [], arr);
            if (errorDates.length) {
                setSearched(false);
                setloader(false);
                return false;
            } else {
                setResult(
                    finalData.map((e) => ({
                        ...e,
                        amount: priceCalculate(
                            e?.isAntigen,
                            e?.isRapidTest,
                            e?.isExpressSameDayTest,
                            e?.isPanel,
                            e.travelType === 'INT' &&
                                npDestination?.includes(e.destination)
                        ),
                    }))
                );
                setSearched(true);
                setloader(false);
            }
        } catch (error) {
            let err: any = error;
            setalertbox({
                open: true,
                type: 'error',
                msg: err?.message || 'Something went Wrong',
            });
            setSearched(true);
            setloader(false);
            setResult([]);
        }
    };
    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={alertbox.open}
                autoHideDuration={3000}
                onClose={() => setalertbox({ open: false, type: '', msg: '' })}
            >
                <Alert
                    icon={false}
                    onClose={() =>
                        setalertbox({ open: false, type: '', msg: '' })
                    }
                    severity={alertbox.type}
                >
                    {alertbox.msg}
                </Alert>
            </Snackbar>
            <Modal
                maxWidth={1000}
                open={isModalVisible ? isModalVisible : false}
                onClose={handleCancel}
                closeOnOverlayClick={false}
                closeOnEsc
            >
                <h3>Show/Hide Column</h3>
                <hr />
                <br />
                <Formik
                    initialValues={{
                        showColumns: invoceColums,
                    }}
                    onSubmit={async (values) => {}}
                >
                    {({ values }) => (
                        <ColumnFilterForm>
                            <div
                                role="group"
                                aria-labelledby="checkbox-group"
                                style={{
                                    display: 'grid',
                                    gridGap: '1em',
                                    gridTemplateColumns: isLargeScreen
                                        ? '1fr 1fr 1fr'
                                        : '1fr 1fr',
                                    justifyContent: 'space-evenly',
                                }}
                            >
                                {invoceColums.map((element, index) => {
                                    return (
                                        <div>
                                            <Checkbox
                                                checked={tempColumns.includes(
                                                    element
                                                )}
                                                onChange={(e: any) => {
                                                    setTempColumns(
                                                        (
                                                            array: any[]
                                                        ): any[] => {
                                                            if (
                                                                array?.includes(
                                                                    element
                                                                )
                                                            )
                                                                return array?.filter(
                                                                    (e) =>
                                                                        e !==
                                                                        element
                                                                );
                                                            return [
                                                                ...array,
                                                                element,
                                                            ];
                                                        }
                                                    );
                                                }}
                                                name={element}
                                            >
                                                &nbsp;&nbsp;&nbsp;
                                                {element}
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                            </Checkbox>
                                        </div>
                                    );
                                })}
                            </div>
                            <span
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Button
                                    loading={loader}
                                    style={{ margin: '10px' }}
                                    onClick={() => {
                                        setTempColumns(finalTempColumns);
                                        setIsModalVisible(false);
                                    }}
                                    type="default"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    loading={loader}
                                    style={{ margin: '10px' }}
                                    onClick={handleCancel}
                                    type="primary"
                                >
                                    Apply
                                </Button>
                            </span>
                        </ColumnFilterForm>
                    )}
                </Formik>
            </Modal>
            <Row
                style={{ marginLeft: 15 }}
                gutter={{
                    xs: 5,
                    sm: 5,
                    md: 15,
                    lg: 15,
                    xl: 20,
                    xxl: 20,
                }}
            >
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 8 }}
                    lg={{ span: 5 }}
                >
                    <Form layout="vertical">
                        <Form.Item label="Date Range" name="todate">
                            <AntdRangePicker
                                dates={dates}
                                setDates={setDates}
                                onChange={(value: any) =>
                                    setFormData({ ...formData, date: value })
                                }
                                disabled={loader}
                                range={30}
                            />
                        </Form.Item>
                    </Form>
                </Col>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 8 }}
                    lg={{ span: 6 }}
                >
                    <Form layout="vertical">
                        <Form.Item label="Location" name="location">
                            <Select
                                loading={loader}
                                allowClear
                                placeholder="Please select"
                                showArrow
                                style={{ width: '100%' }}
                                disabled={loader}
                                showSearch
                                optionFilterProp="children"
                                virtual={false}
                                filterOption={(input: any, option: any) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA: any, optionB: any) =>
                                    optionA.children
                                        .toLowerCase()
                                        .localeCompare(
                                            optionB.children.toLowerCase()
                                        )
                                }
                                onChange={(e) => {
                                    if (e) {
                                        setFormData({
                                            ...formData,
                                            locationQbenchId: [e],
                                        });
                                    } else {
                                        setFormData({
                                            ...formData,
                                            locationQbenchId: [],
                                        });
                                    }
                                }}
                            >
                                {AllUniqLocation?.map((location: any) => (
                                    <Option
                                        key={location.qbenchCustomerId}
                                        value={location.qbenchCustomerId}
                                    >
                                        {location.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Form>
                </Col>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 8 }}
                    lg={{ span: 5 }}
                >
                    <Form layout="vertical">
                        <Form.Item
                            label="Appointment Type"
                            name="appointmentType"
                        >
                            <Select
                                loading={loader}
                                allowClear
                                defaultActiveFirstOption={true}
                                disabled={loader}
                                placeholder="Please select"
                                showArrow
                                showSearch
                                optionFilterProp="children"
                                virtual={false}
                                filterOption={(input: any, option: any) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA: any, optionB: any) =>
                                    optionA.children
                                        .toLowerCase()
                                        .localeCompare(
                                            optionB.children.toLowerCase()
                                        )
                                }
                                onChange={(e: any) => {
                                    if (
                                        e === 'All Scheduler Lite Appointments'
                                    ) {
                                        setFormData({
                                            ...formData,
                                            allSchedulerLite: true,
                                            allAppointments: false,
                                        });
                                    } else if (e === 'All Appointments') {
                                        setFormData({
                                            ...formData,
                                            allSchedulerLite: false,
                                            allAppointments: true,
                                        });
                                    } else {
                                        setFormData({
                                            ...formData,
                                            allSchedulerLite: false,
                                            allAppointments:
                                                e === undefined ? null : false,
                                        });
                                    }
                                }}
                            >
                                <Option key={0} value={'All Appointments'}>
                                    {'All Appointments'}
                                </Option>
                                <Option
                                    key={1}
                                    value={'All Scheduler Lite Appointments'}
                                >
                                    {'All Scheduler Lite Appointments'}
                                </Option>
                                <Option
                                    key={1}
                                    value={'Only Weekly Testing Appointments'}
                                >
                                    {'Only Weekly Testing Appointments'}
                                </Option>
                            </Select>
                        </Form.Item>
                    </Form>
                </Col>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 8 }}
                    lg={{ span: 4 }}
                >
                    <Form layout="vertical">
                        <Form.Item label="Status" name="status">
                            <Select
                                loading={loader}
                                allowClear
                                showArrow
                                placeholder="Please Select"
                                onChange={(e: any) => {
                                    setFormData({ ...formData, status: e });
                                }}
                            >
                                <Option value="completed">Completed</Option>
                                <Option value="Cancelled">Cancelled</Option>
                            </Select>
                        </Form.Item>
                    </Form>
                </Col>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 8 }}
                    lg={{ span: 3 }}
                >
                    <Button
                        onClick={showModal}
                        disabled={loader}
                        style={{
                            backgroundColor: 'rgb(0, 172, 193)',
                            color: 'white',
                            height: 33,
                            margin: '30px 0 0 18px',
                        }}
                    >
                        Show/Hide Colums
                    </Button>
                </Col>
            </Row>
            <br />
            <Button
                onClick={handleSearch}
                disabled={loader}
                style={{
                    backgroundColor: 'rgb(0, 172, 193)',
                    color: 'white',
                    height: 33,
                    margin: '5px 0 0 17px',
                }}
            >
                Generate Report
            </Button>
            <Card>
                <CardHeader color="primary">
                    <Typography
                        variant="h2"
                        style={{ color: 'white', fontSize: '1.5rem' }}
                    >
                        Invoice Report
                    </Typography>
                </CardHeader>
                <CardBody>
                    {loader ? (
                        <Loader />
                    ) : (
                        <>
                            {searched ? (
                                result.length ? (
                                    <>
                                        <Box
                                            alignContent="center"
                                            alignItems="center"
                                            textAlign="center"
                                        >
                                            <DownloadLinkCSV
                                                columns={initialColumsData}
                                                tableData={result}
                                                paramData={formData.date}
                                                downLoadInvoice
                                            />
                                        </Box>
                                    </>
                                ) : (
                                    <>
                                        <Typography
                                            variant="h5"
                                            align="center"
                                            color="initial"
                                        >
                                            No Data Found.
                                        </Typography>
                                    </>
                                )
                            ) : (
                                <></>
                            )}
                        </>
                    )}
                </CardBody>
            </Card>
        </>
    );
}
