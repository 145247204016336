
import React,{ SetStateAction, useState }from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import DialogActions from '@material-ui/core/DialogActions';
import styled from 'styled-components';
import { userCheckin } from '../../../api';

interface Props {
    open: boolean;
    handleClosed: () => void;
    setOpenSampleModal: React.Dispatch<SetStateAction<boolean>>;
    qbenchId: number[];
    // handleDialogModal: () => void;
    getDay: () => void;
    handleCancel: () => void;
    handleSearch:()=>void;
    
};

const Container = styled.div`
max-width: 750px;
background: #ffff;
padding: 35px;
`
const PrintModal: React.FC<Props> = ({
    open,
    handleClosed,
    qbenchId,
    setOpenSampleModal,
    getDay,
    handleCancel,
    handleSearch,
}) => {
    const [openLabel, setOpenLabel] = useState<boolean>(false);
    const [btnloading, setBtnLoading] = useState<boolean>(false);

    // const { getPrintSampleLabels } = useAdminState();
    // let selectRowdata: any = getPrintSampleLabels;

    // const handleClose = () => {
    //     setOpenLabel(false);
    //   };

    const handleOpen = async () => {
        try {
            setBtnLoading(true);
            if (qbenchId) {
                setOpenSampleModal(true);
                setOpenLabel(false);
                setBtnLoading(false);
                // handleOpen();
                handleCancel();
                getDay();
            }
        } catch (e) {
            console.error(e);
        }
    };
    return (
        <>
            <Modal
                open={open}
                onClose={handleClosed}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ display: 'grid', placeItems: 'center' }}
            >
                <Container>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        style={{ padding: '13px 0px' }}
                    >
                        Would you like to print the labels for this customer?
                    </Typography>
                    <DialogActions>
                        <Button
                            style={{
                                width: '100px',
                            }}
                            variant="outlined"
                            onClick={() => {
                                handleClosed();
                                // getDay();
                                //handleSearch();
                            }}
                        >
                            No
                        </Button>
                        <Button
                            style={{
                                background: '#1890ff',
                                marginLeft: '23px',
                                color: 'white',
                                width: '117px',
                            }}
                            variant="contained"
                            onClick={handleOpen}
                        >
                            {btnloading ? 'Processing...' : 'Yes'}
                        </Button>
                    </DialogActions>
                    {/* {openSampleModal && <CheckinSinglePrintSample handleOpen />} */}
                </Container>
            </Modal>
        </>
    );
};
export default PrintModal;