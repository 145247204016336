import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "../layouts/Navbars/Navbar.js";
// import Footer from "components/Footer/Footer.js";
import Sidebar from "../layouts/Sidebar/Sidebar.js";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import MiniDrawer from "../layouts/LeftSideMenu";

import routes from "./routes.js";

import styles from "../layouts/adminStyle.js";

import bgImage from "../../../../assets/step-1-header-bg.jpg";
import logo from "../../../../assets/reactlogo.png";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { useAuth0 } from "@auth0/auth0-react";
import { config } from "../../../../config";
import { isExpired, decodeToken } from "react-jwt";
import axios from 'axios';
import { useAdminDispatch, useAdminState } from '../../provider';
import { reportAccess } from '../../api'
import firebase from "firebase";

let ps;

const switchRoutes = (
    <Switch>
        {routes.map((prop, key) => {
            if (prop.layout === "/dashboard") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            }
            return null;
        })}
        <Redirect from="/dashboard" to={window.localStorage.getItem('checkin')?window.localStorage.getItem('checkin'):'/dashboard/Home'} />
    </Switch>
);

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
    // styles
    const classes = useStyles();
    // const { userRole } = useAdminState();
    let { user, isAuthenticated, isLoading, logout, getAccessTokenSilently, error } = useAuth0();
    const [open, setOpen] = React.useState(false);
    // ref to help us initialize PerfectScrollbar on windows devices
    const mainPanel = React.createRef();
    // states and functions
    const [image, setImage] = React.useState(bgImage);
    const [color, setColor] = React.useState("blue");
    const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const { updateUserRole,setAccess } = useAdminDispatch()
    const [updatedRoutes, setUpdatedRoutes] = React.useState([]);
    const [loading,setLoading]=React.useState(isLoading)

    const insuranceReportAccess=()=>{
        reportAccess().then((res)=>{
           if((res?.data[0]?.insurance)?.includes(JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('userDetails')))).email))
               setAccess(true)
        })
    }

    const handleImageClick = image => {
        setImage(image);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleColorClick = color => {
        setColor(color);
    };
    const handleFixedClick = () => {
        if (fixedClasses === "dropdown") {
            setFixedClasses("dropdown show");
        } else {
            setFixedClasses("dropdown");
        }
    };
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleDrawerTogglemenu = () => {
        setOpen(!open);
    };
    const getRoute = () => {
        return window.location.pathname !== "/admin/maps";
    };
    const resizeFunction = () => {
        if (window.innerWidth >= 960) {
            setMobileOpen(false);
        }
    };

    const checkToken=async(accessToken)=>{
		const token=JSON.parse(accessToken);
		const tmp=decodeToken(token.accessToken);
		const isMyTokenExpired = isExpired(token.accessToken);
		if(isMyTokenExpired){
			window.localStorage.removeItem('accessToken');
			window.localStorage.removeItem('userDetails');
			logout({ returnTo: config.auth0Redirection.login }); 
		} else {
			setLoading(false);
		}
	}

    // initialize and destroy the PerfectScrollbar plugin
    React.useEffect(() => {
        if (window.localStorage.accessToken) {
            checkToken(window.localStorage.accessToken);
        }
        getRoleByEmail({ email: JSON.parse(window.localStorage.getItem("userDetails")).email});
        insuranceReportAccess()
    }, []);
    
    // const getRoleByEmail = async () => {
    //         const resData = await fetch(`${config.baseURL}/getRoleByEmail`, {
    //         method: "POST",
    //         headers: {
    //             "content-type": "application/json"
    //         },
    //         body: JSON.stringify({ email: JSON.parse(window.localStorage.getItem("userDetails")).email })
    //     })
    //     const jsonData = await resData.json()
    //         .then(res => {
    //             if (res.state.length == 0) {
    //                 getAllRolesAccess("Default")
    //                 updateUserRole("Default")
    //             } else {
    //                 getAllRolesAccess(res.state[0].role)
    //                 updateUserRole(res.state[0].role)
    //             }
    //         })

    // }
    const getRoleByEmail = async (data) => {
        firebase
            .functions()
            .httpsCallable('getRoleByEmail')(data)
            .then(res => {
                if (res?.data?.length === 0) {
                    getAllRolesAccess("Default")
                    updateUserRole("Default")
                } else {
                    getAllRolesAccess(res.data[0].role)
                    updateUserRole(res.data[0].role)
                }
            })
            .catch(err => {
                console.log("err", err)

            })
    }

    // const getAllRolesAccess = async (roleName) => {
    //         const resData = await fetch(`${config.baseURL}/getAccessFieldsByRole`, {
    //         method: "POST",
    //         headers: {
    //             "content-type": "application/json"
    //         },
    //         body: JSON.stringify({ roleName: roleName })
    //     })
    //     const jsonData = await resData.json()
    //         .then(res => {
    //             if (res.state.length == 0) {
    //                 getAllRolesAccess("Default")
    //             } else {
    //                 mapRoutes(res?.state[0]?.accessFields)
    //             }
    //         })
    // }
    const getAllRolesAccess = async (params) => {
        params = { roleName: params }
        firebase
            .functions()
            .httpsCallable('getAccessFieldsByRole')(params)
            .then(res => {
                if (res.data.length == 0) {
                    getAllRolesAccess("Default")
                } else {
                    mapRoutes(res?.data[0]?.accessFields)
                }
            })
            .catch(err => {
                console.log("ch-err2", err)
            })
    }

    const mapRoutes = (currentUserAccessFields) => {
        const updatedRoutesArr = routes.map(route => {
            let tempObj = route
            if (tempObj.display == false) {
                tempObj.display = false
            } else {

                let flag = currentUserAccessFields.includes(tempObj.name)
                tempObj.display = flag

            }
            return tempObj
        })

        const updatedRoutesArrs = updatedRoutesArr.map(route => {
            let tempObj = route
            return tempObj
        })
        console.log("updatedRoutesArrs",updatedRoutesArrs)
        setUpdatedRoutes(updatedRoutesArrs)
    }
    return (
        <div className={classes.wrapper}>
            {/* <Sidebar
        routes={routes}
        logoText={"Work Site Labs"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerTogglemenu}
        open={open}
        color={color}
        {...rest}
      /> */}
            {open ? <Sidebar
                // userRole={userRole}
                routes={updatedRoutes}
                logoText={"Work Site Labs"}
                logo={logo}
                image={image}
                handleDrawerToggle={handleDrawerTogglemenu}
                open={open}
                color={color}
                {...rest}
            /> : false}
            <div className={open ? classes.mainPanel : classes.mainPanel1} ref={mainPanel}>
                <Navbar
                    routes={routes}
                    open={open}
                    handleDrawerToggle={handleDrawerTogglemenu}
                    {...rest}
                />

                {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
                {getRoute() ? (
                    <div className={classes.content}>
                        <div className={classes.container}>{switchRoutes}</div>
                    </div>
                ) : (
                    <div className={classes.map}>{switchRoutes}</div>
                )}
                {/* {getRoute() ? <Footer /> : null} */}
                {/* <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          bgColor={color}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
        /> */}
            </div>
        </div>
    );
}
