import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper, { PaperProps } from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { deleteRoleApi } from '../../api';

import { config } from '../../../../config';
import { CircularProgress } from '@material-ui/core';
function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function ConfirmDialogBox( props:any) {
  const { getAllRolesAccess, deleteID}=props
  const [open, setOpen] = React.useState(false);
  const [alertbox, setalertbox] = React.useState<any>({open:false,type:'',msg:''});
  const [loading,setLoading]=React.useState(false)
    const user: any = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('userDetails'))))
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteRole=async(deleteID:any)=>{
    try{
      setLoading(true)
    //   const resData = await fetch(`${config.baseURL}/deleteRole`, {
    //   method:"DELETE",
    //   headers: {
    //     'content-type': 'application/json'
    // },
    // body:JSON.stringify({id:deleteID,updatedBy: user?.email ?? '', userName: user?.name ?? ''})
    const resData = await deleteRoleApi({ id: deleteID, updatedBy: user?.email ?? '', userName: user?.name ?? '' })
    if(resData.ok){
        setLoading(false)
        handleClose()
      setalertbox({open:true,type:'success',msg:'Role Deleted Successfully'});
      getAllRolesAccess();
    }}
    catch(error){
        console.log(error)
        setalertbox({open:true,type:'error',msg:'Failed'});
    }
  }
 
  return (
    <div>
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={alertbox.open} autoHideDuration={6000} onClose={() => setalertbox({ oepn: false, type: '', msg: '' })}>
        <Alert onClose={() => setalertbox({ oepn: false, type: '', msg: '' })} severity={alertbox.type}>{alertbox.msg}</Alert>
     </Snackbar>
      <Button color='secondary'  variant="contained" onClick={handleClickOpen}>
        Delete Role
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogContent>
          <DialogContentText>
          <p style={{fontWeight:"bold",fontSize:'20px'}}> Do you really want to delete it?</p> <br/>
          <p> Note: All users with this role will get deleted.</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' color="primary" autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button variant='contained' color="secondary" onClick={()=>{  
              handleDeleteRole(deleteID)
        }}>{loading ? <CircularProgress /> :"Delete"}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
