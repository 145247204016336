import * as React from 'react';
import { Form } from 'antd';
import { addDays, format, isBefore, isEqual } from 'date-fns';
import AntDDatePicker from './AntDDatePicker';
import Loader from './Loader';
import Button from '@material-ui/core/Button';
import CardHeader from '../../admin/components/AppointmentList/Card/CardHeader';
import CardBody from '../../admin/components/AppointmentList/Card/CardBody';
import Card from '../../admin/components/AppointmentList/Card/Card.js';
import { Box, Typography } from '@material-ui/core';
import axios from 'axios';
import { exportdatatoexel } from '../../admin/api';
import { config } from '../../../config';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { getInsurancePersonsDetails } from '../api';

function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export interface IInsuranceReportProps {}
export default function InsuranceReport(props: IInsuranceReportProps) {
    const [selectedFromDate, setSelectedFromDate] = React.useState(
        format(new Date(), config.dateFormat)
    );
    const [selectedToDate, setSelectedToDate] = React.useState(
        format(new Date(), config.dateFormat)
    );
    const [loader, setloader] = React.useState(false);
    const [listofdata, setlistofdata] = React.useState<any[]>([]);
    const [searched, setsearched] = React.useState(false);
    const [errorDates, setErrorDates] = React.useState<any[]>([]);
    const [alertbox, setalertbox] = React.useState({
        open: false,
        type: '',
        msg: '',
    });
    const fetchDataforRangeDate: any = async (
        count: number,
        finalData: any[],
        arr: any[]
    ) => {
        console.log("arr",arr.length)
       
        if (count >= arr.length) {
            return finalData;
        }
        // await axios({
        //     method: 'POST',
        //     url: `${config.baseURL}/getInsurancePersonsDetails`,
        //     data: {
        //         data: {
        //             from: arr[count],
        //             to: arr[count],
        //         },
        //     },
        // })
        await getInsurancePersonsDetails({
            from: arr[count],
            to: arr[count],  
        }).then(async (response: any) => {
                finalData.push(...response.data.result ?? []);
                await fetchDataforRangeDate(count + 1, finalData, arr);
            })
            .catch(async (err) => {
                console.log("errdate",arr[count])
                let errdate: any[] = errorDates;
                errdate.push(arr[count]);
                setErrorDates(errdate);
                await fetchDataforRangeDate(count + 1, finalData, arr);
            });
        return finalData;
    };
    const handlesearch = async (e: any) => {
        
        setalertbox({
            open: true,
            type: 'success',
            msg: ' Please wait , while its loading',
        });
       
        let date = new Date(selectedFromDate);
        let arr: Array<any> = [];
        arr.push(format(new Date(date), 'MM/dd/yyyy'));
        while (isBefore(new Date(date), new Date(selectedToDate))) {
            date = new Date(addDays(date, 1));
            arr.push(format(new Date(date), 'MM/dd/yyyy'));
        }
        setloader(true);
       const Timeout = setInterval(()=>{
            setalertbox({
                open: true,
                type: 'success',
                msg: ' Please wait , while its loading',
            });
        },50000)
        let finalData: any[] = await fetchDataforRangeDate(0, [], arr);
        if (errorDates.length) {
            let data1: any[];
            try {
                data1 = await fetchDataforRangeDate(0, [], errorDates);
                finalData.concat(data1);
                console.log('new Fetch', data1);
            } catch (error) {}
        }
        clearInterval(Timeout)
        setlistofdata(finalData);
        setsearched(true);
        setloader(false);
    };
    const downloaddata = (data: any) => {
        exportdatatoexel(data);
    };
    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={alertbox.open}
                autoHideDuration={3000}
                onClose={() => setalertbox({ open: false, type: '', msg: '' })}
            >
                <Alert
                    icon={false}
                    onClose={() =>
                        setalertbox({ open: false, type: '', msg: '' })
                    }
                    severity={alertbox.type}
                >
                    {alertbox.msg}
                </Alert>
            </Snackbar>
            <div id="container">
                <div
                    style={{
                        float: 'left',
                        width: '24%',
                        paddingTop: '5px',
                        paddingBottom: '15px',
                    }}
                >
                    <Form style={{ marginLeft: '5%' }}>
                        <Form.Item label="From Date" name="fromdate">
                            <AntDDatePicker
                                style={{ width: '95%' }}
                                disabled={loader}
                                defaultValue={new Date(selectedFromDate)}
                                showToday
                                onChange={(fromDate: any) =>
                                    setSelectedFromDate(
                                        format(fromDate, config.dateFormat)
                                    )
                                }
                                allowClear={false}
                            />
                        </Form.Item>
                    </Form>
                </div>
                <div style={{ float: 'left', width: '24%', paddingTop: '5px' }}>
                    <Form style={{ marginLeft: '5%' }}>
                        <Form.Item label="To Date" name="todate">
                            <AntDDatePicker
                                style={{ width: '95%' }}
                                disabled={loader}
                                defaultValue={new Date(selectedFromDate)}
                                showToday
                                onChange={(fromDate: any) =>
                                    setSelectedToDate(
                                        format(fromDate, config.dateFormat)
                                    )
                                }
                                allowClear={false}
                            />
                        </Form.Item>
                    </Form>
                </div>
                <Button
                    onClick={handlesearch}
                    disabled={loader}
                    variant="contained"
                    style={{
                        backgroundColor: 'rgb(0, 172, 193)',
                        color: 'white',
                        height: 33,
                        margin: '5px 0 0 17px',
                    }}
                >
                    Search
                </Button>
            </div>
            <br />
            <Card>
                <CardHeader color="primary">
                    <Typography
                        variant="h2"
                        style={{ color: 'white', fontSize: '1.5rem' }}
                    >
                        Insurance Reports
                    </Typography>
                </CardHeader>
                <CardBody>
                    {loader ? (
                        <Loader />
                    ) : (
                        <>
                            {searched ? (
                                listofdata.length > 0 ? (
                                    <>
                                        <Box
                                            alignContent="center"
                                            alignItems="center"
                                            textAlign="center"
                                        >
                                            {
                                                <Button
                                                    variant="contained"
                                                    onClick={(e: any) => {
                                                        downloaddata(
                                                            listofdata
                                                        );
                                                    }}
                                                >
                                                    Download Report
                                                </Button>
                                            }
                                        </Box>
                                    </>
                                ) : (
                                    <>
                                        <Typography
                                            variant="h5"
                                            align="center"
                                            color="initial"
                                        >
                                            No Data Found.
                                        </Typography>
                                    </>
                                )
                            ) : (
                                ''
                            )}
                        </>
                    )}
                </CardBody>
            </Card>
        </>
    );
}
