import React, { useMemo } from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';

import { useStepsState, useStepsDispatch } from '../../../../shared/providers/schedule/provider';
import { Button } from '../../../../shared/components/styled';
import { colors } from '../../../../../../src/styles/colors';
import { Breakpoints } from '../../../../../../src/dictionaries';

import Modal from '../../../../shared/components/Modal';
import LocationButtonGroup from '../../../../shared/components/form/LocationButtonGroup';
import { cloneDeep } from 'lodash';
import { useAdminDispatch, useAdminState } from '../../../provider';
import {  updateAppointmentdata } from '../../../api';
import { config } from '../../../../../config';
import { parse, format } from 'date-fns';




const Content = styled.div`
  width: 100%;

  @media (min-width: ${Breakpoints.md}px) {
    width: 540px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 240px;
  margin: 0 -5px;

  & > * {
    margin: 0 5px;
  }
`;

const Title = styled.h2`
  color: ${colors.darkBlue};
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 25px;
`;

    
interface Props {
    showModal: boolean;
    closeModal: () => void;
  }
  
  const ChangeLocationModal: React.FC<Props> = ({ showModal, closeModal }) => {
  const {
    form: { location },
    showChangeLocationModal,
  } = useStepsState();
  const { params, appointments,Editappointments } = useAdminState();
  const locationdata = Editappointments !== null ? Editappointments[0]?.location:null
  const appointmentdata:any = Editappointments !== null ? Editappointments[0]:null
  const { toggleChangeLocationModal, updateFormValues } = useStepsDispatch();
  const rollbackLocation = useMemo(() => cloneDeep(locationdata), [showChangeLocationModal]);

  const handleSearchfunction = (locationclick:any) => {
    
    appointmentdata.location= JSON.parse( JSON.parse( JSON.stringify(localStorage.getItem('location')))) ;
    // appointmentdata.slot.locationId = appointmentdata.location.qbenchCustomerId

    appointmentdata.minors.map((e:any)=>e.birthDate=e.birthDate
    ? format(e.birthDate, config.dateFormat)
    : appointmentdata.birthDate)

    updateAppointmentdata(appointmentdata)

    appointmentdata.minors.map((e:any)=>e.birthDate= e.birthDate !== null && e.birthDate !== undefined && (Object.keys(e.birthDate).length)>0 
    ? parse(e.birthDate, config.dateFormat, new Date())
    : new Date())

   // updateAppointmentdata(appointmentdata)
    closeModal();  
   
  };
 


  return (
    <Modal open={showModal} onClose={closeModal}>
      <Formik
        initialValues={{location:locationdata} }
        onSubmit={() => {
          handleSearchfunction(location);
          
        }}
      >
        {() => (
          <Form>
            <Content>
              <Title>Change location</Title>
              <LocationButtonGroup />
              <ButtonsContainer>
                <Button
                  libraryType="default"
                  size="sm"
                  type="button"
                  onClick={() => { 
                    updateFormValues({ location: rollbackLocation });
                    closeModal();                    
                  }}
                >
                  Cancel
                </Button>
                <Button libraryType="primary" size="sm" type="submit">
                  Change
                </Button>
              </ButtonsContainer>
            </Content>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ChangeLocationModal;
