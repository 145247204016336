import * as React from 'react';
import Box from '@material-ui/core/Box';
import { Button } from 'antd';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { addInventory } from '../../api'
import { useAdminState } from './../../provider'

import FormControl from '@material-ui/core/FormControl';

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { Input } from '@material-ui/core';
import moment from 'moment';

function Alert(props: any) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
export default function DialogSelect(
	{
		open, 
		handleClose,
		isAntigen, 
		rapidCount, 
		getInventorys,
		getAuditLog, 
		id,
	}: any) {
	const { editLocation } = useAdminState();
	const [alertbox, setalertbox] = React.useState({
		open: false,
		type: '',
		msg: '',
	  });
	  const [loading,setLoading]=React.useState(false)
	  const [oldCount,setOldCount]=React.useState(isAntigen ? rapidCount?.antigenKitCount : rapidCount?.rapidKitCount);
	  const [newCount,setNewCount]=React.useState<any>('');
	
	const submit = async() => {
		setLoading(true);
		
		const user: any = JSON.parse(window.localStorage.getItem('userDetails')!);
		let data: any = {
			name: user.name,
			isUser: 'Update Count', 
			oldCount: isAntigen ? rapidCount?.antigenKitCount : rapidCount?.rapidKitCount,
			newCount,
			confirmationId: 'N/A',
			testType: isAntigen ? 'Antigen' : 'Rapid',
			locationName: editLocation?.name,
			id,
			createdDate: moment().format('MM/DD/YYYY h:mm A'),
		}
		const result = await addInventory(data);
		if(result.data.status === 200){
			setLoading(false);
				console.log(result);
				getInventorys({
					locationName: editLocation?.name
				})
				getAuditLog({
					locationName: editLocation?.name
				})
				handleClose()

		}
		
	}
	

	return (
		<div>
			 <Snackbar
			  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			  open={alertbox.open}
			  autoHideDuration={6000}
			  onClose={() => setalertbox({ open: false, type: '', msg: '' })}
		  >
			  <Alert
				  onClose={() =>
					  setalertbox({ open: false, type: '', msg: '' })
				  }
				  severity={alertbox.type}
			  >
				  {alertbox.msg}
			  </Alert>
		  </Snackbar>
			<Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
				<DialogTitle>Enter the {isAntigen ? 'antigen' : 'rapid'} kit count</DialogTitle>
				<DialogContent>
					<Box
						component="form"
						style={{ display: 'flex', flexWrap: 'wrap' }}
					>
						<FormControl style={{ margin: 1, minWidth: 400, minHeight:80 }}>
						   <Input 
								type="number"
								placeholder="Kit count" 
								onChange={(e: any)=> {
                                    if( e.target.value === '' )
                                        setNewCount('')
                                    else
                                        setNewCount(parseInt(e.target.value))
                                }}
						   />
						</FormControl>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button
                        type = 'link'
						onClick={handleClose}
					>
						Cancel
					</Button>
					<Button
                        disabled= { newCount === '' ? true : false }
                        type = 'primary'
						onClick={()=>{
							submit()
						}}
					>
					   {loading ?"Updating...": 'Save'}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
