import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { cloneDeep, isEqual } from 'lodash';

import { useStepsState, useStepsDispatch } from '../../../../shared/providers/schedule/provider';
import { Slot } from '../../../../shared/providers/schedule/models';

import Modal from '../../../../shared/components/Modal';
import Slots from '../../../../shared/components/Slots';
import { colors } from '../../../../../styles/colors';
import { Button } from '../../../../shared/components/styled';
import { Minor } from '../../../../shared/models';
import { useAdminDispatch, useAdminState } from '../../../provider';
import { updateAppointmentdata } from '../../../api';
import { format, parse } from 'date-fns';
import { config } from '../../../../../config';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Container = styled.div`
  max-width: 700px;
`;

const Title = styled.h2`
  color: ${colors.darkBlue};
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 13px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 240px;
  margin: 30px -5px 0;

  & > * {
    margin: 0 5px;
  }
`;

interface Props {
    showModal: boolean;
    closeModal: () => void;
}

const ChangeDateTimeModal: React.FC<Props> = ({ showModal, closeModal }) => {
    const { form } = useStepsState();
    const [alertbox, setalertbox] = React.useState({
        open: false,
        type: "",
        msg: "",
    });
    const { updateFormValues } = useStepsDispatch();
    const { setEditAppointment } = useAdminDispatch()
    const appointment = { form };
    const { slot } = form;
    const [loading, setloading] = React.useState(false);
    const { params, appointments, Editappointments } = useAdminState();
    const locationdata = Editappointments !== null ? Editappointments[0].location : null
    const appointmentdata: any = Editappointments !== null ? Editappointments[0] : null
    const rollbackAppt = useMemo(() => cloneDeep(appointmentdata), [showModal]);
    const rollbackSlot = useMemo(() => cloneDeep(appointmentdata.slot), []);
    let newSlot: Slot | null = appointmentdata.slot;
    localStorage.setItem('desiredSlot', '')
    const slotDetails:any=Editappointments?.[0]?.slot;

    return (
        <Modal open={showModal} onClose={closeModal}>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={alertbox.open}
                autoHideDuration={6000}
                onClose={() => setalertbox({ open: false, type: '', msg: '' })}
            >
                <Alert
                    onClose={() =>
                        setalertbox({ open: false, type: '', msg: '' })
                    }
                    severity={alertbox.type}
                >
                    <div dangerouslySetInnerHTML={{__html:alertbox.msg}}></div>
                </Alert>
            </Snackbar>
            <Container>
                <Title>Change date & time</Title>
                <Formik
                    initialValues={{ slot: appointmentdata?.slot }}
                    onSubmit={async () => {
                        
                        if (localStorage.getItem('desiredSlot') !== '' && JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('desiredSlot')))) !== null) {
                            appointmentdata.slot = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('desiredSlot'))))
                            appointmentdata.date = appointmentdata.slot.date
                        }

                        // appointmentdata.minors.map((e: any) => e.birthDate = e.birthDate
                        //     ? format(e.birthDate, config.dateFormat)
                        //     : appointmentdata.birthDate)
                        setloading(true)
                        let response:any = null;
                        try
                        {
                            console.log('Submit Slots final ',rollbackSlot, appointmentdata);
                            if((rollbackSlot.date !== appointmentdata?.slot?.date || rollbackSlot.period !== appointmentdata?.slot?.period) && appointmentdata.isSolvHealth)
                            {
                                response = await updateAppointmentdata({...appointmentdata, isAdmin:true})
                            }
                            else{
                                response = await updateAppointmentdata({...appointmentdata, isAdmin:false})
                            }
                            setloading(false)
                            if(!appointmentdata.isSolvHealth || response.data !== false)
                                closeModal();
                            else 
                                setalertbox({open:true,type:'error', msg:'There are no slots available for rescheduling for chosen date, please try by choosing alternate date & slot or contact SolvHealth.'})
                        }
                        catch(e)
                        {
                            setloading(false)
                            closeModal();
                        }
                    }}
                >
                    {() => (
                        <Form>
                            <Slots
                                isAdmin={true}
                                alwaysShowDatePicker
                                appointment={appointmentdata}
                                isWalkUpTest={appointmentdata?.isWalkUpTest}
                                onFieldUpdate={(update) => {

                                    newSlot = { ...update.slot } as Slot;
                                    updateFormValues({ slot: update.slot });

                                }}

                                onChangeDate={(newDepartureDate) => {
                                    appointmentdata.date = format(newDepartureDate, config.dateFormat)
                                    updateFormValues({
                                        slot: undefined,
                                        date: format(newDepartureDate, config.dateFormat),
                                    });
                                }}
                            />
                            <ButtonsContainer>
                                <Button
                                    libraryType="default"
                                    size="sm"
                                    type="button"
                                    onClick={() => {
                                        if(Editappointments && Editappointments?.length)
                                            setEditAppointment([{...Editappointments[0], slot: rollbackSlot }]);
                                        closeModal();
                                    }}
                                    disabled={loading}
                                >
                                    Cancel
                                </Button>
                                <Button libraryType="primary" size="sm" type="submit" disabled={loading}>
                                    Change
                                </Button>
                            </ButtonsContainer>
                        </Form>
                    )}
                </Formik>
            </Container>
        </Modal>
    );
};

export default ChangeDateTimeModal;
