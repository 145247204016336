import React from 'react'
import { Checkbox, Divider, Input, Select, Switch } from 'antd'
import Title from 'antd/lib/typography/Title';
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import  DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import {IoIosArrowDown, IoIosArrowUp} from 'react-icons/io'
interface props{
    name?:string,
    value?:any,
    onChange?:(data:any)=>void;
    disabled?:boolean;
    location?:any;
    title?:string;
    list?:any[];
    toggleDropDown?:boolean;
    openDropDown?:any;
    setOpenDropDown?:(data:any)=>void;
    size?:number;
    input?:any;
    date?: any;
}
const { Option } = Select;
const SwitchComponent:React.FC<props> = ({name,value=false,onChange,disabled, title='',location,list=[], toggleDropDown, openDropDown, setOpenDropDown, size=120, input = false, date=false}) => {
if(name==='testTypes'){
    return (
        <div style={{width:'100%', overflow:'auto'}}>
            <Table style={{width:'100%'}} >
                <TableHead>
                    <TableRow >
                        <TableCell style={{fontWeight:600, fontSize:18, position:'sticky',left:0,backgroundColor:'rgb(233, 229, 229)',zIndex: 2}}  >Test Type</TableCell>
                        <TableCell style={{ fontWeight: 600, fontSize: 18 }} align='center'>Show Price</TableCell>  
                        <TableCell style={{ fontWeight: 600, fontSize: 18 }} align='center'>Credit Card</TableCell>
                        <TableCell style={{ fontWeight: 600, fontSize: 18 }} align='center'>Insurance</TableCell>
                        <TableCell style={{fontWeight:600, fontSize:18}} align='center'>No Insurance</TableCell>
                        <TableCell style={{fontWeight:600, fontSize:18}} align='center'>Show Duration</TableCell>
                        <TableCell style={{fontWeight:600, fontSize:18}} align='center'>Disable</TableCell>
                        <TableCell style={{fontWeight:600, fontSize:18}} align='center'>Hide</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {/* {location.schedularLite.} */}
                {location.schedularLite.testTypes?.map((e:any,i:number)=>
                    <TableRow key={i} >
                        <TableCell style={{fontWeight:700, fontSize:18,position:'sticky',left:0,backgroundColor:'rgb(233, 229, 229)',zIndex: 2}}  >{e.title} (${e.prices})</TableCell>
                        <TableCell style={{ alignContent: 'center', justifyContent: 'center', textAlign: 'center' }}><Checkbox checked={e?.isCostLabelVisibile ?? false} onChange={({ target: { checked } }) => onChange?.({ id: e.id, isCostLabelVisibile: checked })} /></TableCell>

                        <TableCell style={{ alignContent: 'center', justifyContent: 'center', textAlign: 'center' }}><Checkbox checked={e?.isCreditCard ?? false} onChange={({ target: { checked } }) => onChange?.({ id: e.id, isCreditCard: checked })} /></TableCell>
                        <TableCell style={{ alignContent: 'center', justifyContent: 'center', textAlign: 'center' }}><Checkbox checked={e?.isInsurance ?? false} onChange={({ target: { checked } }) => onChange?.({ id: e.id, isInsurance: checked })} /></TableCell>
                        <TableCell style={{alignContent:'center',justifyContent:'center',textAlign:'center'}}><Checkbox checked={e?.isNoInsurance ?? false} onChange={({target:{checked}})=>onChange?.({id:e.id,isNoInsurance:checked})}/></TableCell>

                        
                        <TableCell style={{alignContent:'center',justifyContent:'center',textAlign:'center'}}><Checkbox checked={e?.isDuretionVisible ?? false} onChange={({target:{checked}})=>onChange?.({id:e.id,isDuretionVisible:checked})}/></TableCell>
                        <TableCell style={{alignContent:'center',justifyContent:'center',textAlign:'center'}}><Checkbox checked={e?.disabled ?? false} onChange={({target:{checked}})=>onChange?.({id:e.id,disabled:checked})}/></TableCell>
                        <TableCell style={{alignContent:'center',justifyContent:'center',textAlign:'center'}}><Checkbox checked={e?.hidden ?? false} onChange={({target:{checked}})=>onChange?.({id:e.id,hidden:checked})}/></TableCell>
                    </TableRow>
                )}
                </TableBody>
            </Table>
        </div>
        )
}
if(title==='OR')
    return <Divider orientation="center">OR</Divider>
if(title)
    return(
        <>
            {toggleDropDown ? (
                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                    <Title
                        level={3}
                        style={{
                            marginTop: '5px',
                            color: '#black',
                            textAlign: 'left',
                            fontWeight: 500,
                            fontSize: '30px',
                        }}
                    >
                        {title}
                    </Title>
                    <IconButton aria-label="Toggle Expand" onClick={()=>setOpenDropDown?.((state:any)=>({...state,moreOptions:!state?.moreOptions}))}>
                        {openDropDown?.moreOptions ? <IoIosArrowUp style={{fontSize:30}} /> : <IoIosArrowDown style={{fontSize:30}} />}
                    </IconButton>
                </div>
            ):
            (   
                <Title
                    level={3}
                    style={{
                        marginTop: '5px',
                        color: '#black',
                        textAlign: 'left',
                        fontWeight: 500,
                        fontSize: '30px',
                    }}
                >
                    {title}
                </Title>
            )}
            {toggleDropDown ? openDropDown?.moreOptions ? <hr style={{marginBottom:'30px'}}/> : '' : <hr style={{marginBottom:'30px'}}/> }
        </>
    )

return (
    <div style={{width:'100%',display:'flex',justifyContent:'space-between'}}>
        <div>
            <Title
                level={3}
                style={{
                    color: '#black',
                    textAlign: 'left',
                    fontWeight: 300,
                    fontSize: '20px',
                }}
            >
                {name}
            </Title>
        </div>
        <div>
            {input ? <Input onChange={onChange} value={ value ?? ''} key={name}/> : list?.length > 0 ? (
            <Select style={{ width: size }} labelInValue value={{value:value ?? '', label : value ?? '' }} onChange={onChange}>
            {list.map((e:{value:any,label:any},i:number)=><Option key={i} value={e.value}>{e.label}</Option>)}
          </Select>
            ) :
            date ?
            <DatePicker
              containerStyle={{
                width: "100%"
              }}
             multiple
             minDate={new Date()}
             value={value}
             plugins={[
                <DatePanel/>
               ]}
             format="MM/DD/YYYY"
             render={(value:any, openCalendar:any) => {
                 return (
                   <Input onClick={openCalendar} value={value} />
                 )
               }}

            onChange={onChange}            
            />: (<Switch style={{marginTop:'10px'}} onChange={onChange} disabled={disabled} checked={value ?? false}/>)}
        </div>
    </div>
  )
}

export default SwitchComponent