import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, Spin } from 'antd';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { colors } from '../../../../../../src/styles/colors';
import { Breakpoints } from '../../../../../../src/dictionaries';
import { sessionToSentryRequest } from '@sentry/core';
import { upgradeTestType,getEmployeeCoupon } from '../../../api';

const Text = styled.p`
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    white-space: nowrap;
    display: block;
    width: 100%;
    min-width: 1px;
`;

const InnerBox = styled.div`
    border: 1px solid rgb(26, 150, 219);
    margin-right: 10px;
    width: 40%;
    border-radius: 10px;
    /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
    cursor: pointer;
    align-items: center;
    text-align: center;
    display: grid;
    background: ${({ selected }: { selected?: boolean }) =>
        selected ? colors.blueLight : colors.white};
`;

const TestTypeTitle = styled.p`
    font-size: medium;
    font-weight: bold;
    color: ${({ selected }: { selected?: boolean }) =>
        selected ? colors.white : colors.blueLight};
    @media (max-width: ${Breakpoints.sm}px) {
        font-size: 15px;
        font-weight: bold;
        color: ${({ selected }: { selected: boolean }) =>
            selected ? colors.white : colors.blueLight};
    }
`;

const TestCostLabel = styled.p`
    font-size: 40px;
    font-weight: bold;
    color: ${({ selected }: { selected: boolean }) =>
        selected ? colors.white : colors.blueLight};
    @media (max-width: ${Breakpoints.sm}px) {
        font-size: 30px;
        font-weight: bold;
        color: ${({ selected }: { selected: boolean }) =>
            selected ? colors.white : colors.blueLight};
    }
`;

const TestDurationInfoLabel = styled.p`
    font-size: 12px;
    font-weight: bold;
    color: ${({ selected }: { selected: boolean }) =>
        selected ? colors.white : colors.blueLight};
    @media (max-width: ${Breakpoints.sm}px) {
        font-size: 9px;
        font-weight: bold;
        color: ${({ selected }: { selected?: boolean }) =>
            selected ? colors.white : colors.blueLight};
    }
`;

const Button = styled.button`
    min-width: 50px;
    padding: 7px 7px;
    border: 1px solid #3734d1;
    border-radius: 3px;

    color: ${({ selected }: { selected: boolean }) =>
        selected ? colors.white : '#3734d1'};
    background-color: ${({ selected }: { selected: boolean }) =>
        selected ? '#3734d1' : colors.white};
`;

interface props {
    testType: string;
    confirmationId: string;
    onClose: any;
    hasRapid?: boolean;
    hasExpress?: boolean;
    handleSearch: () => void;
    testSelection?: string;
    email?: string;
    record: any;
}
function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Upgrade = ({ testType,confirmationId, onClose, hasRapid, hasExpress,handleSearch, testSelection, email, record }: props) => {
    const [isModalVisible, setIsModalVisible] = useState(true);
    const [isSecondModalVisible, setIsSecondModalVisible] = useState(false);
    const [testSelected, setTest] = useState('');
    const [loading, setLoading] = useState(false)
    const [isEmpEmail, setIsEmpEmail] = useState(false);
    const [alertbox, setalertbox] = React.useState({
        open: false,
        type: '',
        msg: '',
    });
    const onCancel = () => {
        setIsModalVisible(false);
        onClose(false);
    };

    useEffect(()=>{
        getEmployeeEmail()
    },[])

    const getEmployeeEmail = async () => {
        let empData = await getEmployeeCoupon(email)
        const dataEmail: any = email?.split('@');
        const worksiteEmail = dataEmail[1];
        if (
            (worksiteEmail === 'worksitelabs.com' || !!empData.data) &&
            testSelection === 'general'
        ) {
            setIsEmpEmail(true)
        
        }
    }

    const onUpgrade = () => {
        if (testSelected !== '') {
            setIsModalVisible(false);
            setIsSecondModalVisible(true);
        }
        else{
            setalertbox({
                open: true,
                type: 'info',
                msg: 'Please select the test type to upgrade',
            });
        }
    };

    const onReject = () => {
        setIsSecondModalVisible(false);
        setIsModalVisible(true);
    };

    const onAccept = async() => {
        setLoading(true)
        const data = await upgradeTestType({
            testType: testSelected,
            confirmationId,
            isEmpEmail
        });
        if(data.data.status === 200){
            setLoading(false);
            setIsSecondModalVisible(false);
            //handleSearch()
        }
    };

    const TestTypeBox: React.FC<any> = ({
        testType,
        title,
        testDuration,
        testCost,
        selected,
        setTest,
        locationTestType,
    }) => {
        const handle = ()=>{
            if(!locationTestType){
                return
            }
            setTest()
        
        }
        return (
            <InnerBox selected={selected}  onClick={handle}
            style={ 
                     {
                          border: !locationTestType
                              ? '1px solid #D3D3D3'
                              : '',
                          background: !locationTestType
                              ? '#D3D3D3'
                              : '',
                          cursor: !locationTestType
                              ? 'not-allowed'
                              : 'pointer',
                      }
            }
            >
                <TestTypeTitle style={{
                         color: !locationTestType
                         ? '#939393'
                         : '',
                          cursor: !locationTestType
                              ? 'not-allowed'
                              : 'pointer',
                      }} selected={selected}>{title}</TestTypeTitle>

                <TestCostLabel style={{
                          color: !locationTestType
                          ? '#939393'
                          : '',
                          cursor: !locationTestType
                              ? 'not-allowed'
                              : 'pointer',
                      }} selected={selected}>${testCost}</TestCostLabel>

                <TestDurationInfoLabel style={{
                                  color: !locationTestType
                                      ? '#939393'
                                      : '',
                                      cursor: !locationTestType
                              ? 'not-allowed'
                              : 'pointer',
                              }}
                 selected={selected}>
                    Results within {testDuration} hours
                </TestDurationInfoLabel>
            </InnerBox>
        );
    };

    return (
        <>
            <Modal
                zIndex={1000}
                // maskClosable={false}
                visible={isModalVisible}
                title={
                    <span
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                        }}
                    >
                        Select a test type to upgrade to:
                    </span>
                }
                closable={false}
                footer={
                    <div
                        style={{
                            margin: 'auto',
                            textAlign: 'center',
                        }}
                    >
                        <Button onClick={() => onCancel()} selected={false}>
                            Cancel
                        </Button>
                        <Button onClick={() => onUpgrade()} selected={true}>
                            Upgrade
                        </Button>
                    </div>
                }
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                    }}
                >
                    {hasRapid && (
                        <TestTypeBox
                            testType={'Rapid'}
                            title={'Rapid PCR Test'}
                            testDuration={'1.5'}
                            locationTestType={isEmpEmail ? false : true}
                            testCost={
                                record?.collectionmethod === 'Nasopharyngeal'
                                    ? 300
                                    : 250
                            }
                            setTest={() => {
                                setTest('Rapid PCR Test');
                            }}
                            selected={
                                testSelected === 'Rapid PCR Test' ? true : false
                            }
                        ></TestTypeBox>
                    )}
                    {testType === 'Standard PCR Test' && hasExpress && (
                        <TestTypeBox
                            testType={'Express'}
                            title={'Express PCR Test'}
                            testDuration={'12'}
                            locationTestType={true}
                            testCost={
                                record?.collectionmethod === 'Nasopharyngeal'
                                    ? 225
                                    : 150
                            }
                            setTest={() => {
                                setTest('Express PCR Test');
                            }}
                            selected={
                                testSelected === 'Express PCR Test' ? true : false
                            }
                        ></TestTypeBox>
                    )}
                </div>
                {record?.collectionmethod === 'Nasopharyngeal' ? (
                    <p>
                        <br />
                        The pricing for this nasopharyngeal collection type is
                        as follows: Standard: $150; Express: $225; Rapid: $300.
                    </p>
                ) : (
                    ''
                )}
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={alertbox.open}
                    autoHideDuration={2000}
                    onClose={() =>
                        setalertbox({ open: false, type: '', msg: '' })
                    }
                >
                    <Alert
                        onClose={() =>
                            setalertbox({ open: false, type: '', msg: '' })
                        }
                        severity={alertbox.type}
                    >
                        {alertbox.msg}
                    </Alert>
                </Snackbar>
            </Modal>

            <Modal
                zIndex={1000}
                maskClosable={false}
                visible={isSecondModalVisible}
                closable={false}
                footer={
                    <div
                        style={{
                            margin: 'auto',
                            textAlign: 'center',
                        }}
                    >
                        {!loading && (
                            <>
                                <Button
                                    onClick={() => onReject()}
                                    selected={false}
                                >
                                    No
                                </Button>
                                <Button
                                    onClick={() => onAccept()}
                                    selected={true}
                                >
                                    Yes
                                </Button>
                            </>
                        )}
                    </div>
                }
            >
                <span
                    style={{ justifyContent: 'space-around', margin: 'auto' }}
                >
                    {!isEmpEmail ? (
                        <p
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-around',
                                textAlign: 'justify',
                            }}
                        >
                            Are you sure you want to upgrade the test type
                            <br /> from {testType.replace('Test', '')} to{' '}
                            {testSelected.replace('Test', '')}? Doing so will
                            send a
                            <br /> notification to the customer and ask them to
                            pay
                            <br /> for the the full price of upgraded
                            appointment via credit card.
                            <br /> After paying, the original appointment will
                            be cancelled.
                        </p>
                    ) : (
                        <p
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-around',
                                textAlign: 'justify',
                            }}
                        >
                            Are you sure you want to upgrade the test type
                            <br /> from {testType.replace('Test', '')} to{' '}
                            {testSelected.replace('Test', '')}? Doing so will
                            send a
                            <br /> notification to the customer and the customer
                            won't be charged.
                        </p>
                    )}
                    {loading && (
                        <p style={{ textAlign: 'center', padding: '8px' }}>
                            <Spin />
                        </p>
                    )}
                </span>
            </Modal>
        </>
    );
};

export default Upgrade;
