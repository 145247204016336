import { format } from 'date-fns';
import { firebase } from '../../../../firebase';
export const getQbenchacknowledgement = async (params: any) =>
  firebase
    .functions()
    .httpsCallable('getQbenchacknowledgement', { timeout: 540 * 1000 })(params);

export const updateNoteQbenchacknowledgement = async (params: any) =>
  firebase
    .functions()
    .httpsCallable('updateNoteQbenchacknowledgement', { timeout: 540 * 1000 })(
        {...params,email:JSON.parse(window.localStorage.getItem('userDetails')!).email,date: format(new Date(),'LL/dd/yyyy hh:mm a ')}
  );