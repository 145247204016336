import axios from "axios";
import {firebase} from "../../../../firebase";
import {config} from "../../../../config";
import { servicedata } from "./Model";
// export const getApiData = async (url:any) => {
//     const responsedata = await axios({
//         method: 'POST',
//         url: `${config.baseURL}/${url}`,
//         headers: {
//             'content-type': 'application/json'
//         }
//     });
//     return responsedata;
// }
export const getApiData = async (url:any) => {
    return firebase
    .functions()
    .httpsCallable(url)();
}
const user: any = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('userDetails'))))

export const postService = async (data: servicedata,id:null|string=null) => {   
    let price = 0;
    if(data.price!=='')
        price = parseInt(data.price);
    data = {...data,time:data.time.value}
    let responsedata;
    if(id===''||id===null)
    {
        responsedata = await axios({
            method: 'POST',
            url: `${config.baseURL}/postServices`,
            headers: {
                'content-type': 'application/json'
            },
            data: {
                name: data.name,
                time: data.time,
                price,
                description: data.description
            }
        });
    }
    else
    {
        responsedata = await axios({
            method: 'POST',
            url: `${config.baseURL}/updateServices`,
            headers: {
                'content-type': 'application/json'
            },
            data: {
                id: id,
                name: data.name,
                time: data.time,
                price,
                description: data.description
            }
        });
    }
    return responsedata;
}


export const modifyLocations = async (data: any) => {
    const responsedata = await axios({
        method: 'POST',
        url: `${config.baseURL}/postorupdateLocation`,
        headers: {
            'content-type': 'application/json'
        },
        data:data
    });
    console.log('response data',responsedata)
    console.log('data ......',data)
    return responsedata;
}

// export const editSlotsConfiguration = async (data: any) => {
//     const responsedata = await axios({
//         method: 'POST',
//         url: `${config.baseURL}/editSlotsConfiguration`,
//         headers: {
//             'content-type': 'application/json'
//         },
//         data:data
//     });
//     console.log('response data',responsedata)
//     console.log('data ......',data)
//     return responsedata;
// }

export const editSlotsConfigurationDummy = async (data: any)=>{
    data.data.updatedBy = user?.email ?? ''
    data.data.userName = user?.name ?? ''
    data.data.updatedFrom = 'Location Management -Edit Slots'
    const responsedata = await axios({
        method: 'POST',
        url: `${config.baseURL}/editSlotsConfigurationDummy`,
        headers: {
            'content-type': 'application/json'
        },
        data:data
    });
    console.log('response data',responsedata)
    console.log('data ......',data)
    return responsedata;
}


export const addHolidays = async (datas: any) => {
    datas.updatedBy=user?.email ?? ''
    datas.userName=user?.name ?? ''
    datas.updatedFrom='Location Management - AddBlockOut Days'
    // const responsedata = await axios({
    //     method: 'POST',
    //     url: `${config.baseURL}/addBlockOutDays`,
    //     headers: {
    //         'content-type': 'application/json'
    //     },
    //     data:{
    //         data:datas
    //     }
    // });
    const responsedata = await firebase
    .functions()
    .httpsCallable('addBlockOutDays', { timeout: 540 * 1000 })({
        ...datas
    });
    console.log('response data',responsedata)
    console.log('data ......',datas)
    return responsedata;
}

export const loadstate=()=>
    firebase.firestore().collection(config.firestoreCollections.state).get().then((response:any)=>{
        const data: any = response.docs.map(
            (doc: any) => doc.data()
        );
        return data   
    })

export const loadCountry=()=>
    firebase.firestore().collection(config.firestoreCollections.country).get().then((response:any)=>{
        const data: any = response.docs.map(
            (doc: any) => doc.data()
        );
        return data   
    })

export const getJotFormConfigList = async () => {
    try {
        const responseData = await axios({
            method: 'POST',
            url: `${config.baseURL}/getJotFormConfig`,
            headers: {
                'content-type': 'application/json'
            },
            data: { data: {} }
        });
        console.log(responseData);

        return responseData;
    }
    catch (error) {
        console.log(error);
    }
}

export const storeJotFormConfig = async (data:any) => {
    try {
        const responseData = await axios({
            method: 'POST',
            url: `${config.baseURL}/storeJotFormConfig`,
            headers: {
                'content-type': 'application/json'
            },
            data: {data: data}
        });
        console.log(responseData);

        return responseData;
    }
    catch (error) {
        console.log(error);
    }
}
// export const getQbenchCustomers= async() => {
//     try{
//         const responseData = await axios({
//            method: 'POST',
//            url: `${config.baseURL}/getQbenchCustomerList`,
//            headers: {
//                'content-type': 'application/json'
//            },
//            data: {data: {}}
//         });
//         return responseData;
//     }
//     catch(error){
//         console.log(error);
//     }

// }
export const getQbenchCustomers = async () => {
    try {
        const response = await firebase.functions().httpsCallable('getQbenchCustomerList')();
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};
   
export const getAssayList = async () => {
    const responsedata = await axios({
        method: 'POST',
        url: `${config.baseURL}/getQbenchAssayList`,
        headers: {
            'content-type': 'application/json'
        },
        data: {data: {}}

    });
    return responsedata;
}

// export const getLocation = async () => {
//     const responsedata = await axios({
//         method: 'POST',
//         url: `${config.baseURL}/getLocation`,
//         headers: {
//             'content-type': 'application/json'
//         },
//         data: {data: {}}

//     });
//     return responsedata;
// }
export const getLocation = async () =>{
    return firebase
    .functions()
    .httpsCallable('getLocation')()
}
