import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { DatePicker } from 'antd';
import { config } from '../../../../config';
import { styles } from '../../../admin/components/AppointmentList/styles';


const { RangePicker } = DatePicker;

const AntdRangePicker: React.FC<any> = ({onChange, dates, setDates, disabled=false, range=7}) => {
  const [value, setValue] = useState<any>(null);
  const disabledDate = (current: any) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > range;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > range;
    return !!tooEarly || !!tooLate;
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  return (
    <RangePicker
      disabled={disabled}
      style={styles.fullWidth}
      value={dates || value}
      disabledDate={disabledDate}
      onCalendarChange={val => setDates(val)}
      onChange={val => { setValue(val);
        onChange(val?.map((date:any | null)=> date ? date.calendar(config.dateFormat) : '').join(', ') || '')
      }}
      onOpenChange={onOpenChange}
      onBlur={() => console.log('blur has been triggered')}
    />
  );
};

export default AntdRangePicker;