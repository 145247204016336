import { firebase } from '../../../../firebase';
export interface invoiceType {
  firstName: string;
  lastName: string;
  middleName?: string;
  confirmationId: string;
  phone: string;
  birthDate: string;
  email: string;
  date: string;
  locationQbenchId: any;
  sampleId?: number | null;
  thirdPartyAppointments:string;
  toggle:string;
  status?:string;
  specialDaywise?:any,
  isWeeklyTesting?:boolean|null;
  allSchedulerLite?:boolean | null ;
  allAppointments?: boolean | null ;
  payingMethod?: string;
}
export const getInvoiceData = (data: invoiceType) => {
    return firebase.functions().httpsCallable('wslSpecialdaywise')(data);
};

export const getNpDestionation = async () =>
    firebase
        .functions()
        .httpsCallable('getNpDestionation')();