import { Typography } from '@material-ui/core';
import { Modal, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import QBenchUpdateConfirmation from './QBenchUpdateConfirmation';
import { stylesForListing } from './styles';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { getAllQbenchCustomerIdsFromLocation } from '../../api';
function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const { Option } = Select;

interface IProps {
    title: string;
    showQBenchUpdateModal: boolean;
    setShowQBenchUpdateModal: Function;
    QBcustomerData: any;
    selectedData: any;
    QBdata: any;
    updData: any;
    isAssay: boolean;
    isUpdatingForRapid: boolean;
    isUpdatingForAntigen: boolean;
    setConfirmedUpdatedData: any;
    handleDrawerToggle: any;
}
const QBenchUpdateModal = ({
    title,
    showQBenchUpdateModal,
    setShowQBenchUpdateModal,
    QBcustomerData,
    selectedData,
    QBdata,
    updData,
    isAssay,
    isUpdatingForRapid,
    isUpdatingForAntigen,
    setConfirmedUpdatedData,
    handleDrawerToggle,
}: IProps) => {
    const classes = stylesForListing();
    const [updatedValue, setUpdatedValue] = useState(updData);
    const [showUpdateConfirm, setShowUpdateConfirm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alertbox, setalertbox] = useState({
        open: false,
        type: '',
        msg: '',
    });
    const handleCancel = () => {
        setUpdatedValue(updData);
        setShowQBenchUpdateModal(false);
    };
    const handleOk = async () => {
        setLoading(true);
        if (isAssay === true) {
            setShowUpdateConfirm(true);
        } else {
            const res = await getAllQbenchCustomerIdsFromLocation({
                qbenchCustomerId: QBdata.qbenchCustomerId,
            });
            if (res.data.status === 200) {
                if (res.data.message) {
                    let allOldCustomerIds = res.data.message.filter(
                        (v: any, i: any, a: any) => a.indexOf(v) === i
                    );
                    console.log('allOldCustomerIds', allOldCustomerIds);
                    allOldCustomerIds.includes(updatedValue.record)
                        ? setalertbox({
                              open: true,
                              type: 'warning',
                              msg:
                                  'This Qbench Customer Id is already used in other location',
                          })
                        : setShowUpdateConfirm(true);
                } else {
                    setalertbox({
                        open: true,
                        type: 'error',
                        msg: 'Internal error',
                    });
                    console.log('res :::', res.data);
                }
            } else {
                setalertbox({
                    open: true,
                    type: 'error',
                    msg: 'Internal error',
                });
                console.log('res :::', res.data);
            }
        }

        setLoading(false);
    };

    return (
        <Modal
            zIndex={1000}
            maskClosable={false}
            visible={showQBenchUpdateModal}
            onCancel={handleCancel}
            onOk={handleOk}
            okText={'Update'}
        >
            {QBcustomerData ? (
                <div style={{ zIndex: 3000, padding: '8px', width: '100%' }}>
                    <Typography
                        variant="h6"
                        color="initial"
                        className={classes.fontweight700}
                    >
                        {title}
                    </Typography>
                    <br />
                    {/* <Select value={6} onChange={() => {}}>
                        {QBdata.map((data: any, index: any) => {
                            return (
                                <MenuItem
                                    style={{ zIndex: 3000 }}
                                    key={index}
                                    value={data.record}
                                >
                                    {data.label}
                                </MenuItem>
                            );
                        })}
                    </Select> */}
                    <Select
                        showArrow
                        defaultValue={selectedData}
                        onChange={(value, option: any) => {
                            setUpdatedValue({
                                label: option.children,
                                record: value,
                            });
                        }}
                        value={updatedValue.record}
                        style={{
                            zIndex: 3000,
                            width: '100%',
                            padding: '2px',
                            fontSize: '18px',
                        }}
                    >
                        {QBcustomerData.map((data: any, index: any) => {
                            return (
                                <Option
                                    style={{ zIndex: 3000 }}
                                    key={index}
                                    value={data.record}
                                >
                                    {data.label}
                                </Option>
                            );
                        })}
                    </Select>
                    <br />
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        open={alertbox.open}
                        autoHideDuration={3000}
                        onClose={() =>
                            setalertbox({ open: false, type: '', msg: '' })
                        }
                    >
                        <Alert
                            onClose={() =>
                                setalertbox({ open: false, type: '', msg: '' })
                            }
                            severity={alertbox.type}
                        >
                            {alertbox.msg}
                        </Alert>
                    </Snackbar>
                </div>
            ) : (
                <h2 style={{ padding: '8px', textAlign: 'center' }}>
                    <Spin />
                </h2>
            )}
            {loading && (
                <h2 style={{ padding: '8px', textAlign: 'center' }}>
                    <Spin />
                </h2>
            )}
            {showUpdateConfirm && (
                <QBenchUpdateConfirmation
                    setShowQBenchUpdateModal={setShowQBenchUpdateModal}
                    showUpdateConfirm={showUpdateConfirm}
                    setShowUpdateConfirm={setShowUpdateConfirm}
                    updatedValue={updatedValue}
                    QBdata={QBdata}
                    isAssay={isAssay}
                    isUpdatingForRapid={isUpdatingForRapid}
                    isUpdatingForAntigen={isUpdatingForAntigen}
                    setConfirmedUpdatedData={setConfirmedUpdatedData}
                    handleDrawerToggle={handleDrawerToggle}
                />
            )}
        </Modal>
    );
};

export default QBenchUpdateModal;
