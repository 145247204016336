import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Popconfirm } from 'antd';
import { AppointmentListItem } from '../../../models';
import { cancelAppointment, deleteNpDest } from '../../../../shared/providers/schedule/api';
import { CloseOutlined } from '@ant-design/icons';
import { useAdminDispatch, useAdminState } from '../../../provider';

const Text = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  white-space: nowrap;
  display: block;
  width: 100%;
  min-width: 1px;
`;

interface IProps {
    appt?: AppointmentListItem;
    isNpDest?: boolean;
    deletes?: any;
    getNpDest?: () => void;
    handleSearch:()=>void;
}

const Cancel = ({ appt, isNpDest, deletes, getNpDest ,handleSearch}: IProps) => {
    const { setAppointments } = useAdminDispatch();
    const { appointments } = useAdminState();
    const [cancelText, setCancelText] = useState(
        isNpDest === true ? 'Delete' : 'Cancel'
    );
    const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

    useEffect(() => {
        if (appt?.isCanceled !== '' && appt?.isCanceled !== false && isNpDest !== true) {
            setCancelText('Cancelled');
        }
    }, []);

    let index:any=[]
    if(appt?.minorIndex===null || appt?.minorIndex===-1 ){
        index.push(-1)
    }else{
        index.push(appt?.minorIndex)
    }
    const handleCancel = async () => {
        setCancelText('Cancel');
        try {
            if (appt?.appointmentId) {
                let params:any={id:appt.appointmentId}
                params['index']=index
                setCancelText('Cancelling ...');
                const cancel=  await cancelAppointment(params);
                if(cancel.data.status==="Appointment cancelled"){
                    await sleep(1000);
                    setCancelText('Cancelled');
                    await sleep(1000);
                    //handleSearch();   
                    let tableDatas: any = appointments;
                    tableDatas = appointments?.map((data: any) => {
                        let rowData: any = data;
                        if (data.sampleId === appt.sampleId) {
                            rowData = {
                                ...rowData,
                                isCanceled: true,
                                status: 'Cancelled',
                            };
                        }
                        return rowData;
                    });
                    setAppointments(tableDatas);              
                }
            }
        } catch (e) {
            setCancelText('Error');
            console.error(e);
        }
    };
    const handleDelete = async () => {
        try {
            if (isNpDest === true) {
                setCancelText('Deleting ...');
                const del = await deleteNpDest(deletes);
                if (del?.data?.status === 200) {
                    console.log('delete1');
                    if (getNpDest) {
                        const data = await getNpDest();
                        console.log('delete2', data);
                    }
                    setCancelText('Deleted');
                }
            }
        } catch (e) {
            setCancelText('Error');
            console.error(e);
        }
    };
    if (cancelText === 'Cancel') {
        return (
            <Popconfirm
                title={
                    'Are you sure you would like to cancel this appointment?'
                }
                onConfirm={handleCancel}
                okText="Yes"
                cancelText="No"
                disabled={cancelText !== 'Cancel' || appt?.results !== '' || appt?.isCheckedIn || (appt.isPanel && appt.tests.filter((e:any)=>e.result).length)}
            >
                <Button
                    type="link"
                    disabled={cancelText !== 'Cancel' || appt?.results !== '' || appt?.isCheckedIn || (appt.isPanel && appt.tests.filter((e:any)=>e.result).length)}
                    block
                    icon={<CloseOutlined />}
                >
                    {cancelText}
                </Button>
            </Popconfirm>
        );
    } else if (cancelText === 'Delete') {
        return (
            <Popconfirm
                title={
                    'Are you sure you would like to delete this NP destination?'
                }
                onConfirm={handleDelete}
                okText="Yes"
                cancelText="No"
            >
                <Button
                    type="link"
                    disabled={cancelText !== 'Delete'}
                    block
                    icon={<CloseOutlined />}
                >
                    {cancelText}
                </Button>
            </Popconfirm>
        );
    } else {
        return <Text>{cancelText}</Text>;
    }
};

export default Cancel;
