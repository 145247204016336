import * as React from "react";
import { styled } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import PrintSampleLabels from './PrintSampleLabels'
import { useAdminDispatch, useAdminState } from '../../../provider';
import axios from "axios";
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import AddNote from "./AddNote";
import { config } from '../../../../../config';
import { processNoShow } from "../../../api";
import firebase from "firebase";
function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

interface Props {
    getDay: () => void;
    handleSearch: () => void;
};

const ActionBox: React.FC<Props> = ({ getDay, handleSearch }) => {
    const [loading, setLoading] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [printModalOpen, setPrintModalOpen] = React.useState(false);
    const [openAddNote, setOpenAddNote] = React.useState(false);
    const open = Boolean(anchorEl);
    const [undoCheckIn, setUndoCheckIn] = React.useState<boolean>(false);
    const [alertbox, setalertbox] = React.useState({
        open: false,
        type: '',
        msg: '',
    });
  const { setAppointments } = useAdminDispatch();
    const { getPrintSampleLabels, appointments } = useAdminState();
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    let selectedData: any = getPrintSampleLabels;

    const handleAddNoteOpen = () => {
        if (selectedData.length === 0) {
            setalertbox({
                type: 'info',
                msg: 'Atleast one Appointment should select',
                open: true,
            });
        } else {
            setOpenAddNote(true);
        }
    };

    const handleAddNoteClose = () => {
        setOpenAddNote(false);
        handleClose();
    };

    const UndoCheckIn = async () => {
        try {
            let timeSample: any = [];
            selectedData.map((rowData: any) => {
                // data['SampleId'] = rowData.sampleId;
                let SampleIds = rowData.sampleId;
                timeSample.push(SampleIds);
            });
            console.log('checkindeletedata', timeSample);
            // const resData: any = await axios({
            //     method: 'POST',
            //     url: `${config.baseURL}/deleteUserCheckIn`,
            //     headers: {
            //         'content-type': 'application/json',
            //     },
            //     data: { data: { SampleId: timeSample } },
            // });
            firebase.functions()
            .httpsCallable('deleteUserCheckIn',{timeout:540*1000})({SampleId : timeSample }).then((res) => {
                if (res?.data) {
                    selectedData.forEach(async (rowData: any) => {
                        const UndoFlag: any =
                        firebase.functions()
                        .httpsCallable('updateIscheckedInFalse',{timeout:540*1000})({id: rowData.id }).then((res) => {console.log("updateIscheckedInFalse-res",res)});
                        //  await axios({
                        //     method: 'POST',
                        //     url: `${config.baseURL}/updateIscheckedInFalse`,
                        //     headers: {
                        //         'content-type': 'application/json',
                        //     },
    
                        //     data: { data: { id: rowData.id } },
                        // });
                       
                        
                        // getDay();
                        handleSearch();
                        let tableDatas: any = appointments;
                        tableDatas = appointments?.map((data: any) => {
                            let rowData: any = data;
                            if (timeSample.includes(data.sampleId)) {
                                rowData = {
                                    ...rowData,
                                    isCheckedIn: false,
                                    checkInExpTime: null,
                                    checkInTime: null,
                                };
                            }
                            return rowData;
                        });
                        setAppointments(tableDatas);
                    });
                    getDay();
                    handleClose();
                    setalertbox({
                        type: 'info',
                        msg: 'Time of collection deleted Successfully',
                        open: true,
                    });
                }
            });
        } catch (error) {
            console.log(error);
            setalertbox({ open: true, type: 'error', msg: 'Failed' });
        }
    };
    const handleNoShow =async (isAddNoShow:boolean) => {
        setLoading(true) 
        const sampleId = selectedData.map((e:any)=>e.sampleId)
        const result =await processNoShow({sampleId, isAddNoShow })
        if(result){
              handleSearch()
              let tableDatas: any = appointments;
              tableDatas = appointments?.map((data: any) => {
                  let rowData: any = data;
                  if (sampleId?.includes(data?.sampleId)) {
                      rowData = {...rowData, noShow: isAddNoShow ? 'No Show' : ''};
                  }
                  return rowData;
              });
              setAppointments(tableDatas);
              getDay()
              handleClose();
        } else {
            setalertbox({open:true,type:'error',msg:'Something Went Wrong'})
        }
        setLoading(false) 
      
    }
    React.useEffect(() => {
        if (openAddNote === false) {
            getDay();
            handleClose();
        }
    }, [openAddNote]);
    React.useEffect(() => {
        if (printModalOpen === false) {
            getDay();
            handleClose();
        }
    }, [printModalOpen]);
    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={alertbox.open}
                autoHideDuration={6000}
                onClose={() => setalertbox({ open: false, type: '', msg: '' })}
            >
                <Alert
                    onClose={() =>
                        setalertbox({ open: false, type: '', msg: '' })
                    }
                    severity={alertbox.type}
                >
                    {alertbox.msg}
                </Alert>
            </Snackbar>
            <Button
                color="primary"
                aria-controls="demo-customized-menu"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={(e: any) => {
                    if (selectedData?.length > 0) handleClick(e);
                    else
                        setalertbox({
                            open: true,
                            type: 'warning',
                            msg: 'No Row Selected',
                        });
                }}
                endIcon={<KeyboardArrowDownIcon />}
            >
                Actions
            </Button>
            <StyledMenu
                style={{ marginTop: '38px' }}
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem style={{ padding: '0' }}>
                    <PrintSampleLabels
                        btnShow={true}
                        open={printModalOpen}
                        setOpen={(data) => setPrintModalOpen(data)}
                        handleSearch={handleSearch}
                    />
                </MenuItem>
                <Button
                    disabled={undoCheckIn}
                    style={{
                        textTransform: 'none',
                        width: '100%',
                        justifyContent: 'flex-start',
                    }}
                    onClick={async () => {
                        setUndoCheckIn(true);
                        await UndoCheckIn().then(() => setUndoCheckIn(false));
                        handleClose();
                    }}
                >
                    {undoCheckIn ? 'Processing...' : 'Undo CheckIn'}
                </Button>
                <br />
                <Button
                    style={{
                        textTransform: 'none',
                        width: '100%',
                        justifyContent: 'flex-start',
                    }}
                    onClick={() => handleAddNoteOpen()}
                >
                    Add Note
                </Button>
                {selectedData.some((e:any)=>e?.noShow === '') && (<Button
                    style={{
                        textTransform: 'none',
                        width: '100%',
                        justifyContent: 'flex-start',
                    }}
                    onClick={() => handleNoShow(true)}
                >
                    {loading ? 'Processing... ': 'Add No Show'}
                </Button>  )}
                {selectedData.some((e:any)=>!!e?.noShow) && (<Button
                    style={{
                        textTransform: 'none',
                        width: '100%',
                        justifyContent: 'flex-start',
                    }}
                    onClick={() => handleNoShow(false)}
                >
                        {loading? 'Processing...':'Undo  No Show'}
                </Button>  )}
            </StyledMenu>
            {openAddNote && (
                    <AddNote
                        open={openAddNote}
                        handleAddNoteClose={handleAddNoteClose}
                        appointmentsData={selectedData}
                        handleSearch={handleSearch}
                    />
                )}
        </div>
    );
};
export default ActionBox;
