import React, {
  useCallback,
  useState,
  createContext,
  useMemo,
  useEffect,
} from 'react';
import { User } from 'firebase/app';
import { format, parse, compareAsc, compareDesc } from 'date-fns';

import {
  AdminState,
  AdminDispatch,
  AppointmentListItem,
  Comparables,
  AppointmentsListParams,
  ConsolidationParams,
  Appointment,
} from './models';
import { SortByDirection, FieldType } from './dictionaries';
import { firebase } from '../../firebase';
import { config } from '../../config';
import { getNewSlots} from "../shared/api";
import { AdminManagePage } from './page/Manage/dictionaries';
import { getPrices } from '../shared/providers/schedule/api';
import { SetPrices } from '../../utils';
// import { steps } from '../admin/page/InventoryTracker/Inventorystep';
import {steps} from '../admin/page/LocationAndService/Locationstep'
import { getAllClientPartner } from './page/SchedularLite/API';
const users: any = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('userDetails'))))
export const AdminContext = createContext<AdminState>({
    params: {
        firstName: '',
        lastName: '',
        confirmationId: '',
        phone: '',
        birthDate: '',
        email: '',
        date: format(new Date(), config.dateFormat),
        locationQbenchId: null,
        sampleId: null,
        pageNumber: 1,
        pageSize: 100,
        thirdPartyAppointments:'',
        status:''
    },
    appointments: null,
    Editappointments: null,
    sortBy: null,
    sortByDirection: SortByDirection.Ascending,
    user: null,
    configured: false,
    adminSlots: [],
    isLoginError: false,
    getID: null,
    userRole: '',
    currentManagePage: AdminManagePage.ManageScreen,
    appointmentList: null,
    manageAppointment: null,
    apptPricesAdmin: {
        standard: 0,
        expedited: 0,
        rapid: 0,
        standard_INT: 0,
        expedited_INT: 0,
        rapid_INT: 0,
        antigen: 0,
        antigen_INT: 0,
    },
    showStandradTestAdmin: false,
    showAdminLocationModal: false,
    getPrintSampleLabels: '',
    getPrintID: '',
    IsMinorAccount: false,
    minorEmail: '',
    isAntigen: false,
    editLocation: null,
    GetServiceData: [],
    locationData: {
      traveltypes: {
          international: false,
          cruises: false,
          events: false,
          domestic: false,
      },
      testtype: {
          standard: false,
          express: false,
          rapid: false,
          antigen: false,
          panel: false,
          standardNp: false,
          expressNp: false,
          rapidNp: false,
      },
      weeksofoperation: {
          tue: false,
          mon: false,
          sun: false,
          wed: false,
          thu: false,
          fri: false,
          sat: false,
      },
      locationname: '',
      cPartnerID: '',
      address1: '',
      address2: '',
      visibleSince: '',
      medicalDirector: '',
      country: { label: '', value: '' },
      state: { label: '', value: '' },
      city: '',
      zipcode: '',
      googlemapshortlink: '',
      sitestatus: { label: '', value: '' },
      delayopenstartdate: '',
      acceptance: { label: '', value: '' },
      slotcapacity: { label: '', value: '' },
      fromhourofoperation: { label: '', value: '' },
      tohourofoperation: { label: '', value: '' },
      qbenchcustomer: '',
      qbenchassay: { label: '', value: '' },
      visible: [],
      weeklyVisibleDay: {
          tue: false,
          mon: false,
          sun: false,
          wed: false,
          thu: false,
          fri: false,
          sat: false,
      },
      dayFromWeeklyVisibleNumber: '',
      weekNumber: '',
      tenentTypes: {
          travel: false,
          general: false,
          school: false,
          community: false,
          work: false,
      },
      holidays: [],
      blockOutDays: [],
  },
    
    step: 0,
    insuranceAccess:false,
    getAllUserRoles: '',
    getUserID: '',
  toggle: false,
  cPartner: []
});

export const AdminDispatchContext = createContext<AdminDispatch>({
    updateParams() {},
    setAppointments() {},
    sortTable() {},
    setUser() {},
    updateAppointmentInStore() {},
    reportParams() {},
    setEditAppointment() {},
    updateAdminSlots() {},
    updateLoginError() {},
    updateUserRole() {},
    updateAdminManageAppointment() {},
    updateResultsScreen() {},
    goToPage() {},
    SetID() {},
    updateStandradTestAdmin() {},
    toggleAdminLocationModal() {},
    SetPrintSampleLabel() {},
    SetPrintID() {},
    setMinorAccount() {},
    setMinorEmail() {},
    setLocation() {},
    SetServiceData() {},
    goToNextStep() {},
    goToPrevStep() {},
    goToInitialStep() {},
    updateLocation() {},
    setAccess() {},
    SetAllUserRole() {},
    SetUserID() {},
    setToggleData() {},
    // setReportFilter() {}
});

export const AdminProvider = ({ children }: { children: React.ReactNode }) => {
  const [params, setParams] = useState<any>({
    firstName: '',
    lastName: '',
    confirmationId: '',
    phone: '',
    birthDate: '',
    email: '',
    // date: format(new Date(), config.dateFormat),
    date: '',
    locationQbenchId: null,
    sampleId: null,
    pageNumber:1,
    pageSize:100,
    thirdPartyAppointments:'',
    status:''
  });

  const [reportparams, setreportParams] = useState<ConsolidationParams>({
    appttime: '',
    results: '',
    location: '',
    fromdate: '',
    todate: ''
  });

  const [appointments, setAppointments] = useState<
    AppointmentListItem[] | null
  >(null);

  const [Editappointments, setEditAppointment] = useState<
    Appointment[] | null
  >(null);

  const [locationData, setlocationData] = useState<any>({
    traveltypes: {
        international: false,
        cruises: false,
        events: false,
        domestic: false,
    },
    testtype: {
        standard: false,
        express: false,
        rapid: false,
        antigen: false,
        standardNp: false,
        expressNp: false,
        rapidNp: false,
    },
    weeksofoperation: {
        tue: false,
        mon: false,
        sun: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
    },
    locationname: '',
    cPartnerID: '',
    address1: '',
    address2: '',
    visibleSince: '',
    medicalDirector: '',
    country: { label: '', value: '' },
    state: { label: '', value: '' },
    city: '',
    zipcode: '',
    googlemapshortlink: '',
    sitestatus: { label: '', value: '' },
    delayopenstartdate: '',
    acceptance: { label: '', value: '' },
    slotcapacity: { label: '', value: '' },
    fromhourofoperation: { label: '', value: '' },
    tohourofoperation: { label: '', value: '' },
    qbenchcustomer: '',
    qbenchassay: { label: '', value: '' },
    visible: [],
    weeklyVisibleDay: {
        tue: false,
        mon: false,
        sun: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
    },
    dayFromWeeklyVisibleNumber: '',
    weekNumber: '',
    tenentTypes: {
        travel: false,
        general: false,
        school: false,
        community: false,
        work: false,
    },
    holidays: [],
    blockOutDays: [],
    updatedBy:users?.email ?? '',
    userName:users?.name ?? '',
    updatedFrom:'Location Management - Add Location'
});


  const [sortBy, setSortBy] = useState<keyof AppointmentListItem | null>(null);
  const [sortByDirection, setSortByDirection] = useState<SortByDirection>(
    SortByDirection.Ascending
  );
  const [user, setUser] = useState<User | null>(null);
  const [configured, setConfigured] = useState(false);
  const [adminSlots, setSlots] = useState([]);
  const [insuranceAccess,setInsuranceAccess]=useState(false)
  //Admin Manage Screen changes
  const [currentManagePage, setCurrentManagePage] = useState(AdminManagePage.ManageScreen);
  const [appointmentList, setAppointmentList] = useState<any>(null);
  const [manageAppointment, setAdminManageAppointment] = useState<Appointment | null>(null);
  const [apptPricesAdmin, setPrices] = useState({
    standard: 0,
    expedited: 0,
    rapid: 0,
    standard_INT: 0,
    expedited_INT: 0,
    rapid_INT: 0,
    antigen:0,
    antigen_INT:0
  });
  const [showStandradTestAdmin, setShowStandradTestAdmin] = useState(false);
  const [showAdminLocationModal, setshowAdminLocationModal] = useState(false);
  const [getAllUserRoles, setAllUserRoles] = useState([]);
  const [getUserID, setUserRoleID] = useState(null);
   const [getPrintSampleLabels, setPrintSampleLabels] = useState([])
    const [getPrintID, setPrintSampleID] = useState(null);
  // const [appointments, setReportFilter] = useState<ConsolidationParams[] | null>(null);
  const [GetServiceData, setServiceData] = useState([]);

  const [editLocation, setEditLocation] = useState(null);
  const [step, setStep] = useState(0);
  const [toggle,setToggle]=useState(false)

  const [isLoginError, setLoginError] = useState(false);
  const [getID, setClientPartnerID] = useState(null);
  const [userRole, setUserRole] = useState("");
  const [minorEmail, setminorEmail] = useState<string>('')
  const [IsMinorAccount, setIsMinorAccount] = useState<boolean>(false);
  let [cPartner, setCpartner] = useState([])
  useEffect(() => {
    const clientPartner = async () => {
      let cPartnerData: any = []
      let data: any = await getAllClientPartner();
      let cPartner = data.filter((e: any) => e.name !== '' && e.name !== undefined).map((e: any) => {
        cPartnerData.push({ value: e.cPartnerID, label: e.name })
        return e
      });
      // filtering the Data to Unique
      let uniqueData: any = cPartnerData.filter((ele: any, index: any) => cPartnerData.findIndex((obj: any) => obj.value === ele.value) === index)
      // HardCoding the Worksite Labs - General Label to Worksite Labs - General & Travel

      let hardCode: any = uniqueData.map((e: any) => e.value === 'WSL001' ? { ...e, label: 'Worksite Labs - General & Travel' } : e).sort((a: any, b: any) => {
        let fa = a.label.toLowerCase(),
          fb = b.label.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        else if (fa > fb) {
          return 1
        } else {
          return 0;
        }
      });
      setCpartner(hardCode);

    }
    clientPartner();
  }, [])
  useEffect(() => {
    const unsubscribe = firebase
      .auth()
      .onAuthStateChanged(async (firebaseUser) => {
        if (firebaseUser) {
          setUser(firebaseUser);
        }

        setConfigured(true);
      });

    getPrices().then((result) => {
        setPrices(SetPrices(apptPricesAdmin,result));
    });

    // getSlotsConfigurations().then((result) => {
    //   setSlots(result.data);
    // })
    

    return () => unsubscribe();
  }, [setUser]);

  const updateParams = useCallback(
    (update: any) => {
      setParams((currentParams: any) => ({
        ...(currentParams || {}),
        ...update,
      }));
    },
    [setParams]
  );

  const setAccess = useCallback(
    (data: any) => {
        setInsuranceAccess(data);
    },
    [setInsuranceAccess]
);

  const reportParams = useCallback(
    (update: ConsolidationParams) => {
      setreportParams((currentParams) => ({
        ...(currentParams || {}),
        ...update,
      }));
    },
    [setreportParams]
  );

  //Admin Manage screen handuler
  const updateResultsScreen = useCallback(
    (appointmentList: any) => setAppointmentList(appointmentList),
    [setAppointmentList]
  );

  const goToPage = useCallback(
    (newPage: AdminManagePage) => {
      setCurrentManagePage(newPage);
    },
    [setCurrentManagePage]
  );

  const updateAdminManageAppointment = useCallback(
    (update: any) => {
      setAdminManageAppointment({
        ...manageAppointment,
        ...update
      });
    },
    [setAdminManageAppointment]
  );

  const sortTable = (columnName: keyof AppointmentListItem) => {
    let direction: SortByDirection = SortByDirection.Ascending;

    if (sortBy === columnName) {
      direction =
        sortByDirection === SortByDirection.Ascending
          ? SortByDirection.Descending
          : SortByDirection.Ascending;
    }

    setSortBy(columnName);
    setSortByDirection(direction);
  };

  const sortAppointments = (
    appointments: AppointmentListItem[] | null,
    sortBy: keyof AppointmentListItem | null,
    sortByDirection: SortByDirection
  ) => {
    if (sortBy !== null && appointments !== null && appointments.length > 0) {
      const compare = comparables[sortBy] || { type: FieldType.Text };

      appointments?.sort((a, b) => {
        if (compare.type !== FieldType.Date) {
          let aval: string = String(a[sortBy]).toUpperCase();
          let bval: string = String(b[sortBy]).toUpperCase();
          if (aval < bval) {
            return sortByDirection === SortByDirection.Ascending ? -1 : 1;
          }
          if (aval > bval) {
            return sortByDirection === SortByDirection.Ascending ? 1 : -1;
          }
          return 0;
        } else {
          const aDate = parse(
            a[sortBy] as string,
            compare?.data?.dateFormat,
            new Date()
          );
          const bDate = parse(
            b[sortBy] as string,
            compare?.data?.dateFormat,
            new Date()
          );
          return sortByDirection === SortByDirection.Ascending
            ? compareAsc(aDate, bDate)
            : compareDesc(aDate, bDate);
        }
      });
    }
    return appointments;
  };

  const updateAppointmentInStore = (
    firebaseId: string,
    minorIndex: number | null,
    update: Partial<AppointmentListItem>
  ) => {
    setAppointments((appts) => {
      if (appts === null) {
        return null;
      }

      const index = appts.findIndex(
        (appt) => appt.id === firebaseId && appt.minorIndex === minorIndex
      );
      appts[index] = {
        ...appts[index],
        ...update,
      };

      return appts;
    });
  };

  const updateStandradTestAdmin = useCallback(
    (show: any) => {
      setShowStandradTestAdmin(show);
    },
    [setShowStandradTestAdmin]
  );

const toggleAdminLocationModal = useCallback(
    (show: boolean) => {
      setshowAdminLocationModal(show);
    },
    [setshowAdminLocationModal]
  );

  const updateAdminSlots = useCallback(
    (update: any) => {
      setSlots(update)
    },
    [setSlots]
  );

  const updateUserRole = useCallback(
    (update: any) => {
      setUserRole(update)
    },
    [setUserRole]
  );

  const updateLoginError = useCallback(
    (show: boolean) => {
      setLoginError(show);
    },
    [setLoginError]
  );

  const SetID = useCallback(
    (data: any) => {
        setClientPartnerID(data);
    },
    [setClientPartnerID]
);

  const SetPrintSampleLabel = useCallback(
    (data: any) => {
        setPrintSampleLabels(data);
    },
    [setPrintSampleLabels],
)
const SetPrintID = useCallback(
    (data: any) => {
        setPrintSampleID(data);
    },
    [setPrintSampleID]
)
const SetAllUserRole = useCallback(
    (data: any) => {
        setAllUserRoles(data);
    },
    [setAllUserRoles]
);
const SetUserID = useCallback(
    (data: any) => {
        setUserRoleID(data);
    },
    [setUserRoleID]
);

const setLocation = useCallback(
    (data: any) => {
        setEditLocation(data);
    },
    [setEditLocation]
);

const setToggleData = useCallback(
    (data: any) => {
        setToggle(data);
    },
    [setToggle]
);
const SetServiceData = useCallback(
    (data: any) => {
        setServiceData(data);
    },
    [setServiceData]
);

const updateLocation = (data: any, reset: boolean = false) => {
  if (!reset) {
      let newdata = { ...locationData, ...data };
      // console.log('admin provider line 419', newdata);
      setlocationData(newdata);
  } else {
      setlocationData({
          traveltypes: {
              international: false,
              cruises: false,
              events: false,
              domestic: false,
          },
          testtype: {
              standard: false,
              express: false,
              rapid: false,
              antigen: false,
              standardNp: false,
              expressNp: false,
              rapidNp: false,
          },
          weeksofoperation: {
              tue: false,
              mon: false,
              sun: false,
              wed: false,
              thu: false,
              fri: false,
              sat: false,
          },
          locationname: '',
          cPartnerID: '',
          address1: '',
          address2: '',
          visibleSince: '',
          medicalDirector: '',
          country: { label: '', value: '' },
          state: { label: '', value: '' },
          city: '',
          zipcode: '',
          googlemapshortlink: '',
          sitestatus: { label: '', value: '' },
          delayopenstartdate: '',
          acceptance: { label: '', value: '' },
          slotcapacity: { label: '', value: '' },
          fromhourofoperation: { label: '', value: '' },
          tohourofoperation: { label: '', value: '' },
          qbenchcustomer: '',
          qbenchassay: { label: '', value: '' },
          visible: [],
          weeklyVisibleDay: {
              tue: false,
              mon: false,
              sun: false,
              wed: false,
              thu: false,
              fri: false,
              sat: false,
          },
          dayFromWeeklyVisibleNumber: '',
          weekNumber: '',
          tenentTypes: {
              travel: false,
              general: false,
              school: false,
              community: false,
              work: false,
          },
          holidays: [],
          blockOutDays: [],
      });
  }
};

const goToNextStep = useCallback(() => {
    setStep((s: number) => {
        if (s + 1 <= steps.length - 1) {
            return s + 1;
        }

        return s;
    });
}, [setStep]);

const goToPrevStep = useCallback(() => {
    setStep((s: number) => {
        if (s - 1 >= 0) {
            return s - 1;
        }

        return s;
    });
}, [setStep]);

const goToInitialStep = useCallback(() => {
  setStep((s: number) => {
      return 0;
  });
}, [setStep]);

  const setMinorAccount = useCallback((data:boolean)=>{
    setIsMinorAccount(data);
  },[])
  const setMinorEmail = useCallback((data:string)=>{
    setminorEmail(data);
  },[])
  const store = useMemo(
    () => ({
      params,
      appointments: sortAppointments(appointments, sortBy, sortByDirection),
      sortBy,
      sortByDirection,
      user,
      configured,
      Editappointments,
      adminSlots,
      isLoginError,
      getID,
      userRole,
      currentManagePage,
      appointmentList,
      manageAppointment,
      apptPricesAdmin,
      showAdminLocationModal,
      showStandradTestAdmin,
      getPrintSampleLabels,
      getPrintID,
      IsMinorAccount,
      minorEmail,
      editLocation,
      GetServiceData,
      locationData,
      step,
      insuranceAccess,
      getAllUserRoles,
        getUserID,
      toggle,
      cPartner
    }),
    [params, appointments, getAllUserRoles, getUserID, getPrintSampleLabels, getPrintID, sortBy, sortByDirection, user, configured, Editappointments, adminSlots, isLoginError, getID, currentManagePage, appointmentList, manageAppointment, apptPricesAdmin, showAdminLocationModal, showStandradTestAdmin, IsMinorAccount, minorEmail, GetServiceData, locationData, step, insuranceAccess, toggle, cPartner]
  );

  const dispatch = useMemo(
    () => ({
      updateParams,
      setAppointments,
      sortTable,
      setUser,
      updateAppointmentInStore,
      reportParams,
      setEditAppointment,
      updateAdminSlots,
      updateLoginError,
      SetID,
      updateUserRole,
      updateResultsScreen,
      updateAdminManageAppointment,
      goToPage,
      updateStandradTestAdmin,
      toggleAdminLocationModal,
      SetPrintSampleLabel,
      SetPrintID,
      setMinorAccount,
      setMinorEmail,
      setLocation,
      SetServiceData,
      updateLocation,
      goToNextStep,
      goToPrevStep,
      goToInitialStep,
      setAccess,
      SetAllUserRole,
      SetUserID,
      setToggleData
    }),
    [sortBy, sortByDirection,SetAllUserRole,SetPrintSampleLabel,SetPrintID,SetID,updateLocation,goToInitialStep, goToNextStep, goToPrevStep, updateAdminSlots, setAccess,setToggleData] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <AdminContext.Provider value={store}>
      <AdminDispatchContext.Provider value={dispatch}>
        {children}
      </AdminDispatchContext.Provider>
    </AdminContext.Provider>
  );
};

export const useAdminState = () => {
  const context = React.useContext(AdminContext);

  if (typeof context === 'undefined') {
    throw new Error(
      '`useAdminState` hook must be used within a `Provider` component'
    );
  }

  return context;
};

export const useAdminDispatch = () => {
  const context = React.useContext(AdminDispatchContext);

  if (typeof context === 'undefined') {
    throw new Error(
      '`useAdminDispatch` hook must be used within a `Provider` component'
    );
  }

  return context;
};

const comparables: Comparables = {
  birthDate: {
    type: FieldType.Date,
    data: {
      dateFormat: config.dateFormat,
    },
  },
  departureDateAndTime: {
    type: FieldType.Date,
    data: {
      dateFormat: config.dateTimeFormat,
    },
  },
};

export const useMediaQuery = (query: string) => {
	const mediaMatch = window.matchMedia(query);
	const [matches, setMatches] = useState(mediaMatch.matches);
  
	useEffect(() => {
	  const handler = (e: any) => setMatches(e.matches);
	  mediaMatch.addEventListener('change', handler);
	  return () => mediaMatch.removeEventListener('change', handler);
	});
	return matches;
  };
  