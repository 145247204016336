import React, {
  useEffect,
  useCallback,
  useState,
  createContext,
  useMemo,
} from 'react';

import { SharedState, SharedDispatch, Location } from './models';
import { getAllOpenedLocations, getOpenedLocations } from './api';
import { firebase } from '../../firebase';
import { newLocationFormItems } from '../admin/page/SchedularLite/schedularUtils';

export const SharedContext = createContext<SharedState>({
  locations: null,
  standradTest: false,
  newLocationForm:newLocationFormItems.reduce((a:any,b:any)=>({...a,[b.name]:b.type==='text'?'':b.type==='number'?null:b.type==='select'?'':''}),{}),
  allLocations:null,
  daywiseUsers:null
});

export const SharedDispatchContext = createContext<SharedDispatch>({
    getLocations() {},
    upateTestType() {},
    setLocation() {},
    setFormData() {},
    updateBlockOutDays() {},
    updateSharedSlots() {},
    setDaywiseUsers() {},
});

export const SharedProvider = ({ children }: { children: React.ReactNode }) => {
  const [locations, setLocations] = useState<Location[] | null>(null);
  const [standradTest, setTestType] = useState<boolean>(false);
  const [newLocationForm, setNewLocationForm] = useState<any>(newLocationFormItems.reduce((a:any,b:any)=>({...a,[b.name]:b.type==='text'?'':b.type==='number'?null:b.type==='select'?'':''}),{}));
  const [allLocations, setAllLocations] = useState<any[] | null>(null);
   const [blockOutDays, setBlockOutDays] = useState<Number[]>([]);
    const [sharedSlots, setSlots] = useState<any>([]);
  const [daywiseUsers, setDaywiseUser] = useState<any[] | null>(null);

  const getLocations = useCallback(async () => {
    try {
      const locations = await getOpenedLocations();
      setLocations(locations);
    } catch (e) {
      console.error(e);
    }
  }, []);
  const getAllLocations = useCallback(async () => {
    try {
      const locations = await getAllOpenedLocations();
      setAllLocations(locations);
    } catch (e) {
      console.error(e);
    }
  }, []);
  useEffect(() => {
    firebase.auth().signInAnonymously();
    getAllLocations();
    const unsubscribe = firebase
      .auth()
      .onAuthStateChanged(async (firebaseUser) => {
        if (firebaseUser) {
          getLocations();
        }
      });

    return () => unsubscribe();
  }, [getLocations]);

  const store = useMemo(
    () => ({
      locations,
      standradTest,
      allLocations,
      newLocationForm,
      daywiseUsers
    }),
    [locations, standradTest, allLocations, newLocationForm, daywiseUsers]
  );

  const upateTestType = useCallback(
    (update: any) => {
      setTestType(update)
    },
    [setTestType]
  );
    const updateSharedSlots = useCallback(
        (update: any) => {
            setSlots(update);
        },
        [setSlots]
    );
    const updateBlockOutDays = useCallback(
        (data: Number[]) => {
            setBlockOutDays(data);
        },
        [setBlockOutDays]
    );
  const setLocation = useCallback(
    (data: any) => {
      setAllLocations(data)
    },
    [setAllLocations]
  );
  const setFormData = useCallback(
    (data: any) => {
        setNewLocationForm((state:any)=>({ ...state ?? {} , ...data ?? {}}))
    },
    [setNewLocationForm]
    );
    const setDaywiseUsers = useCallback(
        (data: any) => {
            setDaywiseUser((state:any)=>([  ...data ?? {}]))
        },
        [setDaywiseUser]
    );
  const dispatch = useMemo(
      () => ({
          getLocations,
          upateTestType,
          setLocation,
          getAllLocations,
          setFormData,
          updateBlockOutDays,
          updateSharedSlots,
          setDaywiseUsers,
      }),
      [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <SharedContext.Provider value={store}>
      <SharedDispatchContext.Provider value={dispatch}>
        {children}
      </SharedDispatchContext.Provider>
    </SharedContext.Provider>
  );
};

export const useSharedState = () => {
  const context = React.useContext(SharedContext);

  if (typeof context === 'undefined') {
    throw new Error(
      '`useSharedState` hook must be used within a `SharedProvider` component'
    );
  }

  return context;
};

export const useSharedDispatch = () => {
  const context = React.useContext(SharedDispatchContext);

  if (typeof context === 'undefined') {
    throw new Error(
      '`useSharedDispatch` hook must be used within a `SharedProvider` component'
    );
  }

  return context;
};
