import React, { useEffect } from 'react';
import styled from 'styled-components';

import {
    Button,
    Container,
    Content,
    PageHeader,
    PageTitle,
} from '../../../../shared/components/styled';
import { Breakpoints } from '../../../../../dictionaries';
import { useAdminDispatch } from '../../../provider';
import { AdminManagePage } from "../dictionaries";
const Text = styled.p`
  margin-bottom: 45px;
`;

const CancelBtn = styled(Button)`
  width: 100%;

  @media (min-width: ${Breakpoints.sm}px) {
    width: 450px;
  }
`;

const ManageRescheduleConfirm: React.FC = () => {

    useEffect(() => {
		const content = document.getElementsByClassName('makeStyles-mainPanel1-3');
    	if(content && content.length) {
        	content[0].scrollTop = 0;
    	}
		const content1 = document.getElementsByClassName('jss3');
    	if(content1 && content1.length) {
        	content1[0].scrollTop = 0;
    	}

	  }, []);
      
    const { goToPage } = useAdminDispatch();

    return (
        <Container size="md">
            <Content>
                <PageHeader>
                    <PageTitle>Your appointment has been rescheduled.</PageTitle>
                </PageHeader>
                <CancelBtn
                    type="button"
                    libraryType="primary"
                    onClick={() => {
                        goToPage(AdminManagePage.ManageScreen)
                    }}
                >
                    Go Back
                </CancelBtn>
            </Content>
        </Container>
    );
};

export default ManageRescheduleConfirm;
