import React, { useEffect, useState } from 'react';

import { Wrapper } from './styled';
import { Location } from '../models';

import Button from "@material-ui/core/Button";
import {
  Col,
  Form,
  Row,
  Select,
} from 'antd';

import CardHeader from "../../admin/components/AppointmentList/Card/CardHeader";
import CardBody from "../../admin/components/AppointmentList/Card/CardBody";
import Card from "../../admin/components/AppointmentList/Card/Card.js";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  getAllOpenedLocations, getApptReport,
  generateApptReport
} from '../api';
import Loader from '../components/Loader';
import { Box, Snackbar, Typography } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import AntdRangePicker from './AntDDatePicker/AntdRangePicker';
import { Moment } from 'moment';
import { addDays, format, isBefore } from 'date-fns';
import { useSharedState } from '../provider';
const { Option } = Select;



function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative"
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typo: {
      paddingLeft: "35%",
      marginBottom: "40px",
      position: "relative"
    },
    note: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      bottom: "10px",
      color: "#c0c1c2",
      display: "block",
      fontSize: "13px",
      lineHeight: "13px",
      left: "0",
      marginLeft: "20px",
      position: "absolute",
      width: "260px"
    },
    cardCategoryWhite: {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none"
    }
  }),
);

// const useStyles = makeStyles(styles);

const Report: React.FC = () => {
  const classes = useStyles();
  const { allLocations }:any = useSharedState();
  const [locations, setLocations] = useState<Location[] | null>(null);
  const [formData, setFormData] = React.useState<any>({
    date: '',
    locationQbenchId: [],
  });
  const [alertbox, setalertbox] = React.useState({
    open: false,
    type: '',
    msg: '',
  });
  const [loader, setloader] = React.useState(false);
  const [empty, setEmpty] = React.useState(false);
  const [result, setResult] = React.useState<any[]>([]);
  const [errorDates, setErrorDates] = useState<any[]>([])

  const AllUniqLocation = allLocations?.filter(
    (v: any, i: any, a: any) =>
      a.findIndex(
        (v2: any) => v2.qbenchCustomerId === v.qbenchCustomerId
      ) === i
  );
  type RangeValue = [Moment | null, Moment | null] | null;
  const [dates, setDates] = React.useState<RangeValue>(null);

  useEffect(() => {
    getAllOpenedLocations().then((loc) => {
        setLocations(loc.filter((e:any)=>e?.isOpened !== false || e?.isOpened === false));
      });
  }, []);

  if (!locations) {
    return <Loader />;
  }

  const genarateFile = async (
    count: number,
    finalData: any[],
    arr: any[],
  ) => {
    if (count >= arr.length) {
      return finalData;
    }
    await getApptReport({ ...formData, date: arr[count] })
      .then(async (response: any) => {
        finalData.push(...response.data.appointments);
        await genarateFile(count + 1, finalData, arr);
      })
      .catch(async (err) => {
        setloader(false);
        setalertbox({
          open: true,
          type: 'error',
          msg: 'Something went wrong while searching ' + arr[count] + " data.",
        });
        let errdate: any[] = errorDates;
        errdate.push(arr[count]);
        setErrorDates(errdate);
      });
    setloader(false)
    if (finalData.length > 0) {
      setEmpty(false)
    } else {
      setEmpty(true)
    }
    return finalData
  }

  const handleSearch = async () => {
    if (!formData.locationQbenchId.length || !formData.date) {
      setalertbox({
        open: true,
        type: 'warning',
        msg: 'All Fields are required*',
      });
      return false;
    }
    try {
      setloader(true);
      let date = new Date(formData.date.split(', ')[0]);
      let arr: Array<any> = [];
      arr.push(format(new Date(date), 'MM/dd/yyyy'));
      while (
        isBefore(new Date(date), new Date(formData.date.split(', ')[1]))
      ) {
        date = new Date(addDays(date, 1));
        arr.push(format(new Date(date), 'MM/dd/yyyy'));
      }
      let finalData : any = await genarateFile(0, [], arr);
      setResult(finalData)
      if (errorDates.length) {
        setloader(false);
        return false;
      }else{
        if (finalData.length > 0) {
          setEmpty(false)
          generateApptReport(finalData)
          return true
        } else {
          setEmpty(true)
          return false;
        }
      }
    } catch (err: any) {
      setloader(false);
      setResult([])
      setalertbox({
        open: true,
        type: 'error',
        msg: err?.message || 'Something went Wrong',
      });
    }
  }

  return (
    <>
      <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={alertbox.open}
          autoHideDuration={3000}
          onClose={() => setalertbox({ open: false, type: '', msg: '' })}
        >
          <Alert
            icon={false}
            onClose={() =>
              setalertbox({ open: false, type: '', msg: '' })
            }
            severity={alertbox.type}
          >
            {alertbox.msg}
          </Alert>
        </Snackbar>
        <Row  
        style={{ marginLeft: 15 }}
        gutter={{
          xs: 5,
          sm: 5,
          md: 15,
          lg: 15,
          xl: 20,
          xxl: 20,
            }}>
          <Col   
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 8 }}
          lg={{ span: 6 }}
          >
            <Form layout="vertical">
              <Form.Item label="Date Range" name="todate">
                <AntdRangePicker
                  dates={dates}
                  setDates={setDates}
                  onChange={(value: any) =>
                    setFormData({ ...formData, date: value })
                  }
                  disabled={loader}
                  range={30}
                  allowClear
                />
              </Form.Item>
            </Form>
          </Col>
          <Col 
           xs={{ span: 24 }}
           sm={{ span: 24 }}
           md={{ span: 8 }}
           lg={{ span: 8 }}
          >
            <Form layout="vertical">
              <Form.Item label="Location" name="location">
                <Select
                  loading={loader}
                  allowClear
                  placeholder="Please select"
                  showArrow
                  aria-required
                  showSearch
                  disabled={loader}
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                  onChange={(e) => {
                    if (e) {
                      setFormData({
                        ...formData,
                        locationQbenchId: [e],
                      })
                    } else {
                      setFormData({
                        ...formData,
                        locationQbenchId: [],
                      })
                    }
                  }
                  }
                >
                  {AllUniqLocation?.map((location: any) => (
                    <Option
                      key={location.qbenchCustomerId}
                      value={location.qbenchCustomerId}
                    >
                      {location.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </Col>
          <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
          >
            <Button
              onClick={handleSearch}
              disabled={loader}
              style={{
                backgroundColor: 'rgb(0, 172, 193)',
                color: 'white',
                margin: '30px 0 0 20px',
              }}
            >
              Generate Report
            </Button>
          </Col>
        </Row>
        <Card>
          <CardHeader color="primary">
            <h2 className={classes.cardTitleWhite}>CONSOLIDATION REPORT</h2>
          </CardHeader>
          <CardBody>
            {loader ? (
              <Loader />
            ) : (
              <>
              {empty ? (
                  result.length ? (
                      <>
                          <Box
                              alignContent="center"
                              alignItems="center"
                              textAlign="center"
                          >
                          </Box>
                      </>
                  ) : (
                      <>
                          <Typography
                              variant="h5"
                              align="center"
                              color="initial"
                          >
                              No Data Found.
                          </Typography>
                      </>
                  )
              ) : (
                  <></>
              )}
          </>
            )}
          </CardBody>
        </Card>
    </>
  );
};



export default Report;
