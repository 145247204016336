import { firebase } from '../../firebase';
import {
  AppointmentListItem,
  AppointmentsListParams,
  ConsolidationParams,
  Appointment,
} from './models';
import xlsx from 'node-xlsx';
import { config } from '../../config';
import _ from "lodash";
import { parse, format } from 'date-fns';
import axios from 'axios';
// import qbench from '../';

export const signIn = async (email: string, password: string) => {
  firebase.auth().signInWithEmailAndPassword(email, password);
  window.location.href = '/dashboard';
};

export const signOut = async () => firebase.auth().signOut();

export const getPaymentIntentId = async (params: any) =>
    firebase.functions().httpsCallable('getPaymentIntentId')(params);

export const userCheckin = async (params: any) =>
  firebase
    .functions()
    .httpsCallable('userCheckin', { timeout: 540 * 1000 })(params);
export const getAppointmentsByDate = async (params: AppointmentsListParams) =>
  firebase
    .functions()
    .httpsCallable('getAppointmentsByDate', { timeout: 540 * 1000 })(params);

export const getAppointmentsByconfirmationId = async (
  params: AppointmentsListParams
) =>
  firebase
    .functions()
    .httpsCallable('getAppointmentsByconfirmationId', { timeout: 540 * 1000 })(
    params
  );
  

export const getfilterwithrecords = async (params: ConsolidationParams) =>
  firebase
    .functions()
    .httpsCallable('getfilterwithrecords', { timeout: 540 * 1000 })(params);

export const getAllAppointmentSlottime = async () =>
  firebase.functions().httpsCallable('getAllAppointmentSlottime')();

  export const slotoccupany = async (params: any) =>
  firebase
    .functions()
    .httpsCallable('getslotcapacity', { timeout: 540 * 1000 })(params);

export const sendConfirmationEmail = async (emails: string[]) =>
  firebase.functions().httpsCallable('sendConfirmationEmail')(emails);

export const downloadResultsPDF = async (sampleId: string) => {
  var windowReference: any = window.open();
  const { data } = await firebase
    .functions()
    .httpsCallable('getResultsPDFDownloadLink')(sampleId);

  if (data?.url) {
    // window.open(data?.url, '_blank');
    // let a: any = await document.createElement("a");
    // document.body.appendChild(a);
    // a.href = `${data?.url}`;
    // a.download = 'report';
    // a.click();
    // document.body.removeChild(a);
    windowReference.location = data?.url;
  }
};
export const InsuranceData = async (params: any) =>
    firebase.functions().httpsCallable('InsuranceData')(params);

export const StripeData = async (params: any) =>
    firebase.functions().httpsCallable('StripeData')(params);

 export const NoInsuranceData = async (params: any) =>
 firebase.functions().httpsCallable('NoInsuranceData')(params);

export const updateAppointment = async ({
  firebaseId,
  qbenchId,
  sampleId,
  update,
  minorIndex,
}: {
  firebaseId: string;
  qbenchId: string;
  sampleId: string;
  minorIndex: number | null;
  update: Partial<AppointmentListItem>;
}) =>
  firebase.functions().httpsCallable('updateAppointment')({
    firebaseId,
    sampleId,
    qbenchId,
    update,
    minorIndex,
  });

export const getQbenchResults = async () =>
  firebase.functions().httpsCallable('getQbenchResults')();

export const getApptConsolidationDetails = async () =>
  firebase.functions().httpsCallable('getApptConsolidationDetails')();

export const getApptbasedonLocation = async () =>
  firebase.functions().httpsCallable('getApptbasedonLocation')();

export const getApptbasedonGender = async () =>
  firebase.functions().httpsCallable('getApptbasedonGender')();

export const getApptbasedonresults = async () =>
  firebase.functions().httpsCallable('getApptbasedonresults')();
  
  export const reportAccess = async () =>
  firebase.functions().httpsCallable('reportAccess')();

export const getCountry = async () =>
    firebase
        .functions()
        .httpsCallable('getCountry')();
        
export const addNpDestionation = async (params: any) =>
    firebase
        .functions()
        .httpsCallable('addNpDestionation')(params);

export const getNpDestionation = async () =>
    firebase
        .functions()
        .httpsCallable('getNpDestionation')();

// export const getCountyReport = async (runDate?: any) =>
// firebase.functions().httpsCallable('getCountyReport')({"runDate": runDate});

export const getAllRolesAccessApi = async (params:any) => {
  // params = { roleName: params }
  return firebase
    .functions()
    .httpsCallable('getAccessFieldsByRole')(params)
}
export const createUserRolesDataApi = async (params:any) => {
  return firebase
    .functions()
    .httpsCallable('createUserRolesData')(params)
}
export const getAllAccessRolesDataApi = async () => {
  return firebase
    .functions()
    .httpsCallable('getAllAccessRolesData')()
}
export const updateUserRolesDataApi = async (params:any) => {
  return firebase
    .functions()
    .httpsCallable('updateUserRolesData')(params)
}
export const getRoleByEmailApi = async (data: any) => {
  return firebase
     .functions()
     .httpsCallable('getRoleByEmail')(data)
 }
export const getAllUserRolesDataApi = async () => {
  return firebase
    .functions()
    .httpsCallable('getAllUserRolesData')()
}
export const deleteUserRolesDataApi = async (params: any) => {
  return firebase
    .functions()
    .httpsCallable('deleteUserRolesData')(params)
}
export const deleteRoleApi = async (params:any) => {
  return firebase
    .functions()
    .httpsCallable('deleteRole')(params)
}
export const getCountyReport = (runDate?: any) =>
Promise.all(
  [
    firebase.firestore()
    .collection("qbenchconsolidationresults")
    .where('tests.complete_date_formated', '==', runDate)
    .get(), 
    
    firebase
    .firestore()
    .collection("thirdpartyqbenchconsolidationresults")
    .where('tests.complete_date_formated', '==', runDate)
    .get()
  ]
).then(res=>{  
    const consolidationdetailsFromQbench: any = res[0].docs.map(
      (doc: any) => doc.data()
    );
    const consolidationdetailsFromThirdParty: any = res[1].docs.map(
      (doc: any) => doc.data()
    );

    const consolidationdetailsFromPrimary:any=consolidationdetailsFromThirdParty.filter((record:any)=>record.isPrimary)
    const consolidationdetails: any = [...consolidationdetailsFromQbench,...consolidationdetailsFromPrimary]

    let output:any={}
    output['Allconsolidationdetails']=consolidationdetails
    output['Positiveconsolidationdetails']=consolidationdetails.filter((record:any)=>record.tests.results == 'DETECTED')
    output['Negativeconsolidationdetails']=consolidationdetails.filter((record:any)=>record.tests.results == 'NOT DETECTED')

    return output;
})

export const generatecountyReport = async (appointments: any) => {
  const appointmentData: any[] = [];
    if (appointments?.length){
        appointments.map((e: any) => {            
            if (e.isPanel) {
                    e.testDetils.map((ee: any) => {
                        appointmentData.push({
                            ...e,
                            testId: ee?.id ?? '',
                            testName: ee?.name ?? '',
                            
                        });
                    });                
            } 
             else {
                appointmentData.push({...e, testId: e.tests.id, testName: '' })               
            }
        });
    }
  const data = [
    [
      'FirstName',
      'LastName',
      'PatientIdentifier',
      'DOB',
      'Gender',
      'Race',
      'Ethnicity',
      'Patient_Street_Address',
      'Apartment_Number',
      'City',
      'State',
      'Zipcode',
      'PatientPhoneNumber',
      'Patient Email',
      'OrderingFacility',
      'Referring Physician',
      'Physician address',
      'Physician City',
      'Physician State',
      'Physician Zipcode',
      'Physician phone number',
      'Accession #',
      'Specimen Collection Date',
      'Specimen Received Date',
      'Specimen Type',
      'Test reported date',
      'Test Code_LOINC',
      'Assay Name',
      'Result',
      'PerformingFacility',
      'CLIA',
      'Pregnancy Status',
      'County Name',
      'Sample ID',
      'Test Name',
      'Test ID'
    ],
    ...appointmentData.reduce((acc: any[], appt: any) => {
      const a = acc;

      const row = [
        `${
          appt.samples !== undefined ? appt.samples['X_PATIENT_FIRST_NAME'] : ''
        }`,
        `${
          appt.samples !== undefined ? appt.samples['X_PATIENT_LAST_NAME'] : ''
        }`,
        '',
        `${appt.samples !== undefined ? appt.samples['X_PATIENT_DOB'] : ''}`,
        appt.sex,
        appt.race,
        appt.ethnicity,
        `${appt.address !== undefined ? appt.address['address'] : ''}`,

        `${appt.address !== undefined ? appt.address['address'] : ''}`,
        `${appt.address !== undefined ? appt.address['city'] : ''}`,
        `${appt.address !== undefined ? appt.address['state'] : ''}`,
        `${appt.address !== undefined ? appt.address['zipCode'] : ''}`,
        appt.phone,
        appt.email,
        'Work Site Lab',
        `${
          appt.location !== null && appt.location.medicalDirector !== undefined
            ? appt.location.medicalDirector
            : 'NA'
        }`,
        'NA',
        'NA',
        'NA',
        'NA',
        'NA',
        `${
          appt.samples !== undefined
            ? appt.samples['X_PATIENT_EXTERNAL_ID']
            : ''
        }`,
        `${appt.date}`,
        `${
          appt.tests !== undefined ? appt.tests['complete_date_formated'] : ''
        }`,
        `${
          appt.samples !== undefined &&
          appt.samples !== null &&
          appt.samples.accessioning_type !== undefined &&
          appt.samples.accessioning_type !== null &&
          appt.samples.accessioning_type['value'] !== null &&
          appt.samples.accessioning_type['value'] !== undefined
            ? appt.samples.accessioning_type['value']
            : ''
        }`,
        `${
          appt.samples !== undefined &&
          appt.samples['time_of_collection'] !== undefined &&
          appt.samples['time_of_collection'] !== null &&
          appt.samples['time_of_collection'] !== ''
            ? format(
                new Date(appt.samples['time_of_collection']),
                config.dateFormat
              )
            : ''
        }`,
        `${
          appt.tests !== undefined &&
          appt.tests !== null &&
          appt.tests.assay !== undefined &&
          appt.tests.assay !== null &&
          appt.tests.assay['title'] !== undefined &&
          appt.tests.assay['title'] !== null
            ? appt.tests.assay['title'] ==
              'Atila iAMP NAAT RT-PCR COVID-19 Detection'
              ? '94500-6'
              : appt.tests.assay['title'] ==
                'Quidel Lyra NAAT RT-PCR COVID-19 Detection'
              ? '94559-2'
              : appt.tests.assay['title'] == 
                'Biorad DD PCR'
              ? '94533-7'
              : appt.tests.assay['title'] ==
                'LumiraDx SARS-CoV-2 RNA STAR NAAT RT-PCR Detection'
              ? '94559-2'
              : appt.tests.assay['title'] ==
                'SELGO COVID-19 SARS-Cov-2 by NAAT RT-PCR Detection'
              ? '94500-6'
              : appt.tests.assay['title'] ==
                'Accula NAAT SARS-CoV-2 (COVID-19) Rapid RT-PCR Testing'
              ? '95409-9'
              : appt.tests.assay['title'] ==
                'Cepheid Xpert® Xpress SARS-CoV-2 NAAT RT-PCR'
              ? '94500-6'
              : appt.tests.assay['title'] ==
                'Abbott BinaxNOW COVID-19 Ag CARD'
              ? '94558-4'
              : appt.tests.assay['title'] ==
                'INDICAID COVID-19 Antigen Test'
              ? '94558-4'
              : appt.tests.assay['title'] ==
              'Qiagen Quiamp RNA NAAT RT PCR Qual Assay'
              ? '95209-3'
              : appt.tests.assay['title'] ==
              'Status™ COVID-19/Flu A&B'
              ? '95942-9'
              :appt.tests.assay['title'] ==
              'Celltrion DiaTrustTM COVID-19 Ag Rapid Antigen Test'?
              '95209-3'
              : ''
            : ''
        }`,
        `${
          appt.tests !== undefined &&
          appt.tests !== null &&
          appt.tests.assay !== undefined &&
          appt.tests.assay !== null &&
          appt.tests.assay['title'] !== undefined &&
          appt.tests.assay['title'] !== null
            ? appt.tests.assay['title']
            : ''
        }`,
        `${
          appt.tests !== undefined && appt.tests !== null
            ? appt.tests['results']
            : ''
        }`,
        `${
          appt.location !== undefined &&
          appt.location !== null &&
          appt.location.performingfacility !== undefined &&
          appt.location.performingfacility !== null
            ? appt.location.performingfacility
            : ''
        }`,
        `${
          appt.location !== undefined &&
          appt.location !== null &&
          appt.location.CLIA !== undefined &&
          appt.location.CLIA !== null
            ? appt.location.CLIA
            : ''
        }`,
        'NA',
        `${
          appt.address !== undefined &&
          appt.address.county !== undefined &&
          appt.address.county !== null
            ? appt.address['county']
            : ''
        }`,
        `${appt.sampleId}`,
        `${ appt.testName !== undefined &&
          appt.testName !== null ? appt.testName : ""}`,
        `${appt.testId !== undefined &&
          appt.testId !== null ? appt.testId : ""}`
      ];

      a.push(row);

      return a;
    }, []),
  ];
  const buffer = xlsx.build([{ name: 'mySheetName', data: data,options:{} }]); // new package update requires option params
  const blob = new Blob([buffer]);
  saveAs(blob, `report.xlsx`);
};

export const updateAppointmentdata = async (params: Appointment) =>
  firebase
    .functions()
    .httpsCallable('updatedaywiseAppointment', { timeout: 540 * 1000 })(params);

export const getAppointmentdata = async (params: any) =>
  firebase
    .functions()
    .httpsCallable('getAppointmentdata', { timeout: 540 * 1000 })(params);

// export const updateAppointmentdata = async (appointment: Appointment) => {

//   firebase
//   .firestore().collection(config.firestoreCollections.appointments)
//   .doc(appointment.id)
//   .update({
//     location:appointment.location,firstName:appointment.firstName,
//     lastName:appointment.lastName,
//     email:appointment.email,
//     phone:appointment.phone,
//     birthDate:appointment.birthDate,
//     address:appointment.address,
//     slot:appointment.slot,
//     date:appointment.date,
//     minors:appointment.minors
//   });

//   //qbench.updateSample(qbenchId, sampleId, update);

// }

// // firebase
// // .functions()
// // .httpsCallable('getfilterwithrecords', { timeout: 540 * 1000 })(params);

export const generateALDDReport = async (appointments: any) => {
  const data = [
    [
      'Appt Time',
      'Status',
      'Airline',
      'Travel Type',
      'Destination',
      'VIP Slot',
      'Rapid PCR Test',
      'Confirmation ID',
      'Sample ID',
      'First Name',
      'Last Name',
      'Email',
      'DOB',
      'Phone Number',
      'Flight Date and Time',
      'Symptoms Reported?',
      'Contact in last 14 days?',
      'Results',
      'Google/NPS',
      'Latest Collection Date',
      'Location',
    ],
    ...appointments.reduce((acc: any[], appt: any) => {
      const a = acc;

      let location: any = '';
      if (
        appt.location !== undefined &&
        appt?.location?.qbenchCustomerId !== undefined
      ) {
        const filter_data: any = _.filter(config.locations_abbreviation, function(o) { return o.qbenchCustomerId == appt?.location?.qbenchCustomerId });
        if (filter_data.length > 0) {
          location = filter_data[0].value;
        }
      }

      

        if ((appt.isCanceled !== 'Canceled' || appt.isCanceled !== 'Cancelled') && appt.results !== '') {
        const row = [
          appt.period,
          appt.isCanceled,
          // appt.airlineCode,
          appt.airlineCode !== undefined &&
          appt.airlineCode !== null &&
          appt.airlineCode.value !== undefined &&
          appt.airlineCode.value !== null
            ? appt.airlineCode.value
            : appt.airlineCode,
          appt.travelType,
          appt.destination,
          appt.isExpressSameDayTest,
          appt.isRapidTest,
          appt.confirmationId,
          appt.sampleId,
          appt.firstName,
          appt.lastName,
          appt.email,
          appt.birthDate,
          appt.phone,
          appt.departureDateAndTime,
          appt.symptoms,
          appt.hadContact,
          appt.results,
          'NPS',
          appt.date,
          location,
        ];
        a.push(row);
      }
      return a;
    }, []),
  ];
  const buffer = xlsx.build([{ name: 'mySheetName', data: data,options:{} }]); // new package update requires option params
  const blob = new Blob([buffer]);
  saveAs(blob, `ALDD_report.xlsx`);
};
export const upgradeTestType = async (params: any) =>
    firebase.functions().httpsCallable('upgradeTestType')(params);

export const getEmployeeCoupon = async (data: any) => {
    return firebase.functions().httpsCallable('getEmployeeCoupon')(data);
}

export const getALDDReportByDate = async (params: AppointmentsListParams) =>
  firebase
    .functions()
    .httpsCallable('getALDDReportByDate', { timeout: 540 * 1000 })(params);

export const updateAndRelaseAntigenResult = async (params: any) =>
    firebase.functions().httpsCallable('updateAndRelaseAntigenResult', { timeout: 540 * 1000 })(
      params
    );
    
export const updateAntigenResultFalse = async (params: AppointmentsListParams) =>
    firebase.functions().httpsCallable('updateAntigenResultFalse', { timeout: 540 * 1000 })(
      params
    );   

export const getAdminUser = async (params: any) =>
  firebase.functions().httpsCallable('getadminuser', { timeout: 540 * 1000 })({
    email: params.email,
  });

export const exportdatatoexel = async (appointments: any) => {
  const data = [
    [
      'Accessioning Number',
      'Appointment Date',
      'Date of Collection',
      'Complete Date',
      'Patient First Name',
      'Patient Last Name',
      'Patient Street',
      'Patient City',
      'Patient State',
      'Patient Zip',
      'Patient DOB',
      'Patient Sex',
      'Patient Phone Number',
      'Insurance Carrier Name',
      'Insurance Policy Holder',
      'Subscriber First Name',
      'Subscriber Last Name',
      "Subscriber DOB", 
      'Insurance Member ID',
      'Insurance Group',     
      'Ordering Provider',
      'Ordering Provider NPI',
      'Facility ID',
      'Partner Group',
      'Facility CLIA',
      'Facility Street',
      'Facility City',
      'Facility State',
      'Facility Zip',
      'Billing CPT Code',
      'ICD 10 Code',
      'Patient Drivers License',
      'Patient Social Security Number',
      'Insurance Front Card',
      'Insurance Back Card',
      'WSL order_id',
      'Insurance Missing',
      'Source',
      'Pverify'
    ],
    ...appointments.reduce((acc: any[], appt: any) => {
      const a = acc;

      const row = [
          `${
              appt.samples !== undefined &&
              appt.samples !== null &&
              appt.samples.X_EXTERNAL_ACCESSION_ID !== undefined &&
              appt.samples.X_EXTERNAL_ACCESSION_ID !== null
                  ? appt.sampleId
                  : ''
          }`,

          `${appt.date}`,

          `${
              appt.samples !== undefined &&
              appt.samples['time_of_collection'] !== undefined &&
              appt.samples['time_of_collection'] !== null &&
              appt.samples['time_of_collection'] !== ''
                  ? format(
                        new Date(appt.samples['time_of_collection']),
                        config.dateFormat
                    )
                  : ''
          }`,

          `${
              appt.tests !== undefined && appt.tests !== null
                  ? appt.tests['complete_date_formated'] ??
                    appt.tests['complete_date_formated']
                  : ''
          }`,

          // `${appt.samples !== undefined && appt.samples['time_of_recieve'] !== undefined && appt.samples['time_of_recieve'] !== null && appt.samples['time_of_recieve'] !== ''
          //   ? appt.samples['time_of_recieve']
          //   : ''
          // }`,

          `${
              appt.samples !== undefined && appt.samples !== null
                  ? appt.samples['X_PATIENT_FIRST_NAME']
                  : ''
          }`,

          `${
              appt.samples !== undefined && appt.samples !== null
                  ? appt.samples['X_PATIENT_LAST_NAME']
                  : ''
          }`,

          `${
              appt.address !== undefined && appt.address !== null
                  ? appt.address['address']
                  : ''
          }`,

          `${
              appt.address !== undefined && appt.address !== null
                  ? appt.address['city']
                  : ''
          }`,

          `${
              appt.address !== undefined && appt.address !== null
                  ? appt.address['state']
                  : ''
          }`,

          `${
              appt.address !== undefined && appt.address !== null
                  ? appt.address['zipCode']
                  : ''
          }`,

          `${
              appt.samples !== undefined && appt.samples !== null
                  ? appt.samples['X_PATIENT_DOB']
                  : ''
          }`,

          appt.sex,

          appt.phone,

          `${
              appt.insurance !== undefined && appt.insurance !== null
                  ? appt.insurance.payerList
                  : ''
          }`,

          `${
              appt.insurance !== undefined && appt.insurance !== null
                  ? appt.insurance.insuranceFor == "i'm"
                      ? 'Self'
                      : appt.insurance.insuranceFor == 'my Parents'
                      ? 'Child / Dependent'
                      : appt.insurance.insuranceFor == 'my spouse or partner'
                      ? 'Spouse / Partner'
                      : appt.isSolvHealth
                      ? appt.insurance.insuranceFor
                      : 'Other'
                  : ''
          }`,

          `${
              appt.insurance !== undefined && appt.insurance !== null
                  ? appt.insurance.subscriberFirstName
                  : ''
          }`,

          `${
              appt.insurance !== undefined && appt.insurance !== null
                  ? appt.insurance.subscriberLastName
                  : ''
          }`,

          `${
              appt.insurance !== undefined &&
              appt.insurance !== null &&
              appt.insurance?.insuranceFor === "i'm"
                  ? appt.insurance.subscriberbirthDate ?? appt?.birthDate
                  : appt.insurance.policyHolderbirthDate !== undefined &&
                    appt.insurance.policyHolderbirthDate !== null &&
                    appt.insurance.policyHolderbirthDate !== ''
                  ? appt.insurance.policyHolderbirthDate
                  : appt.birthDate
          }`, //subsriber dob

          `${
              appt.insurance !== undefined && appt?.insurance !== null
                  ? appt?.insurance?.insuranceId
                  : ''
          }`,

          `${
              appt.insurance !== undefined && appt.insurance !== null
                  ? appt.insurance.group
                  : ''
          }`,

          // //Not capturing subscrber DOB
          // `${appt.insurance !== undefined && appt.insurance.policyHolderbirthDate !== undefined && (Object.keys(appt.insurance.policyHolderbirthDate).length)>0
          //   ? appt.insurance.policyHolderbirthDate
          //   : ''
          // }`,

          'Christopher Reeves',

          '1871630319',

          `${
              appt.location !== undefined &&
              appt.location !== null &&
              appt.location.facilityId !== undefined &&
              appt.location.facilityId !== null
                  ? appt.location.facilityId
                  : ''
          }`,

          `${
              appt.location !== undefined &&
              appt.location !== null &&
              appt.location.name !== undefined &&
              appt.location.name !== null
                  ? appt.location.name
                  : ''
          }`,

          `${
              appt.location !== undefined &&
              appt.location !== null &&
              appt.location.CLIA !== undefined &&
              appt.location.CLIA !== null
                  ? appt.location.CLIA
                  : ''
          }`,

          `${
              appt.location !== undefined &&
              appt.location !== null &&
              appt.location.facilityStreet !== undefined &&
              appt.location.facilityStreet !== null
                  ? appt.location.facilityStreet
                  : ''
          }`,

          `${
              appt.location !== undefined &&
              appt.location !== null &&
              appt.location.facilityCity !== undefined &&
              appt.location.facilityCity !== null
                  ? appt.location.facilityCity
                  : ''
          }`,

          `${
              appt.location !== undefined &&
              appt.location !== null &&
              appt.location.facilityState !== undefined &&
              appt.location.facilityState !== null
                  ? appt.location.facilityState
                  : ''
          }`,

          `${
              appt.location !== undefined &&
              appt.location !== null &&
              appt.location.facilityZip !== undefined &&
              appt.location.facilityZip !== null
                  ? appt.location.facilityZip
                  : ''
          }`,

          `${
              appt.isPanel
                  ? appt.location?.panelTestCode?.CPT
                  : appt?.location?.CPT
                  ? appt?.location?.CPT
                  : config.Billing_CPT_Code.StandardLab_PCR
          }`,

          `${
              appt.isPanel
                  ? appt.location?.panelTestCode?.ICD
                  : appt?.location?.ICD
                  ? appt?.location?.ICD
                  : 'Z11.52'
          }`,

          `${
              appt.driverLicenseNumber !== undefined &&
              appt.driverLicenseNumber !== null
                  ? appt.driverLicenseNumber
                  : ''
          }`,

          `${
              appt.socialSecurityNumber !== undefined &&
              appt.socialSecurityNumber !== null
                  ? appt.socialSecurityNumber
                  : ''
          }`,

          `${
              appt.insurance !== undefined && appt.insurance !== null
                  ? appt.insurance.frontCard ?? ''
                  : ''
          }`,

          `${
              appt.insurance !== undefined && appt.insurance !== null
                  ? appt.insurance.backCard ?? ''
                  : ''
          }`,

          `${
              appt.qbenchId !== undefined && appt.qbenchId !== null
                  ? appt.qbenchId.toString()
                  : ''
          }`,

          `${appt.insurance?.insuranceId ? 'FALSE' : 'TRUE'}`,

          appt?.isPrimary
              ? '911 Covid'
              : appt?.isSolvHealth
              ? 'SolvHealth'
              : appt?.isReelHealth
              ? 'ReelHealth'
              : appt?.isCLXHealth
              ? 'CLX'
              : appt?.isSchedularLite
              ? 'Scheduler Lite'
              : 'Scheduler',

          `${
              appt.insurance !== undefined &&
              appt.insurance !== null &&
              appt.insurance.newCarrierName === 'Other'
                  ? 'N/A'
                  :appt.insurance.payerList==='Center for Elder Independence/Medicare' ? 'N/A'
        :appt.insurance.payerList==='VETERANS IDENTIFICATION CARD' ? 'N/A'
        :appt.insurance.payerList==='CEI MEDICAL' ? 'N/A'
        :appt.insurance.payerList==='health PAC' ? 'N/A'
        :appt.insurance.payerList==='On Lok PACE' ? 'N/A'
        :appt.insurance.payerList==='Standard Health Care Advantage' ? 'N/A'
        :appt.insurance.payerList==='UNUM Life Insurance' ? 'N/A'
        :appt.insurance.payerList==='alameda alliance' ? 'N/A'
                  : appt.insurance.pVerify
                  ? 'Yes'
                  : 'No'
          }`,
      ];
      a.push(row);
      return a;
    }, []),
  ];
  const buffer = xlsx.build([{ name: 'mySheetName', data: data,options:{} }]); // new package update requires option params
  const blob = new Blob([buffer]);
  saveAs(blob, `report.xlsx`);
};

export const exportDiscountAmoutData = async (
  appointments: any,
  selectedFromDate: any
) => {
  const data = [
    ['First Name', 'Last Name', 'Email', 'Confirmation ID', 'Discount Amount','Promo Code'],
    ...appointments.reduce((acc: any[], appt: any) => {
      const a = acc;
      const row: any = [
        appt.firstName,
        appt.lastName,
        appt.email,
        appt.confirmationId,
        appt.discountAmount !== undefined ? appt.discountAmount : '',
        appt.promoCode?? ''

      ];
      a.push(row);
      return a;
    }, []),
  ];
  const buffer = xlsx.build([{ name: 'mySheetName', data: data,options:{} }]); // new package update requires option params
  const blob = new Blob([buffer]);
  const date = format(new Date(selectedFromDate), config.dateFormat);
  saveAs(blob, `discounted_appt_${date}.xlsx`);
};

export const getAdminConfigDaywise = async (params: any) =>
  firebase.functions().httpsCallable('getAdminConfig', { timeout: 540 * 1000 })(
    params
);

export const insertAdminConfigDaywise = async (params: any) => {
  return firebase
    .functions()
    .httpsCallable('insertAdminConfig', { timeout: 540 * 1000 })(params);
};

export const getDayWiseAppointmentsSearchData = async (
  params: AppointmentsListParams
) =>{
    if (params.locationQbenchId === 0 &&
        params.firstName === '' &&
        params.lastName === '' &&
        params.confirmationId === '' &&
        params.birthDate === '' &&
        params.email === '' &&
        params.phone === '' &&
        !params.sampleId  &&
        params.date === '' &&
        params.status === '' &&
        (params.toggle === '') &&
        params.thirdPartyAppointments === '') {
        console.log(':: empty fields');

        return { data: {} }
    }
    else {
        return firebase
            .functions()
            .httpsCallable('getDayWiseAppointmentsSearchData', { timeout: 540 * 1000 })(
                params
            );
    }
}
export const processNoShow = async (data:any) => 
    firebase
    .functions()
    .httpsCallable('processNoShow', { timeout: 540 * 1000 })(data);
// logAsCustomer API's
export const getUserSearchData = async (params: AppointmentsListParams) =>
  firebase
    .functions()
    .httpsCallable('getCustomersSearchData', { timeout: 540 * 1000 })(params);

export const getUserDetails = async (params: any) =>
  firebase.functions().httpsCallable('getCustomersSearchData', { timeout: 540 * 1000 })(
    params
  );

export const adminRegisterLogin = async (params: any) => 
  firebase.functions().httpsCallable('adminRegisterLogin')(params);

export const getInsuranceAndStripeByDateLocation = async (params: any) =>
  firebase.functions().httpsCallable('getInsuranceAndStripeByDateLocation')(params);

export const getInsuranceAndStripeCount = async (params: any) =>
  firebase.functions().httpsCallable('getInsuranceAndStripeCount')(params);

export const updateIscheckedIn = async (params: any) =>
  firebase
    .functions()
    .httpsCallable('updateIscheckedIn', { timeout: 540 * 1000 })({...params??{},date:format(new Date(),'LL/dd/yyyy hh:mm a ')});

export const updateNoteQbenchacknowledgement = async (params: any) =>
    firebase
        .functions()
        .httpsCallable('updateNoteQbenchacknowledgement', { timeout: 540 * 1000 })(
        {...params,email:JSON.parse(window.localStorage.getItem('userDetails')!).email,date: format(new Date(),'LL/dd/yyyy hh:mm a ')}
    );
export const getQbenchacknowledgement = async (params: any) =>
    firebase
      .functions()
      .httpsCallable('getQbenchacknowledgement', { timeout: 540 * 1000 })(params);

export const checkInCrelioAppointment = async (params: any) => {
    await firebase.functions().httpsCallable('checkIn', { timeout: 540 * 1000 })(
        params
    );
};
// export const getSlotsConfigurationss = async (params: any) => {
//   return firebase
//     .functions()
//     .httpsCallable('getSlotsConfigurationss')(params);
// }

// Manage Inventory
// export const getLocation = async () => {
//     const responsedata = await axios({
//         method: 'POST',
//         url: `${config.baseURL}/getLocation`,
//         headers: {
//             'content-type': 'application/json'
//         },
//         data: {data: {}}

//     });
//     return responsedata;
// }
export const getLocation = async () =>{
  return firebase
  .functions()
  .httpsCallable('getLocation')()
}
export const addInventory = async (params?: any) => {
    return firebase
        .functions()
        .httpsCallable('addInventory')(params);
}
export const getInventory = async (params?: any) => {
    return firebase
        .functions()
        .httpsCallable('getInventory')(params);
}
export const getInventoryAuditLog = async (params?: any) => {
    return firebase
        .functions()
        .httpsCallable('getInventoryAuditLog')(params);
}
export const getNewSlots = async (params: any) => {
  return firebase
    .functions()
    .httpsCallable('getNewSlots')(params);
}
// End - Manage Inventory
export const getTestResultByQBenchCustomerId = async (params: any) => {
  return firebase
      .functions()
      .httpsCallable('getTestResultByQBenchCustomerId')(params);
}

export const updateAssayIdByQbenchCustomerId = async (params: any) => {
  return firebase
      .functions()
      .httpsCallable('updateAssayIdByQbenchCustomerId')(params);
}

// export const updateAssayIdByQBenchCustIdInQBench = async (params: any) => {
//   return firebase
//       .functions()
//       .httpsCallable('updateAssayIdByQBenchCustIdInQBench')(params);
// }
export const updateQbenchCustomerIdByLocationId = async (params: any) => {
  return firebase
      .functions()
      .httpsCallable('updateQbenchCustomerIdByLocationId')(params);
    } 

    export const getEmployeeTesting = async () =>{
      return firebase
       .functions()
       .httpsCallable('getEmployeeTesting')();
    }
    
    export const addEmployeeTesting = async (params: any) =>{
     return firebase
      .functions()
      .httpsCallable('addEmployeeTesting')(params);
    }
    
    export const deleteEmployeeTesting = async (params: any) =>{
     return firebase
      .functions()
      .httpsCallable('deleteEmployeeTesting')(params);
}
export const getAllQbenchCustomerIdsFromLocation = async (params: any) => {
    return firebase
        .functions()
        .httpsCallable('getAllQbenchCustomerIdsFromLocation')(params);
}