
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TextField from '@material-ui/core/TextField';
import TablePagination from '@material-ui/core/TablePagination';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Box, Button, Container, Grid, AppBar, Typography, TableSortLabel, InputLabel, Select, MenuItem } from '@material-ui/core';
import { Link } from 'react-router-dom';
import axios from 'axios';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useAdminDispatch, useAdminState } from '../../provider';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import { createStyles } from '@material-ui/core/styles';
import { config } from '../../../../config';
import { deleteUserRolesDataApi, getAllAccessRolesDataApi, getAllRolesAccessApi, getAllUserRolesDataApi, getRoleByEmailApi } from '../../api';


function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStylesforbackdrop = makeStyles((theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }),
);
const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    tablehead: {
        fontWeight: 'bold'
    },
    btncolor: {
        borderRadius: '5px',
        color: 'white',
        background: "#1890ff",
        "&:hover": {
            backgroundColor: "#40a9ff !important",
        }
    },
    success: {
        color: '#1890ff'
    },
    buttonsearch: {
        display: "flex",
        flexWrap: "wrap",
        // justifyContent: "sapce-evenly",
    },
    searchfield: {
        marginLeft: "60%",     
    },
    selectfield:{
        marginLeft:"80%",
        display: "flex",
        flexWrap: "wrap",
    },
    titleColor: {
        color: '#FFF',
        background: "linear-gradient(60deg, #00acc1, #00acc1)",
        padding: '10px',
    }
});


export default function UserRoles() {
    const user: any = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('userDetails'))))
    const { SetAllUserRole, SetUserID } = useAdminDispatch();
    SetUserID('');
    const [alertbox, setalertbox] = useState<any>({ open: false, type: '', msg: '' });
    const [backdrop, setbackdrop] = useState(false);
    const [filterUsers, setFilterUsers] = useState<any>("")
    const [rowdataCopy, setRowdataCopy] = useState<any>([])
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [rowdata, setrowdata] = React.useState<Data[]>([]);
    const [rolesArr, setRolesArr] = React.useState([]);
    const [select, setSelect] = React.useState ({
        Role:'all'
    });
  const [orderBy, setOrderBy] = React.useState<keyof Data>('email');
  const [order, setOrder] = React.useState<Order>('asc');
  const [confirmdelete, setconfirmdelete] = useState({ open: false, id: '' });
  const [loaded, setloaded] = useState(false);
  const classes = useStyles();
  const {getAllUserRoles} = useAdminState()
  const [userRoleName, setUserRoleName] = useState('Default')

    React.useEffect(() => {
        getRoleByEmail({ email: JSON.parse(window.localStorage.getItem("userDetails"))?.email})
        getAllRolesFun()
    }, [])
    // const getRoleByEmail = async () => {
    //     try {
    //             const resData = await fetch(`${config.baseURL}/getRoleByEmail`, {
    //             method: "POST",
    //             headers: {
    //                 "content-type": "application/json"
    //             },
    //             body: JSON.stringify({ email: JSON.parse(window.localStorage.getItem("userDetails")!).email })
    //         })
    //         const jsonData = await resData.json()
    //             .then(res => {
    //                 if (res.state.length == 0) {
    //                     getAllRolesAccessFields("Default")
    //                 } else {
    //                     setUserRoleName(res.state[0].role)
    //                     getAllRolesAccessFields(res.state[0].role)
    //                 }
    //             })
    //     }
    //     catch (error) {
    //         console.log(error)
    //         setalertbox({ open: true, type: 'error', msg: 'failed' });
    //     }
    // }
    const getRoleByEmail = async (data:any) => {
        try {
            const resData = await getRoleByEmailApi(data)
            if (resData.data.length === 0) {
                getAllRolesAccessFields("Default")
            } else {
                 setUserRoleName(resData.data[0].role)
                 getAllRolesAccessFields(resData.data[0].role)
            }
        }
        catch (error) {
            console.log(error)
            setalertbox({ open: true, type: 'error', msg: 'failed' });
        }
    }

    // const getAllRolesAccessFields = async (roleName: any) => {
    //     axios({
    //         url: `${config.baseURL}/getAccessFieldsByRole`,
    //         method: 'post',
    //         data: { roleName: roleName },
    //     }).then((res:any) =>{
    //         if(res.data.state[0]?.accessFields)
    //         checkAccess(res.data.state[0]?.accessFields)
    //     })
    // }
    const getAllRolesAccessFields = async (roleName: any) => {
        const resData = await getAllRolesAccessApi({ roleName: roleName });
        checkAccess(resData.data[0]?.accessFields)
    }
    const checkAccess = (allAccessFields: any) => {
        //check allAccessFiels includes curent component name
        //Name should be as given in the routes
        if (!allAccessFields.includes("Roles Access Form")) {
            alert("You should be admin to access this page")
            window.location.href = `${config.auth0Redirection.login}/dashboard`;
        }
    }
    // const getdata = async (method: any, id = null) => {
    //     if (method === 'getAll') {
    //         const responsedata = await axios({
    //             method: 'GET',
    //             url: `${config.baseURL}/getAllUserRolesData`,
    //             headers: {
    //                 'content-type': 'application/json'
    //             }
    //         });
    //         setloaded(true)
    //         console.log("responsedata",responsedata)
    //         return responsedata;
    //     }
    //     else
    //         if (method === 'delete') {
    //             const responsedata = await axios({
    //                 method: 'POST',
    //                 url: `${config.baseURL}/deleteUserRolesData`,
    //                 headers: {
    //                     'content-type': 'application/json'
    //                 },
    //                 data: { id }
    //             });
    //             return responsedata;
    //         }
    // }
    const getdata = async (method: any, id = null) => {
        if (method === 'getAll') {
            console.log("KC getALl", );
            try {
                const responsedata = await axios({
                    url: `${config.baseURL}/getAllUserRolesData`,
                    method: 'post',
                    data: { data:null },
                });
                setloaded(true)
                console.log("KC responseData", responsedata);
                
                return responsedata;
                
            } catch (error) {
                console.log("KC error", error);
                
                
            }
            
        }
        else
            if (method === 'delete') {
                const responsedata = await deleteUserRolesDataApi({ id,updatedBy:user?.email ??"",userName:user?.name ?? "" })
                return responsedata;
            }
    }
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };


    useEffect(() => {
        getdata('getAll').then((res: any) => {
            let newdata = res.data.userRole.map((e: any) => {
                return ({ id: e.newid, ...e.data })
            });
            SetAllUserRole(newdata);
            setrowdata(newdata);
            setRowdataCopy(newdata)
        }).catch(err => { console.log(err); })
    }, []);


    const deleteData = (id: any) => {
        if (!(id === '' || id === null)) {
            setbackdrop(true);
            getdata('delete', id).then((res: any) => {
                if (res.status === 200) {
                    let newdata = rowdata.filter((e: any) => e.id !== id);
                    setrowdata(newdata);
                    SetAllUserRole(newdata);
                    setalertbox({ open: true, type: 'success', msg: 'Row Deleted Successfully' });
                    setbackdrop(false);
                    if(select.Role === 'all'){
                        setRowdataCopy(newdata.filter((user: { email: string; }) => {
                            return user.email.toLowerCase().includes(filterUsers.toLowerCase())
                         }))
                        setPage(0);
                    } else {
                        setRowdataCopy(newdata.filter((user: {email: string}) => {
                            return user.email.toLowerCase().includes(filterUsers.toLowerCase())
                        }).filter((user:  {role: string}) => {
                            return user.role.toLowerCase().includes(select.Role.toLowerCase())
                         }))
                        setPage(0);
                    }}
                else {
                    setalertbox({ open: true, type: 'error', msg: 'Unable to Deleted Row' });
                    setbackdrop(false);
                }
            }).catch(err => {
                setalertbox({ open: true, type: 'error', msg: 'Unable to Deleted Row' });
                setbackdrop(false);
            })
        }
        setconfirmdelete({ open: false, id: '' });
    }

    const forbackdrop = useStylesforbackdrop();
    function ConfirmAlertBox() {
        return (
            <>
                <Dialog
                fullWidth
                    open={confirmdelete.open}
                    onClose={() => setconfirmdelete({ open: false, id: '' })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Do you really want to Delete it?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='outlined' onClick={() => setconfirmdelete({ open: false, id: '' })} color="primary">
                            Cancel
                        </Button>
                        <Button variant='contained' color="secondary" onClick={() => deleteData(confirmdelete.id)}>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
    const getAllRolesFun = async () => {
        const resData = await fetch(`${config.baseURL}/getAllAccessRolesData`)
        const jsonData = await resData.json();
        setRolesArr(jsonData?.allRole)
    }
    // const getAllRolesFun = async () => {
    //     const resData = await getAllAccessRolesDataApi();
    //     console.log({ getAllAccessRolesData:resData});
        
    //     setRolesArr(resData?.data?.allRole)
    // }

    // React.useEffect(() => {
    //     getAllRolesFun()
    // }, [])

    //sorting
    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    type Order = 'asc' | 'desc';

    function getComparator<Key extends keyof any>(
        order: Order,
        orderBy: Key,
    ): (
            a: { [key in Key]: number | string },
            b: { [key in Key]: number | string },
        ) => number {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    // This method is created for cross-browser compatibility, if you don't
    // need to support IE11, you can use Array.prototype.sort() directly
    function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
        const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }
    interface Data {
        email: string;
        role: string;
        id: string
    }
    interface HeadCell {
        id: keyof Data;
        label: string;
        numeric: boolean;
    }

    const headCells: readonly HeadCell[] = [
        {
            id: 'email',
            numeric: false,
            label: 'Email',
        },
        {
            id: 'role',
            numeric: false,
            label: 'Roles',
        },
    ];
    interface EnhancedTableProps {
        onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
        order: Order;
        orderBy: string;
    }

    function EnhancedTableHead(props: EnhancedTableProps) {
        const { order, orderBy, onRequestSort } =
            props;
        const createSortHandler =
            (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
                onRequestSort(event, property);
            };
            return (
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell:any) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={createSortHandler(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                      
                    ))}
                    <TableCell align='center' className={classes.tablehead}>Actions</TableCell>
                  </TableRow>
                </TableHead>
              );
        }
        const handleRequestSort = (
            event: React.MouseEvent<unknown>,
            property: keyof Data,
          ) => {
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
          };
          
  
    return (
        <>
            <AppBar position="static" color="primary" className={classes.titleColor} style={{ textAlign: 'center' }}>
                <Typography variant="h6" color="inherit" component="div" style={{ padding: "15px", color: '#FFF' }}>
                    User Role Details
                </Typography>
            </AppBar>
            <Box >
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={alertbox.open} autoHideDuration={3000} onClose={() => setalertbox({ oepn: false, type: '', msg: '' })}>
                    <Alert onClose={() => setalertbox({ oepn: false, type: '', msg: '' })} severity={alertbox.type}>{alertbox.msg}</Alert>
                </Snackbar>
                <Backdrop className={forbackdrop.backdrop} open={backdrop} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <ConfirmAlertBox />
                <Container maxWidth='xl'>
                    <Grid >
                        <Container maxWidth='xl'>
                            <Box mt={10} mb={5} className={classes.buttonsearch}>
                                <Link to='/dashboard/UserRolesForm'><Button className={classes.btncolor} variant='contained'>Add New User</Button></Link> &nbsp;
                                <Link to='/dashboard/RolesAccessForm'><Button className={classes.btncolor} variant='contained'>Role access Details</Button></Link>
                               
                               
                                <TextField className={classes.searchfield}
                                    label="Filter by Email"
                                    type="search"
                                    variant="outlined"
                                    onChange={(e) => {
                                        setFilterUsers(e.target.value.toLowerCase())
                                        let filterData: any;
                                        if(select.Role === 'all'){
                                            filterData = getAllUserRoles.filter((user: { email: string; }) => {
                                                return user.email.toLowerCase().includes(e.target.value.toLowerCase())
                                             })
                                            setPage(0);
                                        } else {
                                            filterData =rowdata.filter((user: {email: string}) => {
                                                return user.email.toLowerCase().includes(e.target.value.toLowerCase())
                                            }).filter((user:  {role: string}) => {
                                                return user.role.toLowerCase().includes(select.Role.toLowerCase())
                                             })
                                            setPage(0);
                                        }
                                        setRowdataCopy(filterData)
                                    }}
                                    value={filterUsers.toLowerCase()}
                                />
                                 <div style={{ margin: "40px" }}></div>
                                    <InputLabel id="Role" className={classes.selectfield}><b>Select Role</b> </InputLabel>
                                    <Select 
                                        label="Select Role"
                                        type='dropdown'
                                        className={classes.selectfield} 
                                        variant='outlined' 
                                        id="Role" 
                                        onChange={(e:any) => {
                                            let data: any = { ...select };
                                            data.Role = e.target.value;
                                            setSelect({Role:e.target.value});
                                            let filterData: any;
                                            if(e.target.value === 'all'){
                                                filterData = getAllUserRoles.filter((user: { email: string; }) => {
                                                                return user.email.toLowerCase().includes(filterUsers.toLowerCase())
                                                             })
                                                setPage(0);
                                            } else {
                                                filterData = rowdata.filter((user: { email: string; }) => {
                                                    return user.email.toLowerCase().includes(filterUsers.toLowerCase())
                                                 })?.filter((user:any) => {
                                                    return (user.role.toLowerCase()) === (e.target.value.toLowerCase())
                                                 })
                                                setPage(0);
                                            }                                            
                                            setRowdataCopy(filterData);
                                        }}
                                        value={select.Role}
                                    >
                                        <MenuItem value="all">All</MenuItem>
                                        {rolesArr?.length && rolesArr.filter((role: any) => role.data.roleName !== "Default").sort((a:any,b:any)=>a.data.roleName.localeCompare(b.data.roleName)).map((role: any) => {
                                            return <MenuItem value={role.data.roleName}>{role.data.roleName}</MenuItem>
                                        })}
                                    </Select>
                            </Box>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="simple table">
                                    {/* <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.tablehead}> Email</TableCell>
                                            <TableCell className={classes.tablehead}>Module</TableCell>
                                            <TableCell className={classes.tablehead}>Roles</TableCell>

                                            <TableCell align='center' className={classes.tablehead}>Actions</TableCell>
                                        </TableRow>
                                    </TableHead> */}
                                    <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort}/>
                                    <TableBody>
                                        {loaded ? (
                                            stableSort(rowdata,getComparator(order, orderBy))
                                            .filter((row: any) => {
                                                return row.email.includes(filterUsers)
                                            }).filter((row: any) => {
                                                if (select.Role === "all") {
                                                    return true
                                                } else {                                                    
                                                    return (row.role).toLowerCase() === (select.Role).toLowerCase()
                                                }
                                            })
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length === 0
                                            ? (<TableRow >
                                                    <TableCell align='center' colSpan={9}>
                                                        No Data Found
                                                    </TableCell>
                                                </TableRow>) : (
                                                    stableSort(rowdata,getComparator(order, orderBy))
                                                    .filter((row: any) => {
                                                        return row.email.includes(filterUsers)
                                                    }).filter((row: any) => {
                                                        if (select.Role === "all") {
                                                            return true
                                                        } else {
                                                            return row.role.toLowerCase() === (select.Role).toLowerCase()
                                                        }
                                                    })
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((row: any) => (
                                                        <TableRow key={row.id}>
                                                            <TableCell >{row.email}</TableCell>
                                                            {/* <TableCell >{row.module}</TableCell> */}

                                                            <TableCell >{row.role}</TableCell>
                                                            <TableCell align='center'>
                                                               {row.role==='Super Admin' && userRoleName =="Admin" ?'': 
                                                               <>
                                                                <Link to={'/dashboard/UserRolesForm'}>
                                                                    <IconButton aria-label="edit" onClick={() => SetUserID(row.id)}>
                                                                        <EditIcon className={classes.success} />
                                                                    </IconButton>
                                                                </Link>
                                                                <IconButton aria-label="delete" onClick={() => setconfirmdelete({ open: true, id: row.id })}>
                                                                    <DeleteIcon color='secondary' />
                                                                </IconButton>
                                                                </>
                                                            }
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                            )) : (<>
                                                <TableRow >
                                                    <TableCell align='center' colSpan={9}>
                                                        <CircularProgress />
                                                    </TableCell>
                                                </TableRow>
                                            </>)}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 25, 50 , 100]}
                                component="div"
                                count={rowdataCopy.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Container>
                    </Grid>
                </Container>
            </Box>
        </>
    );
}
