import { Input, Modal, Spin } from 'antd';
import React, { useState } from 'react'
import { addNpDestionation } from '../../api';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

interface IProps {
    showEditNpModal:boolean;
    setShowEditNpModal: Function;
    npData: any;
    getNpDest?: () => void;
}

const NpModal = ({
    showEditNpModal,
    setShowEditNpModal,
    npData,
    getNpDest,
}: IProps) => {

    const [loading, setLoading] = React.useState(false);
    const [alertbox, setalertbox] = React.useState<{
        type: string;
        msg: string;
        open: true | false;
    }>({ type: '', msg: '', open: false });
    const [locationName, setLocationName] = useState(
        npData ? npData.label : ''
    );
    const handleCancel =()=>{
        setShowEditNpModal(false)
        setLocationName('');
    }
    const handleOk = async()=>{
        setLoading(true);
        let params = {
            ...npData,
            label : locationName
        }
        const addData: any = await addNpDestionation(params);
        console.log('addData', addData);
        if (addData?.data?.status === 200) {
            setalertbox({
                open: true,
                type: 'success',
                msg: 'NP location Updated successfully',
            });
            setLoading(false);
            
        } 
        else{
            setLoading(false);
            setalertbox({
                open: true,
                type: 'error',
                msg: 'Location not updated',
            });
        }
        getNpDest && getNpDest();
        setShowEditNpModal(false);
        setLocationName('');
    }
    const handleClose = () => {
        setalertbox({ open: false, type: '', msg: '' });
    };

    function Alert(props: any) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    
    return (
        <Modal
            zIndex={2000}
            title="Update Location Name"
            visible={showEditNpModal}
            onCancel={handleCancel}
            onOk={handleOk}
            okText={'Update'}
        >
            {loading === false ? (
                <Input
                    placeholder="Location ..."
                    value={locationName}
                    onChange={(e) => setLocationName(e.target.value)}
                />
            ) : (
                <Spin style={{'textAlign':'center'}} />
            )}
            <Snackbar
                open={alertbox.open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                autoHideDuration={3000}
            >
                <Alert onClose={handleClose} severity={alertbox.type}>
                    {alertbox.msg}
                </Alert>
            </Snackbar>
        </Modal>
    );
};

export default NpModal
