import React from 'react';
import styled from 'styled-components';
import {  useAdminState } from '../../../provider';
import {  CSVLink } from 'react-csv';
import {  parse,  format } from 'date-fns';
import {  config } from '../../../../../config';
import {  Box,  Button } from '@material-ui/core';
const Wrapper = styled.div`
    padding-bottom: 10px;
    padding-top: 10px;
    margin-left: auto;
    text-align: right;
    a {
        text-decoration: none;
    }
`;
const headers = [
    { label: 'Check In', key: 'isCheckedIn' },
    { label: 'Notes', key: 'note' },
    { label: 'Test Type', key: 'testType' },
    { label: 'Test Name', key: 'testName' },
    { label: 'Appt Date', key: 'date' },
    { label: 'Appt Time', key: 'period' },
    { label: 'PNR #', key: 'PNR' },
    { label: 'Flight #', key: 'flightNo' },
    { label: 'Confirmation No', key: 'confirmationId' },
    { label: 'Status', key: 'status' },
    { label: 'No Show', key: 'noShow' },
    { label: 'Collection Method', key: 'collectionmethod' },
    { label: 'VIP Slot', key: 'isExpressSameDayTest' },
    { label: 'Rapid', key: 'isRapidTestText' },
    { label: 'Sample ID', key: 'sampleId' },
    { label: 'Test ID', key: 'testId' },
    { label: 'First Name', key: 'firstName' },
    { label: 'Last Name', key: 'lastName' },
    { label: 'Email', key: 'email' },
    { label: 'DOB', key: 'birthDate' },
    { label: 'Phone Number', key: 'phone' },
    { label: 'Airline', key: 'airlineCode' },
    { label: 'Travel Type', key: 'travelType' },
    { label: 'Destination', key: 'destination' },
    { label: 'Flight Date and Time', key: 'departureDateAndTime' },
    { label: 'Classroom', key: 'classroom' },
    { label: 'Results', key: 'results' },
    { label: 'Payment Method', key: 'payingMethod' },
    { label: 'Symptoms Reported?', key: 'symptoms' },
    { label: 'Contact in last 14 days?', key: 'hadContact' },
    { label: 'Price', key: 'amount' },
];

interface props {
    columns?: any;
    tableData?: any[];
    paramData?: string;
    downLoadInvoice?: boolean;
}
const DownloadLinkCSV: React.FC<props> = ({
    columns,
    tableData,
    paramData,
    downLoadInvoice = false,
}) => {
    const { params, appointments } = useAdminState();
    const appointmentData: any[] = [];
    if (tableData?.length ? tableData.length : appointments?.length) {
        (tableData?.length ? tableData : appointments)?.map((e: any) => {
            if (e.isPanel && !downLoadInvoice) {
                e.tests.map((ee: any, index: number) => {
                    appointmentData.push({
                        ...e,
                        amount: index === 0 ? e?.amount : '',
                        testId: `=""${ee.id}""` ?? '',
                        testName: ee?.name ?? '',
                        results: ee.result ?? '',
                    });
                });
            } else {
                appointmentData.push({
                    ...e,
                    testId: `=""${e?.testID || ''}""`,
                    testName: '',
                });
            }
        });
    }
    const DownloadContent = ({ title = 'Download CSV' }) => (
        <>
            {Array.isArray(appointmentData) && appointmentData.length > 0 ? (
                <CSVLink
                    data={
                        appointmentData
                            ? appointmentData.map((appt) => ({
                                  ...appt,

                                  symptoms:
                                      Array.isArray(appt.symptoms) &&
                                      appt.symptoms.length > 0
                                          ? appt.symptoms.join(', ')
                                          : 'None',
                                  note:
                                      typeof appt.note === 'string'
                                          ? appt.note
                                          : appt.note
                                                .map((e: any) => e.message)
                                                .join(', '),
                                  birthDate: `=""${appt.birthDate}""`,
                                  amount: `=""${appt.amount}""`,
                                  sampleId: `=""${appt.sampleId}""`,
                                  date: `=""${appt.date}""`,
                                  phone: `=""${appt.phone}""`,
                                  classroom: `=""${appt?.classroom || ''}""`,
                                  isCanceled: `=""${
                                      appt?.isCanceled ? 'Cancelled' : ''
                                  }""`,
                                  airlineCode: `=""${appt.airlineCode}""`,
                                  travelType: `=""${appt.travelType}""`,
                                  hadContact: `=""${appt.hadContact}""`,
                                  departureDateAndTime: `=""${
                                      appt?.departureDateAndTime || ''
                                  }""`,
                                  destination: `=""${appt.destination}""`,
                                  isExpressSameDayTest: `=""${appt.isExpressSameDayTest}""`,
                                  flightNo: `=""${appt.flightNo || ''}""`,
                                  PNR: `=""${appt.PNR || ''}""`,
                                  period: `=""${
                                      appt?.slot?.label?.split(' - ')[0] ?? ''
                                  }""`,
                                  testType: appt.isRapidTest
                                  ? 'Rapid PCR Test'
                                  : appt.isExpressSameDayTest
                                      ? 'Express PCR Test'
                                      : appt?.isAntigen
                                          ? 'Antigen Test'
                                          : appt?.isPanel
                                           ? 'Flu A&B/Covid-19'
                                          : 'Standard PCR Test',
                                  status: `=""${
                                      appt.isCanceled
                                          ? 'Cancelled'
                                          : appt.results === null
                                          ? ''
                                          : appt.results !== ''
                                          ? 'Completed'
                                          : ''
                                  }""`,
                                  isRapidTestText: appt.isRapidTest
                                      ? 'YES'
                                      : '',
                                  SolvHealth: appt.isSolvHealth
                                      ? 'SolvHealth'
                                      : appt.isReelHealth
                                      ? 'ReelHealth'
                                      : appt.isPrimary
                                      ? '911 Covid'
                                      : appt.isCLXHealth
                                      ? 'CLXHealth'
                                      : '',

                                  isCheckedIn: appt.isCheckedIn
                                      ? `=""True""`
                                      : `=""False""`,
                              }))
                            : []
                    }
                    headers={headers
                        .filter(
                            (e) =>
                                columns?.includes(e.label) ||
                                ([
                                    'Symptoms Reported?',
                                    'Contact in last 14 days?',
                                    'Test ID',
                                    'Test Name',
                                    'Payment Method',
                                ].includes(e.label) &&
                                    !downLoadInvoice)
                        )
                        .map((e) =>
                            downLoadInvoice && e.label === 'Price'
                                ? { ...e, label: 'Price($)' }
                                : e
                        )}
                    filename={`Appointments ${
                        paramData
                            ? paramData
                            : format(
                                  params.date != ''
                                      ? parse(
                                            params.date,
                                            config.dateFormat,
                                            new Date()
                                        )
                                      : new Date(),
                                  config.fileDateFormat
                              )
                    }.csv`}
                >
                    {title}
                </CSVLink>
            ) : null}
        </>
    );
    if (downLoadInvoice) {
        return (
            <>
                <Box
                    alignContent="center"
                    alignItems="center"
                    textAlign="center"
                >
                    {
                        <Button
                            style={{
                                backgroundColor: '#aeb5b6',
                                color: 'black',
                                height: 33,
                                margin: '5px 0 0 17px',
                            }}
                        >
                            <DownloadContent title="Download Invoice" />
                        </Button>
                    }
                </Box>
            </>
        );
    }
    return (
        <Wrapper>
            <DownloadContent />
        </Wrapper>
    );
};
export default DownloadLinkCSV;
