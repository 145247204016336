import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Grid, TextField } from '@material-ui/core';
import { updateMobile } from './Model';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function ModalBoxForChangeEmail({ id, phone, name, open, setopen, classes, email }: any) {

    const [mobile, setmobile] = React.useState<string>("")
    const handleSubmit = () => {
        // setopen(true);
        if (mobile !== '' && mobile.length >= 10) {
            updateMobile(id, mobile).then((response: any) => {
                if (response.data) {
                    setopen(false);
                }
            })
        }


    };
    const handleClose = () => {
        setopen(false);
    };

    return (
        <div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Change Mobile Number
                </DialogTitle>
                <DialogContent dividers style={{ width: '600px' }}>
                    <Typography variant="h6" color="initial" > Email : {email} </Typography>
                    <Typography variant="h6" color="initial" > Name : {name} </Typography>
                    <Typography variant="h6" color="initial" > Phone : {phone} </Typography>
                    <br />
                    <br />

                    <Grid justify='center' container >
                        <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
                            <TextField fullWidth className={classes.heightfield} id="outlined-basic" value={mobile} onChange={(e) => {
                                setmobile(e.target.value);
                            }} label="Phone" variant="outlined" />
                        </Grid>
                    </Grid>
                    <br />
                    <br />

                </DialogContent>
                <DialogActions>
                    <Button autoFocus variant='contained' onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} variant='contained' color="primary">
                        Change Number
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
