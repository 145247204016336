import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// @material-ui/icons
import { makeStyles } from '@material-ui/core/styles';
import Card from './Card/Card.js';
import Grid from '@material-ui/core/Grid';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DotIcon from '@material-ui/icons/MoreHoriz';
import DonutIcon from '@material-ui/icons/DonutSmall';
import CalIcon from '@material-ui/icons/DateRange';
import CountIcon from '@material-ui/icons/Assessment';
// Component
import CardHeader from './Card/CardHeader.js';
import Loader from '../../../shared/components/Loader';
import Chart from 'react-apexcharts';

import {
  getApptConsolidationDetails,
  getApptbasedonLocation,
  getApptbasedonGender,
  getApptbasedonresults,
} from '../../../admin/api';

import styles from './dashboardStyle.js';
import { useAdminDispatch, useAdminState } from '../../provider';
const useStyles = makeStyles(styles);

export default function Dashboard() {
  const history = useHistory();
  const [TodayAppointments, settodayAppointments] = useState([]);
  const [TomorrowAppointments, settomorrowAppointments] = useState([]);
  const [ThisWeekAppointments, setthisweekAppointments] = useState([]);
  const [onLocationLabel, setLocationlabel] = useState(['']);
  const [onLocationCount, setLocationcount] = useState([0]);
  const [onGenderCount, setGendercount] = useState([0]);
  const [DetectedCount, setDetectedresult] = useState([0]);
  const [NotdetectedCount, setNotdetectedresult] = useState([0]);
  const [nodataLocation, setLocationNoData] = useState(false);
  const [nodataGender, setGenderNoData] = useState(false);
  const [loading, setLoading] = useState(true);

  const { updateUserRole } = useAdminDispatch();
  const { userRole } = useAdminState();

  const classes = useStyles();

  const optionLocation = {
    chart: {
      width: '100%',
      height: 400,
    },
    labels: onLocationLabel,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: '100%',
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  const optionGender = {
    chart: {
      width: '100%',
      height: 400,
    },
    labels: ['Female', 'Male', 'Prefer not to state'],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: '100%',
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  //Line chart options
  const linegraphseries = [
    {
      name: 'Count',
      data: onLocationCount,
    },
  ];

  const lineoptions = {
    chart: {
      id: 'basic-bar',
    },
    xaxis: {
      categories: onLocationLabel,
    },
    markers: {
      size: 4,
      colors: ['#FFA41B'],
      strokeColors: '#fff',
      strokeWidth: 2,
      hover: {
        size: 7,
      },
    },
    yaxis: {
      min: 0,
      max: undefined,
      title: {
        text: 'Count',
      },
    },
    title: {
      text: 'Appointments By Location',
      style: {
        fontSize: '14px',
        color: '#666',
      },
    },
  };

  useEffect(() => {
    setLoading(true);
    const userdata: any = JSON.parse(
      JSON.parse(JSON.stringify(localStorage.getItem('userDetails')))
    );
    // async function callAPI(params: any) {
    //   await axios({
    //     method: 'post',
    //     url: `${config.baseURL}/getadminuser`,
    //     data: { data: { email: params } },
    //   }).then(function (response: any) {
    //     if (response.data.result.adminuser.length > 0) {
    //       updateUserRole(response.data.result.adminuser[0].role);
    //     } else {
    //       updateUserRole('default');
    //     }
    //   });
    // }

    // callAPI(userdata['email']);

    getApptConsolidationDetails()
      .then(({ data }) => {
        settodayAppointments(data.Todayappointmentscount);
        settomorrowAppointments(data.Tomorrowappointmentscount);
        setthisweekAppointments(data.Thisweekappointmentscount);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });

    getApptbasedonLocation()
      .then(({ data }) => {
        var locationlabelresult = [];
        var locationcountresult = [];
        for (var i = 0; i < data.length; i++) {
          locationlabelresult.push(data[i].Location.split(' - ')[0]);
          locationcountresult.push(data[i].count);
        }
        setLocationcount(locationcountresult);
        setLocationlabel(locationlabelresult);
        var locationnodata = locationcountresult.every(
          (val, i, arr) => val === arr[0]
        );
        setLocationNoData(locationnodata);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });

    getApptbasedonGender()
      .then(({ data }) => {
        const gendercountresult = Object.keys(data).map((key) => data[key]);
        setGendercount(gendercountresult);
        var gendernodata = gendercountresult.every(
          (val, i, arr) => val === arr[0]
        );
        setGenderNoData(gendernodata);
      })
      .catch((err) => {
        console.error(err);
      });

    getApptbasedonresults()
      .then(({ data }) => {
        setDetectedresult(data.detectedcount);
        setNotdetectedresult(data.notdetectedcount);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <div style={{ flexGrow: 1 }}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <Card>
                <div
                  onClick={() =>
                    history.push('/dashboard/appoinment', { Activetabs: 0 })
                  }
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    paddingTop: '15px',
                    paddingBottom: '15px',
                  }}
                >
                  <DashboardIcon
                    style={{
                      color: '#56d9fe',
                      width: '50px',
                      height: '50px',
                      paddingLeft: '2px',
                      paddingRight: '2px',
                    }}
                  />
                  <div
                    style={{
                      width: '70%',
                      paddingLeft: '5px',
                      paddingRight: '5px',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '24px',
                        color: '#56d9fe',
                        display: 'block',
                        textAlign: 'left',
                        fontWeight: 600,
                      }}
                    >{`${
                      TodayAppointments !== null ? TodayAppointments : 0
                    }`}</span>
                    <span
                      style={{
                        fontSize: '14px',
                        color: '#4d4f5c',
                        display: 'block',
                        textAlign: 'left',
                      }}
                    >
                      Appointments Today
                    </span>
                  </div>
                  <DotIcon
                    style={{ color: '#aeb8bf', width: '20px', height: '20px' }}
                  />
                </div>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Card>
                <div
                  onClick={() =>
                    history.push('/dashboard/appoinment', { Activetabs: 1 })
                  }
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    paddingTop: '15px',
                    paddingBottom: '15px',
                  }}
                >
                  <DonutIcon
                    style={{
                      color: '#a4a1fb',
                      width: '50px',
                      height: '50px',
                      paddingLeft: '2px',
                      paddingRight: '2px',
                    }}
                  />
                  <div
                    style={{
                      width: '70%',
                      paddingLeft: '5px',
                      paddingRight: '5px',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '24px',
                        color: '#a4a1fb',
                        display: 'block',
                        textAlign: 'left',
                        fontWeight: 600,
                      }}
                    >{`${
                      TomorrowAppointments !== null ? TomorrowAppointments : 0
                    }`}</span>
                    <span
                      style={{
                        fontSize: '14px',
                        color: '#4d4f5c',
                        display: 'block',
                        textAlign: 'left',
                      }}
                    >
                      Appointments Tomorrow
                    </span>
                  </div>
                  <DotIcon
                    style={{ color: '#aeb8bf', width: '25px', height: '25px' }}
                  />
                </div>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Card>
                <div
                  onClick={() =>
                    history.push('/dashboard/appoinment', { Activetabs: 2 })
                  }
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    paddingTop: '15px',
                    paddingBottom: '15px',
                  }}
                >
                  <CalIcon
                    style={{
                      color: '#4ad991',
                      width: '50px',
                      height: '50px',
                      paddingLeft: '2px',
                      paddingRight: '2px',
                    }}
                  />
                  <div
                    style={{
                      width: '70%',
                      paddingLeft: '5px',
                      paddingRight: '5px',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '24px',
                        color: '#4ad991',
                        display: 'block',
                        textAlign: 'left',
                        fontWeight: 600,
                      }}
                    >
                      {ThisWeekAppointments !== null ? ThisWeekAppointments : 0}
                    </span>
                    <span
                      style={{
                        fontSize: '14px',
                        color: '#4d4f5c',
                        display: 'block',
                        textAlign: 'left',
                      }}
                    >
                      Appointments This Week
                    </span>
                  </div>
                  <DotIcon
                    style={{ color: '#aeb8bf', width: '25px', height: '25px' }}
                  />
                </div>
              </Card>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Card className={classes.defectcardview}>
                <h3
                  style={{
                    textAlign: 'center',
                    paddingRight: '20px',
                    paddingTop: '10px',
                    fontWeight: 500,
                    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                  }}
                >
                  Appointment Volume by Location
                </h3>
                <CardHeader>
                  <div style={{ paddingTop: '2px', paddingLeft: '30px' }}>
                    {nodataLocation ? (
                      <h4 style={{ textAlign: 'left', color: '#a1a1a1' }}>
                        ⚠ No Data
                      </h4>
                    ) : null}
                    <Chart
                      options={optionLocation}
                      series={onLocationCount}
                      type="pie"
                    />
                  </div>
                </CardHeader>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Card className={classes.defectcardview}>
                <h3
                  style={{
                    textAlign: 'center',
                    paddingRight: '20px',
                    paddingTop: '10px',
                    fontWeight: 500,
                    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                  }}
                >
                  Appointment Volume by Gender
                </h3>

                <CardHeader>
                  <div style={{ paddingTop: '2px', paddingLeft: '30px' }}>
                    {nodataGender ? (
                      <h4 style={{ textAlign: 'left', color: '#a1a1a1' }}>
                        ⚠ No Data
                      </h4>
                    ) : null}
                    <Chart
                      options={optionGender}
                      series={onGenderCount}
                      type="pie"
                    />
                  </div>
                </CardHeader>
              </Card>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <Card>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                  }}
                >
                  <CountIcon
                    style={{
                      color: '#4d4f5c',
                      width: '50px',
                      height: '50px',
                      paddingLeft: '2px',
                      paddingRight: '2px',
                    }}
                  />
                  <div
                    style={{
                      width: '70%',
                      paddingLeft: '5px',
                      paddingRight: '5px',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '17px',
                        color: '#4d4f5c',
                        display: 'block',
                        textAlign: 'left',
                        fontWeight: 400,
                      }}
                    >
                      Not Detected
                    </span>

                    <span
                      style={{
                        fontSize: '17px',
                        color: '#4d4f5c',
                        display: 'block',
                        textAlign: 'left',
                      }}
                    >{`${
                      NotdetectedCount !== null ? NotdetectedCount : 0
                    }`}</span>
                  </div>
                  <DotIcon
                    style={{ color: '#aeb8bf', width: '20px', height: '20px' }}
                  />
                </div>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Card>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                  }}
                >
                  <CountIcon
                    style={{
                      color: '#4d4f5c',
                      width: '50px',
                      height: '50px',
                      paddingLeft: '2px',
                      paddingRight: '2px',
                    }}
                  />
                  <div
                    style={{
                      width: '70%',
                      paddingLeft: '5px',
                      paddingRight: '5px',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '17px',
                        color: '#4d4f5c',
                        display: 'block',
                        textAlign: 'left',
                        fontWeight: 400,
                      }}
                    >
                      Detected
                    </span>

                    <span
                      style={{
                        fontSize: '17px',
                        color: '#4d4f5c',
                        display: 'block',
                        textAlign: 'left',
                      }}
                    >{`${DetectedCount !== null ? DetectedCount : 0}`}</span>
                  </div>
                  <DotIcon
                    style={{ color: '#aeb8bf', width: '20px', height: '20px' }}
                  />
                </div>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Card>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                  }}
                >
                  <CountIcon
                    style={{
                      color: '#4d4f5c',
                      width: '50px',
                      height: '50px',
                      paddingLeft: '2px',
                      paddingRight: '2px',
                    }}
                  />
                  <div
                    style={{
                      width: '70%',
                      paddingLeft: '5px',
                      paddingRight: '5px',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '17px',
                        color: '#4d4f5c',
                        display: 'block',
                        textAlign: 'left',
                        fontWeight: 400,
                      }}
                    >
                      Detected %
                    </span>

                    <span
                      style={{
                        fontSize: '17px',
                        color: '#4d4f5c',
                        display: 'block',
                        textAlign: 'left',
                      }}
                    >
                      {Math.floor((Number(DetectedCount) / 100) * 100)}
                    </span>
                  </div>
                  <DotIcon
                    style={{ color: '#aeb8bf', width: '20px', height: '20px' }}
                  />
                </div>
              </Card>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Card className={classes.defectcardview}>
              <Chart
                options={lineoptions}
                series={linegraphseries}
                type="line"
                height={350}
              />
            </Card>
          </Grid>
        </>
      )}
    </div>
  );
}
