import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { Spin } from 'antd';
import { TextField } from '@material-ui/core';
import { useAdminState } from '../../../provider';
import { updateAppointmentdata } from '../../../api';
import Modal from '../../../../shared/components/Modal';
import styled from 'styled-components';
import { Button } from '../../../../shared/components/styled';
import {
    getQbenchacknowledgement,
    updateNoteQbenchacknowledgement,
} from '../../../../shared/providers/admin/api';

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 500px;
    max-width: 640px;
    float: right;
    margin: 30px 10px 15px;
    & > * {
        margin: 0 5px;
    }
`;
interface Props {
    setModalVisible: (value: boolean) => void;
    modalVisible?: boolean;
    handleModalConfirm: () => void;
    handleModalCancel: () => void;
    childComp?: React.ReactNode;
    proceedLogin?: boolean;
}

const CheckInModal: React.FC<Props> = ({
    modalVisible,
    setModalVisible,
    handleModalConfirm,
    handleModalCancel,
    childComp,
    proceedLogin,
}) => {
    const [notes, setNotes] = useState<String>('');
    const [isUpdating, setIsUpdating] = useState<boolean>(false);

    const { Editappointments, getPrintSampleLabels }: any = useAdminState();
    let selectRowdata: any = getPrintSampleLabels;

    const handleConfirm = async () => {
        if (Editappointments[0]?.slot !== undefined) {
            setIsUpdating(true);
            let params: any = {
                ...Editappointments[0],
                note: proceedLogin ? '' : notes,
            };
            await updateAppointmentdata(params);
            if (proceedLogin) {
                let paramsdata = {
                    id: null,
                    note: notes,
                    qbenchId: parseInt(Editappointments[0]?.qbenchId),
                    expireIn: (Editappointments[0]?.isExpressSameDayTest) ? 12 
                    : (!Editappointments[0]?.isExpressSameDayTest && !Editappointments[0]?.isRapidTest 
                        && !Editappointments[0]?.isAntigen) ? 24 : 0
                };
                await updateNoteQbenchacknowledgement(paramsdata);
            }
            updateNote();
            handleModalConfirm();
            setIsUpdating(false);
            setModalVisible(false);
        }
        setNotes('');
    };
    const onCloseModal = () => {
        if (Editappointments[0]?.slot !== undefined) {
            setModalVisible(false);
            handleModalCancel();
        }
    };
    const getNoteQbenchacknowledgement = async () => {
        try {
            await getQbenchacknowledgement({
                id: selectRowdata[0]?.sampleId,
            }).then(async (response: any) => {
                if (response.data) {
                    setNotes(
                        response.data[0]?.note ? response.data[0]?.note : ''
                    );
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    const updateNote = async () => {
        try {
            let params = {
                id: selectRowdata[0]?.sampleId,
                note: notes,
                qbenchId: null,
                // checkInTime: moment(new Date()).format('MM/DD/YYYY hh:mm A'),
                // checkInExpTime: (Editappointments[0]?.isExpressSameDayTest) ?
                // moment(new Date())
                //     .add(12, 'hours')
                //     .format('MM/DD/YYYY hh:mm A') :
                //  (!Editappointments[0]?.isExpressSameDayTest && !Editappointments[0]?.isRapidTest 
                //     && !Editappointments[0]?.isAntigen) ? 
                //     moment(new Date())
                //     .add(24, 'hours')
                //     .format('MM/DD/YYYY hh:mm A') : null,
                expireIn: (Editappointments[0]?.isExpressSameDayTest) ? 12 
                : (!Editappointments[0]?.isExpressSameDayTest && !Editappointments[0]?.isRapidTest 
                    && !Editappointments[0]?.isAntigen) ? 24 : 0

            };
            await updateNoteQbenchacknowledgement(params).then(() => {});
        } catch (error) {
            console.error(error);
        }
    };
    return (
        <Modal
            maxWidth={900}
            open={modalVisible ? modalVisible : false}
            onClose={onCloseModal}
            closeOnOverlayClick={false}
            closeOnEsc
        >
            <>
                <h2>
                    <b>Verification for Check In</b>
                </h2>
                <hr />
                {Editappointments !== null ? (
                    <>
                        {childComp}
                        <>
                            <br />
                            <div
                                style={{
                                    textAlign: 'center',
                                    margin: '0 1.5%',
                                }}
                            >
                                <div style={{ textAlign: 'left' }}>
                                    <strong>Notes</strong>
                                </div>
                                <TextField
                                    id="outlined-full-width"
                                    style={{ width: '100%', marginTop: '6px' }}
                                    placeholder="Notes"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => setNotes(e.target.value)}
                                />
                            </div>
                        </>
                        <ButtonsContainer>
                            <Button
                                width={0}
                                disabled={
                                    Editappointments === null || isUpdating
                                }
                                libraryType="default"
                                size="sm"
                                type="button"
                                onClick={onCloseModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                width={0}
                                disabled={
                                    Editappointments === null || isUpdating
                                }
                                onClick={() => handleConfirm()}
                                libraryType="primary"
                                size="sm"
                                type="button"
                            >
                                {isUpdating ? 'Proccessing...' : 'Confirm'}
                            </Button>
                        </ButtonsContainer>
                    </>
                ) : (
                    <>
                        <div style={{ textAlign: 'center' }}>
                            <h2>Processing...</h2>
                            <br />
                            <br />
                            <Spin size="large" />
                        </div>
                    </>
                )}
            </>
        </Modal>
    );
};

export default CheckInModal;
