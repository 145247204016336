import React, { useState, useMemo } from 'react';

import { rescheduleAppointment } from '../../../../shared/providers/manage/api';
import Schedule from '../../../../shared/components/Schedule';
import { AdminManagePage } from '../dictionaries';
import ManageAdminPaymentModal from "./ManageAdminPaymentModal";
import { cloneDeep } from 'lodash';
import DownGradeModal from '../../../../shared/components/DownGradeModal';
import { useAdminDispatch, useAdminState } from '../../../provider';

const Reschedule: React.FC = () => {
	const { manageAppointment, apptPricesAdmin, showStandradTestAdmin } = useAdminState();
	const { updateAdminManageAppointment, goToPage, toggleAdminLocationModal } = useAdminDispatch();
	const [loading, setLoading] = useState<boolean>();

	const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [checkappointment, setcheckappointment] = useState<{change:boolean,upgrade:boolean,previous:any,present:any}>({change:false,upgrade:false,previous:'',present:''})
	const [showModal, setShowModal] = useState(false);

	const rollbackisExpressSameDayTest = useMemo(() => cloneDeep(manageAppointment?.isExpressSameDayTest), [true]);
    const rollbackisRapidTest = useMemo(() => cloneDeep(manageAppointment?.isRapidTest), [true]);
    const rollbackisAntigenTest = useMemo(() => cloneDeep(manageAppointment?.isAntigen), [true]);

	if (!manageAppointment) {
		return null;
	}
    const checkEveryTime = ()=>{	  
        let oldTest: any = rollbackisExpressSameDayTest===true ? 2 : rollbackisRapidTest===true  ? 3 : rollbackisAntigenTest===true ? 0 :  1;
        let currentTest:any = manageAppointment?.isExpressSameDayTest===true ? 2 : manageAppointment.isRapidTest===true ? 3 : manageAppointment?.isAntigen===true ? 0 : 1;
        if(oldTest===currentTest)
        {
          setcheckappointment((previousstate)=>{return{
            change:false,
            upgrade:false,
            previous:'',
            present:''
          }})
        }
        else
        {
          let previous = oldTest===3?'Rapid':oldTest===2?'Express':oldTest===0?'Antigen':'Standard'
          let present = currentTest===3?'Rapid':currentTest===2?'Express':currentTest===0?'Antigen':'Standard'
          if(oldTest>currentTest)
            setcheckappointment((e)=>{return{change:true,upgrade:false,previous,present}})
          else
            setcheckappointment((e)=>{return{change:true,upgrade:true,previous,present}})
        }
        
      }
	const resheduleAppointmentHandler = async () => {
		setLoading(true);
		try {
			if (
				manageAppointment?.isExpressSameDayTest !== rollbackisExpressSameDayTest &&
				manageAppointment?.isExpressSameDayTest
			) {
				setShowPaymentModal(true);
			} else if (
				manageAppointment?.isExpressSameDayTest !== rollbackisExpressSameDayTest &&
				manageAppointment?.isExpressSameDayTest == false
			) {
				setShowModal(true);
			} else if (
				manageAppointment?.isRapidTest !== rollbackisRapidTest &&
				manageAppointment?.isRapidTest
			) {
				setShowPaymentModal(true);
			} else if (
				manageAppointment?.isRapidTest !== rollbackisRapidTest &&
				manageAppointment?.isRapidTest == false
			) {
				setShowModal(true);
			} else {
				let appointmentData: any = manageAppointment;
				delete appointmentData?.qbenchAcknowledgement;
				if(appointmentData?.crelioId) {
					goToPage(AdminManagePage.ManageRescheduleConfirm);
					setLoading(false);
				} else if (appointmentData?.qbenchId) {
					await rescheduleAppointment(appointmentData);
					goToPage(AdminManagePage.ManageRescheduleConfirm);
					setLoading(false);
				}
			}
		} catch (e) {
			setLoading(false);
		}
	};
	const gobacktothepage=()=>{
		goToPage(AdminManagePage.MangeResults)
	}

	return (
		<>
			<Schedule
				isManage={true}
				standardTest={showStandradTestAdmin}
				prices={apptPricesAdmin}
				title="Reschedule Your Appointment: Pick a date and time"
				onLocationChange={() => {
					toggleAdminLocationModal(true);
				}}
				onSchedule={() => resheduleAppointmentHandler()}
				onFieldUpdate={(update) =>
                    {
                        checkEveryTime();
                        updateAdminManageAppointment({ ...manageAppointment, ...update })
                    }
				}
				appointment={manageAppointment}
				confirmBtnText={checkappointment.change?"Continue Reschedule":'Confirm Reschedule'}
                loading={loading}
                RescheduleTestType={checkappointment}
				goBack={gobacktothepage}
			/>
			<ManageAdminPaymentModal
				showModal={showPaymentModal}
				closeModal={() => {
					setLoading(false);
					setShowPaymentModal(false);
				}}
			/>
			<DownGradeModal
				showModal={showModal}
				onCancel={() => {
					setShowModal(false);
					setLoading(false);
				}}
				title={"Note"}
				content={"Please call or email customer service at 213-948-2461 or support@worksitelabs.com to request for a refund for your downgrade to a Standard PCR Test"}
				onConfirm={async () => {
					setShowModal(false);
					let appointmentData: any = manageAppointment;
					delete appointmentData?.qbenchAcknowledgement;
					await rescheduleAppointment(appointmentData);
					goToPage(AdminManagePage.ManageRescheduleConfirm);
				}}
			/>
		</>
	);
};

export default Reschedule;
