export const isUSPhone = /^\s*(?:\+?(\d{1,3}))?[(]*(\d{3})[]*(\d{3})[]*(\d{4})(?: (\d+))?\s*$/;

export const isValidZIP = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

export const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const isValidDateFormat = /^\d{2}\/\d{2}\/\d{4}$/;

export const UrlValidation  = /^(?:\/dashboard\b)(?:\/DayWiseAppointments+)(?:\?Id=.+)$/

export const password = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/;

export const fullNameValidation = /^([a-zA-Z]{2,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/;