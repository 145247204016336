import { parseSnapshot } from '../../../../utils';
import { config } from '../../../../config';
import { firebase } from '../../../../firebase';
import { Slot } from './models';
import { Appointment } from '../../models';

import _, { includes } from "lodash";

const db = firebase.firestore();

export const subscribeOnAppointmentsUpdate = (
  { date, locationId, isRapidTest, isWalkUpTest }: { date: string; locationId: number, isRapidTest: boolean, isWalkUpTest?:boolean },
  onSnapshot: any
) => { 
    if (isWalkUpTest) {
        db.collection(config.firestoreCollections.schedulewalkup)
            .where('date', '==', date)
            .where('locationId', '==', locationId)
            .onSnapshot((snapshot) => onSnapshot(parseSnapshot(snapshot)));
    }
    else if (isRapidTest) {
      db
          .collection(config.firestoreCollections.schedulesrapid)
          .where('date', '==', date)
          .where('locationId', '==', locationId)
          .onSnapshot((snapshot) => onSnapshot(parseSnapshot(snapshot)));
  } else {
      db
          .collection(config.firestoreCollections.schedules)
          .where('date', '==', date)
          .where('locationId', '==', locationId)
          .onSnapshot((snapshot) => onSnapshot(parseSnapshot(snapshot)));
  }
}

export const reserveSlot = async (
  reserve: Slot,
  release: Slot | null,
  numberOfGuests: number,
  isRapidTest: boolean,
  reservationId?: string | null,
  isManage?: any,
  appointment?: any,
  isWalkUpTest?:boolean
): Promise<any> => {
  var appointments: any;
  if (isWalkUpTest)
    {
        appointments = await getWalkUpAppointmentsByDateAndLocation({
            date: reserve.date,
            locationId: reserve.locationId,
        });   
    }
    else if (isRapidTest) {
      appointments = await getRapidAppointmentsByDateAndLocation({
          date: reserve.date,
          locationId: reserve.locationId,
      });
  } else {
      appointments = await getAppointmentsByDateAndLocation({
          date: reserve.date,
          locationId: reserve.locationId,
      });
  }

  if (appointments) {
      const {
          data: { appointmentsPerPeriod },
          id,
      } = appointments;


      // if (appointmentsPerPeriod[reserve.period] === config.maxSlotsByPeriod) {
      //     throw new Error('No available slots for period');
      // }

      let newAppointments;
      if (isWalkUpTest) {
        newAppointments = appointmentsPerPeriod ? appointmentsPerPeriod + numberOfGuests : numberOfGuests;
      } else {
        newAppointments= {
            ...appointmentsPerPeriod,
            [reserve.period]:
                appointmentsPerPeriod[reserve.period] !== undefined
                    ? appointmentsPerPeriod[reserve.period] + numberOfGuests
                    : numberOfGuests,
        };   
      }
      if (release) {
          console.log("3", newAppointments);
          if (reserve.date === release.date) {
              if (isWalkUpTest) {
                  newAppointments = appointmentsPerPeriod ? appointmentsPerPeriod - numberOfGuests : 0;
              } else {
                  newAppointments[release.period] =
                      appointmentsPerPeriod[release.period] !== undefined
                          ? appointmentsPerPeriod[release.period] - numberOfGuests
                          : 0;
              }
          } else {
              releaseSlot(release, isRapidTest, isWalkUpTest);
          }

      }

      const createReserve: any = await firebase.functions().httpsCallable( isManage ? "createReschuleReservation" : "createReservation")({
          email: appointment?.email,
          period: reserve.period,
          numberOfGuests: numberOfGuests,
          locationId: reserve.locationId,
          paymentStatusCode: isManage ? 5 : 1,
          date: reserve?.date,
          reserveId: reservationId,
          isRapidTest: isRapidTest,
          testType: appointment.isExpressSameDayTest ? "express" : appointment.isRapidTest ? "rapid" :appointment?.isAntigen ? 'antigen': "standard",
          newAppointments,
          documentId: id,
          isWalkUpTest
      })

      if (createReserve !== "") {
          return createReserve
      }

      return null

  } else {

      if (release) {
          releaseSlot(release, isRapidTest,isWalkUpTest);
      }

      const createReserve: any = await firebase.functions().httpsCallable(isManage ? "createReschuleReservation" : "createReservation")({
              email: appointment?.email,
              period: reserve.period,
              numberOfGuests: numberOfGuests,
              paymentStatusCode: 1,
              locationId: reserve.locationId,
              date: reserve?.date,
              reserveId: reservationId,
              isRapidTest: isRapidTest,
              testType:appointment.isRapid?"rapid":appointment.isExpressSameDayTest ? "express" : appointment?.isAntigen ? 'antigen': "standard",
              newAppointments: "",
              documentId:"",
              isWalkUpTest
          })

      if (createReserve !== "") {
          return createReserve
      }
  }

  return null
};

export const releaseReservation = async (
  period: any,
  numberOfGuests: any,
  locationId: any,
  date: any,
  reservationId: any,
  isManage?: any,
  isRapidTest?: any
) =>
  firebase.functions().httpsCallable(isManage? "rescheduleReleaseReservation" : "releaseReservation")({
      email: "",
      period: period,
      numberOfGuests: numberOfGuests,
      locationId: locationId,
      paymentStatusCode: isManage ? 5 : 1,
      date: date,
      reserveId: reservationId,
      isManage: isManage,
      isRapidTest: isRapidTest
  })

export const releaseSlot = async (slot: Slot, isRapidTest?: boolean,isWalkUpTest?:boolean) => {
  var appointments: any;
  if (isWalkUpTest)
    {
        appointments = await getWalkUpAppointmentsByDateAndLocation({
            date: slot.date,
            locationId: slot.locationId,
        });   
    } else if (isRapidTest) {
    appointments = await getRapidAppointmentsByDateAndLocation({
      date: slot.date,
      locationId: slot.locationId,
    });
  } else {
    appointments = await getAppointmentsByDateAndLocation({
      date: slot.date,
      locationId: slot.locationId,
    });
  }

  if (appointments === null) {
    throw new Error('No appointments for period');
  }

  const {
    data: { appointmentsPerPeriod },
    id,
  } = appointments;
  if(isWalkUpTest){
    return db
    .collection(config.firestoreCollections.schedulewalkup)
    .doc(id)
    .update({
    appointmentsPerPeriod: {
        appointmentsPerPeriod: appointmentsPerPeriod? appointmentsPerPeriod - 1: 0,
    },
    });
  } else {
      return db
        .collection(config.firestoreCollections.schedules)
        .doc(id)
        .update({
          appointmentsPerPeriod: {
            ...appointmentsPerPeriod,
            [slot.period]: appointmentsPerPeriod[slot.period]
              ? appointmentsPerPeriod[slot.period] - 1
              : 0,
          },
        });
  }
};

export const updateSlot = async (
  reservedSlot: Slot,
  numberOfGuestsChange: number,
  isRapidTest?:boolean
): Promise<any> => {
  var appointments: any;
  if (isRapidTest) {
    appointments = await getRapidAppointmentsByDateAndLocation({
      date: reservedSlot.date,
      locationId: reservedSlot.locationId,
    });
  } else {
    appointments = await getAppointmentsByDateAndLocation({
      date: reservedSlot.date,
      locationId: reservedSlot.locationId,
    });
  }

  if (appointments) {
    const {
      data: { appointmentsPerPeriod },
      id,
    } = appointments;

    let maxAvailable: any = 16;

    if (!includes([142, 145], reservedSlot.locationId) && reservedSlot.period < 15 && !isRapidTest) {
      maxAvailable = 26;
    } else if (
      !includes([142, 145], reservedSlot.locationId) &&
      reservedSlot.period == 15 &&
      !isRapidTest
    ) {
      maxAvailable = 16;
    } else if(!isRapidTest) {
      maxAvailable = 10;
    }else{
      maxAvailable = 4;
    }

    if (appointmentsPerPeriod[reservedSlot.period] === maxAvailable) {
      throw new Error('No available slots for period');
    }

    const newAppointments = {
      ...appointmentsPerPeriod,
      [reservedSlot.period]:
        appointmentsPerPeriod[reservedSlot.period] !== undefined
          ? appointmentsPerPeriod[reservedSlot.period] + numberOfGuestsChange
          : numberOfGuestsChange,
    };

    if (isRapidTest) {
      return db
      .collection(config.firestoreCollections.schedulesrapid)
      .doc(id)
      .update({ appointmentsPerPeriod: newAppointments });
    } else {
      return db
      .collection(config.firestoreCollections.schedules)
      .doc(id)
      .update({ appointmentsPerPeriod: newAppointments });
    }

    
  }
};

export const createPaymentIntent = async ({
  amount,
  currency,
  metadata,
  description,
}: {
  amount: number;
  currency: string;
  metadata: object;
  description: string;
}) =>
  firebase.functions().httpsCallable('createPaymentIntent')({
    amount,
    currency,
    metadata,
    description,
  });

// firebase.functions().httpsCallable('createAppointment')(appointment);
export const createAppointment = async (appointment: Appointment) =>
  firebase.functions().httpsCallable('createAppointmentDatailswithTask')(
    appointment
  );

export const getAppointmentsByDateAndLocation = ({
  date,
  locationId,
}: {
  date: string;
  locationId: number;
}) =>
  firebase
    .firestore()
    .collection(config.firestoreCollections.schedules)
    .where('date', '==', date)
    .where('locationId', '==', locationId)
    .get()
    .then(parseSnapshot);

export const getWalkUpAppointmentsByDateAndLocation = ({
    date,
    locationId,
    }: {
    date: string;
    locationId: number;
    }) =>
    firebase
        .firestore()
        .collection(config.firestoreCollections.schedulewalkup)
        .where('date', '==', date)
        .where('locationId', '==', locationId)
        .get()
    .then(parseSnapshot);

export const getRapidAppointmentsByDateAndLocation = ({
  date,
  locationId,
}: {
  date: string;
  locationId: number;
}) =>
  firebase
    .firestore()
    .collection(config.firestoreCollections.schedulesrapid)
    .where('date', '==', date)
    .where('locationId', '==', locationId)
    .get()
    .then(parseSnapshot);

export const cancelAppointment = async (id: string) =>
  firebase.functions().httpsCallable('cancelAppointment')(id);
export const deleteNpDest = async (params: any) =>
    firebase.functions().httpsCallable('deleteNpDest')(params);
export const getPrices = async () =>
  firebase.functions().httpsCallable('getPrices')();

export const resetSchedules = async () => {
  try {
    const appointmentSnapShot = await firebase
      .firestore()
      .collection('appointments')
      .get();
    const appointments = appointmentSnapShot.docs.map((doc: any) => doc.data());

    let updatedSchedule: any = {};
    appointments.forEach((appointment: any) => {
      const key = `${appointment.slot.date}-${appointment.slot.locationId}`;
      let updatedValue: any = null;
      if (
        updatedSchedule[key] &&
        updatedSchedule[key][`${appointment.slot.period}`]
      ) {
        updatedValue = {
          ...updatedSchedule[key],
          [`${appointment.slot.period}`]:
            updatedSchedule[key][`${appointment.slot.period}`] + 1,
        };
      } else {
        updatedValue = {
          ...updatedSchedule[key],
          [`${appointment.slot.period}`]: 1,
        };
      }

      updatedSchedule = {
        ...updatedSchedule,
        [key]: updatedValue,
      };
    });

    const snaps = await firebase.firestore().collection('schedules').get();

    await Promise.all(snaps.docs.map((doc) => doc.ref.delete()));

    Object.keys(updatedSchedule).forEach((key: string) => {
      const date = key.split('-')[0];
      const locationId = key.split('-')[1];

      firebase
        .firestore()
        .collection('schedules')
        .where('date', '==', date)
        .where('locationId', '==', Number(locationId))
        .get()
        .then((snap: any) => {
          if (snap.docs.length > 0) {
            snap.docs.forEach((doc: any) => {
              const id = doc.id;
              firebase
                .firestore()
                .collection('schedules')
                .doc(id)
                .set({
                  appointmentsPerPeriod: updatedSchedule[key],
                  date: date,
                  locationId: Number(locationId),
                });
            });
          } else {
            firebase
              .firestore()
              .collection('schedules')
              .add({
                appointmentsPerPeriod: updatedSchedule[key],
                date: date,
                locationId: Number(locationId),
              });
          }
        })
        .catch((err) => console.log);
    });
  } catch (err) {
    console.log('firebase reset schedule error: ', err);
  }
};

export const getPostalCodeDetails = async (postalCode: string) => {
  try {
    const SnapShot = await firebase
      .firestore()
      .collection(config.firestoreCollections.postalcodes)
      .where('ZipCode', '==', _.toInteger(postalCode))
      .get();
    const postalCodeDetails = SnapShot.docs.map((doc: any) => doc.data());
    if (postalCodeDetails.length > 0) {
      return postalCodeDetails;
    } else {
      return [];
    }
  } catch (error) {
    console.log('firebase reset schedule error: ', error);
  }
};

export const updatePayment = async (
    reservationId: string | null,
    paymentStatusCode: any
) =>
    firebase.functions().httpsCallable("updatePayment")({
        reservationId,
        paymentStatusCode: paymentStatusCode
    })
	
	
	export const checkReservationValidity = async (
    reservationId: any,
) => {
    try {
        return firebase.functions().httpsCallable('checkReservationValidity')({
            reservationId: reservationId,
        });
    } catch (error) {
        return {
            "status": 400,
            "message": "failed",
            "reservationId": ""
        }
    }
}

export const getNewSlots = async (params: any) => {
  return firebase
    .functions()
    .httpsCallable('getNewSlots')(params);
}