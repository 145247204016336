import React from 'react';
import styled from 'styled-components';
import { useAdminState } from '../../provider';
import Editlocation from '../LocationAndService/EditLocation';
import Holidays from '../LocationAndService/Holidays';
import Listing from '../LocationAndService/Listing';
import ListInventory from './ListInventory';
import ManageInventory from './ManageInventory';




interface WrapperProps {
    backgroundColor: number;
  }

  export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: auto;
  background: ${({
  backgroundColor,
}: WrapperProps) =>
    backgroundColor === 1 
      ? '#FFFFFF'
      : '#FFFFFF'};
  & > * {
    &:nth-child(2) {
      flex-grow: 1;
    }
  }
`;


export const steps = [
  ListInventory,
  ManageInventory,
];

function Inventorystep() {
  const { step } = useAdminState();
  const CurrentStep = steps[step];


  return (
    <Wrapper backgroundColor={step} >
      <div>
        <CurrentStep />
      </div>
    </Wrapper>
  );
}

export default Inventorystep;