import AppointmentList from "../AppointmentList/AppointmentList";
import Npdestinations from '../NpDestination/Npdestinations';
import CustomerList from "../AppointmentList/Customer";
import QbenchResultsList from "../AppointmentList/QbenchResultsList";

import SlotOccupany from "../AppointmentList/slotoccupany";
import CountyReport from "../AppointmentList/CountyReport";
import WizardReport from "../AppointmentList/WizardReport";

import Dashboard from "../AppointmentList/Dashboard";
import KeyMetrics from "../AppointmentList/KeyMetrics";

import Report from "../../../shared/components/Report";
import ConsolidationReport from "../../../shared/components/ConsolidationReport";
import InsertInvitation from "@material-ui/icons/InsertInvitation";
import PinDropIcon from '@material-ui/icons/PinDrop';
import DashboardIcon from "@material-ui/icons/Dashboard";
import ReportIcon from "@material-ui/icons/InsertChart";
import ChangeMobileNumber from "../../page/UserInfoUpdate/ChangeMobileNumber";
import AdminManage from "../../page/Manage/index";
import EmpTesting from "../../page/EmployeeTesting/EmployeeTesting";
import  InsuranceReport  from "../../../shared/components/InsuranceReport";
import DiscountReport from "../../../shared/components/DiscountReport";
import InsuranceAndStripeRevenue from "../../../shared/components/InsuranceAndStripeRevenue";
import InsuranceAndStripeRevenueCount from "../../../shared/components/InsuranceAndStripeRevenueCount";
import InventoryWrapper from "../../../admin/page/InventoryTracker/InventoryTrackerWrapper";
import LocationWrapper from "../../page/LocationAndService/LocationWrapper";
import UserRolesForm from '../../page/UserInfoUpdate/UserRolesForm';
import UserRoles from '../../page/UserInfoUpdate/UserRoles';
import RolesAccessForm from "../../page/UserInfoUpdate/RolesAccessForm.js";
import SchedulerLite from "../../page/SchedularLite";
import ConfigureSchedular from "../../page/SchedularLite/ConfigureSchedular";
import WeeklyTestingAdministrator from "../../page/WeeklyTestingAdministrator";
import StripeDashboard from "../../page/StripeDashboard/StripeDashboard";
import InvoiceReport from "../../../shared/components/InvoiceReport";

const dashboardRoutes = [
  {
    path: "/Home",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/dashboard",
    display:true
  },
  {
    path: "/stripeDashboard",
    name: "Revenue Dashboard",
    icon: DashboardIcon,
    component: StripeDashboard,
    layout: "/dashboard",
    display: true
  },
  {
    path: "/KeyMetrics",
    name: "Key Metrics",
    icon: ReportIcon,
    component: KeyMetrics,
    layout: "/dashboard",
    display:true
  },
  {
    path: "/DayWiseAppointments",
    name: "Day Wise Appointments",
    icon: InsertInvitation,
    component: AppointmentList,
    layout: "/dashboard",
    display:true
  },
  {
    path: "/customer",
    name: "Customers",
    icon: InsertInvitation,
    component: CustomerList,
    layout: "/dashboard",
    display:true
  },
  {
    path: "/schedulerlite",
    name: "Scheduler Lite",
    icon: InsertInvitation,
    component: SchedulerLite,
    layout: "/dashboard",
    display: true
  },
  {
    path: "/weeklyTestingLocationAdmin",
    name: "Special Day Wise Users",
    icon: InsertInvitation,
    component: WeeklyTestingAdministrator,
    layout: "/dashboard",
    display: true
  },
  {
    path: "/npdestions",
    name: "NP Destinations",
    icon: PinDropIcon, 
    component: Npdestinations,
    layout: "/dashboard",
    display: true
  },
  {
    path: "/employeeTesting",
    name: "Employee Testing",
    icon: InsertInvitation,
    component: EmpTesting,
    layout: "/dashboard",
    display:true
  },
  {
    path: "/inventoryTracker",
    name: "Inventory Tracker",
    icon: InsertInvitation, 
    component: InventoryWrapper,
    layout: "/dashboard",
    display: true
  },
  {
    path: "/weeklyreport",
    name: "Weekly Report",
    icon: InsertInvitation,
    component: Report,
    layout: "/dashboard",
    display:false
  },
  {
    path: "/result",
    name: "Results",
    icon: InsertInvitation,
    component: QbenchResultsList,
    layout: "/dashboard",
    display:true
  },
  {
    path: "/slotoccupany",
    name: "Slot Occupancy",
    icon: InsertInvitation,
    component: SlotOccupany,
    layout: "/dashboard",
    display:true
  },
  {
    path: "/consolidationreport",
    name: "Consolidation Report",
    icon: InsertInvitation,
    component: ConsolidationReport,
    layout: "/dashboard",
    display:false
  },
  {
    path: "/countyreport",
    name: "County Report",
    icon: InsertInvitation,
    component: CountyReport,
    layout: "/dashboard",
    display:false
  },
  {
    path: "/manage",
    name: "Manage",
    icon: InsertInvitation,
    component: AdminManage,
    layout: "/dashboard",
    display:true
  },
  {
    path: "/report",
    name: "Reports",
    icon: InsertInvitation,
    component: WizardReport,
    layout: "/dashboard",
    display:true
  },
  {
    path: "/locations",
    name: "Location",
    icon: InsertInvitation, 
    component: LocationWrapper,
    layout: "/dashboard",
    display: true
  },
  {
    path: "/insuranceReport",
    name: "Services",
    icon: InsertInvitation,
    component: InsuranceReport,
    layout: "/dashboard",
    display: false
  },
  {
    path: "/discountreport",
    name: "Services",
    icon: InsertInvitation,
    component: DiscountReport,
    layout: "/dashboard",
    display: false
  },
  {
    path: "/userinfo",
    name: "UserInfo",
    icon: InsertInvitation,
    component: ChangeMobileNumber,
    layout: "/dashboard",
    display:true
  },
  {
    path: '/insuranceAndStripeRevenue',
    name: 'Services',
    icon: InsertInvitation,
    component: InsuranceAndStripeRevenue,
    layout: '/dashboard',
    display: false,
  },
  {
    path: '/insuranceAndStripeRevenueCount',
    name: 'Services',
    icon: InsertInvitation,
    component: InsuranceAndStripeRevenueCount,
    layout: '/dashboard',
    display: false,
  },
  {
    path: "/Users",
    name: "Users",
    icon: InsertInvitation,
    component: UserRoles,
    layout: "/dashboard",
    display: true
  },
  {
    path: "/UserRolesForm",
    name: "User Roles Form",
    icon: InsertInvitation,
    component: UserRolesForm,
    layout: "/dashboard",
    display: false
  },
  {
    path: "/RolesAccessForm",
    name: "Roles Access Form",
    icon: InsertInvitation,
    component: RolesAccessForm,
    layout: "/dashboard",
    display: false
  },
  {
    path: "/configureSchedularLite",
    name: "Roles Access Form",
    icon: InsertInvitation,
    component: ConfigureSchedular,
    layout: "/dashboard",
    display: false
  },
  {
    path: "/InvoiceReport",
    name: "Invoice Report",
    icon: InsertInvitation,
    component: InvoiceReport,
    layout: "/dashboard",
    display: false
  },
];

export default dashboardRoutes;
