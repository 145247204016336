import React, { useState, useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import styled from 'styled-components';
// import { parse, isValid } from 'date-fns';
import { FieldValidator, Field, FieldProps } from 'formik';

import chevronRight from '../../../assets/chevron-right.svg';
import chevronLeft from '../../../assets/chevron-left.svg';
import calendar from '../../../assets/calendar.svg';
import { colors } from '../../../styles/colors';
import { StyledInput } from './form/Input';
import { ErrorText } from './styled';
import { config } from '../../../config';
import { includes } from 'lodash';
import { parse, isValid, format } from 'date-fns';
import { hexToRGB, weekAndWeekDayConvertion } from '../../../utils';
import MaskedInput from 'react-maskedinput';
// import { Portal } from "react-overlays";

// const CalendarContainer = ({ children }: any) => {
// 	const el = document.getElementById("calendar-portal");

// 	return <Portal container={el}>{children}</Portal>;
// };

const Container = styled.div`
    &.react-datepicker-inline {
        .react-datepicker {
            border: 0;
        }
    }

    .react-datepicker-popper[data-placement^='bottom']
        .react-datepicker__triangle {
        border-bottom-color: ${colors.white};
    }

    .react-datepicker {
        font-weight: normal;
        line-height: 1.375;
        border-radius: 0;
    }

    .react-datepicker-popper[data-placement^='bottom']
        .react-datepicker__triangle,
    .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow,
    .react-datepicker-popper[data-placement^='bottom']
        .react-datepicker__triangle::before,
    .react-datepicker-popper[data-placement^='top']
        .react-datepicker__triangle::before,
    .react-datepicker__year-read-view--down-arrow::before,
    .react-datepicker__month-read-view--down-arrow::before,
    .react-datepicker__month-year-read-view--down-arrow::before {
        width: 0;
    }

    .react-datepicker__triangle {
        border-top-color: ${colors.white};
    }

    .react-datepicker__header {
        background-color: transparent;
        border-bottom: 0;
        padding-top: 11px;
    }

    .react-datepicker__navigation--previous,
    .react-datepicker__navigation--next {
        width: 28px;
        height: 28px;
        top: 7px;
        border: 0;
    }

    .react-datepicker__current-month {
        margin-bottom: 6px;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
        font-weight: normal;
    }

    .react-datepicker__navigation--previous {
        border-right-color: transparent;
        background: url(${chevronLeft}) no-repeat;
    }

    .react-datepicker__navigation--next {
        border-left-color: transparent;
        background: url(${chevronRight}) no-repeat;
    }

    .react-datepicker__day-names {
        font-size: 10px;
        text-transform: uppercase;
        font-weight: bold;
    }

    .react-datepicker__day--selected {
        border-radius: 100%;
        background-color: ${colors.blue};
    }

    .react-datepicker__day {
        &:hover {
            border-radius: 100%;
        }

        &:focus {
            outline: none;
        }
    }

    .react-datepicker__day--keyboard-selected {
        border-radius: 100%;
        background-color: transparent;
        color: #000;
    }
`;

const DatePickerWrapper = styled.div`
    .react-datepicker-wrapper {
        width: 100%;
    }
`;

const InputContainer = styled.div`
    position: relative;
    margin-bottom: 35px;

    &:after {
        position: absolute;
        display: block;
        content: '';
        width: 40px;
        height: 40px;
        right: 10px;
        opacity: 0.5;
        top: 8px;
        background-image: url(${calendar});
        background-size: cover;
    }
    .masked-input {
        border: 1px solid ${colors.grey};
        border-radius: 10px;
        font-size: 16px;
        line-height: 24px;
        padding: 15px 28px;
        transition: all 0.15s ease-in-out;
        width: 100%;

        &:focus,
        &:active {
            border: 1px solid ${colors.blueLight};
            box-shadow: 0 0 4px ${hexToRGB(colors.blueLight, 0.8)};
        }
    }
`;

const Error = styled.div`
    position: absolute;
    left: 6px;
    top: 58px;
`;

interface Props {
    onChange: (date: Date) => void;
    value: Date;
    inline?: boolean;
    customInput?: React.ReactNode;
    maxDate?: Date;
    minDate?: Date;
    showTimeSelect?: boolean;
    disabled?: boolean;
    isDayFilter?: boolean;
    isblockOutDays?: any;
    isWeeklyFlag?: boolean;
    weeklyVisible?: any;
    location?: any;
}

const Datepicker: React.FC<Props> = ({
    onChange,
    value,
    inline,
    customInput,
    maxDate,
    minDate,
    showTimeSelect,
    disabled,
    isDayFilter,
    isblockOutDays,
    isWeeklyFlag,
    weeklyVisible,
    location,
}) => {
    if (
        location?.qbenchCustomerId !==
            config?.qbenchCustomerId?.SFOAirport_BARTStation &&
        location?.qbenchCustomerId !== config?.qbenchCustomerId?.SFO_Airport &&
        location?.qbenchCustomerId !== config?.qbenchCustomerId?.SanFrancisco &&
        location?.qbenchCustomerId !==
            config?.qbenchCustomerId?.SanFransiscoDownTown
    ) {
        isblockOutDays?.push(
            ...['11/24/2022', '11/25/2022', '12/26/2022', '01/02/2023']
        );
    }
    isblockOutDays = isblockOutDays?.map((e: any) => new Date(e));
    const isWeekday = (date: any) => {
        let day: any = new Date(date).getDay();
        let validDay: any = true;
        let isaddOutDays: any =
            location !== undefined && location?.cPartnerID === 'SEQ001'
                ? includes(
                      [config.qbenchCustomerId.PostIsolationRapidAntigenTest],
                      location?.qbenchCustomerId
                  )
                    ? ['02/25/2022']
                    : includes(
                          [config.qbenchCustomerId.SequoiaHighSchool],
                          location?.qbenchCustomerId
                      )
                    ? [
                          '09/05/2022',
                          '12/29/2022',
                          '01/05/2023',
                          '02/23/2023',
                          '04/13/2023',
                      ]
                    : // : includes([config.qbenchCustomerId.TIDEAcademy_LowerTIDE], location?.qbenchCustomerId)
                    //     ? ["01/19/2022"]
                    includes(
                          [config.qbenchCustomerId.WoodSideHighSchool],
                          location?.qbenchCustomerId
                      )
                    ? ['04/29/2022', '05/06/2022']
                    : includes([config.qbenchCustomerId.MenloAthertonHighSchool], location?.qbenchCustomerId)
                    ? ['04/19/2023']
                    : includes([config.qbenchCustomerId.CarlmontHighSchool], location?.qbenchCustomerId)
                        ? ['04/19/2023'] 
                    : []
                : location?.cPartnerID === 'KEN001'
                ? includes(
                      [config.qbenchCustomerId.KernHealthSystems],
                      location?.qbenchCustomerId
                  )
                    ? ['09/06/2022']
                    : []
                : [];
        let blockOutDays: any = includes(
            isblockOutDays,
            format(new Date(date), config.dateFormat)
        );
        let additionalOutDays: any = includes(
            isaddOutDays,
            format(new Date(date), config.dateFormat)
        );
        if (isWeeklyFlag !== undefined && isWeeklyFlag) {
            let addDay: any = weekAndWeekDayConvertion({
                weeklyVisible: weeklyVisible,
                date: date,
            });

            if (isDayFilter) {
                if (new Date(date).getDate() === new Date(addDay).getDate()) {
                    validDay = true;
                } else if (additionalOutDays) {
                    validDay = true;
                } else {
                    validDay = false;
                }
            } else {
                if (includes(weeklyVisible.weeklyVisibleDays, day)) {
                    validDay = true;
                } else if (additionalOutDays) {
                    validDay = true;
                } else {
                    validDay = false;
                }
            }
        } else if (blockOutDays !== undefined && blockOutDays) {
            validDay = false;
        } else if (additionalOutDays) {
            validDay = true;
        } else {
            validDay = true;
        }

        return validDay;
    };
    let endDate: any = '';
    let maximumDate = maxDate;
    if (location?.qbenchCustomerId === 12) {
        endDate = new Date('04/23/2022');
    }
    if (endDate !== '') {
        maximumDate = endDate;
    } else {
        maximumDate = maxDate;
    }
    if (location?.qbenchCustomerId === config.qbenchCustomerId.Austin) {
        maximumDate = new Date('12/30/2022');
    }
    if (location?.qbenchCustomerId === config.qbenchCustomerId.LongBeach) {
        maximumDate = new Date('01/13/2023');
    }
    if (location?.qbenchCustomerId === config.qbenchCustomerId.Portland) {
        maximumDate = new Date('01/13/2023');
    }
    if (
        location?.qbenchCustomerId ===
        config.qbenchCustomerId.SanFransiscoDownTown
    ) {
        maximumDate = new Date('10/02/2022');
    }
    if (
        location?.qbenchCustomerId === config.qbenchCustomerId.SanDiagoVallyPark
    ) {
        maximumDate = new Date('11/19/2022');
    }
    if (
        location?.qbenchCustomerId === config.qbenchCustomerId.ArroyoUpperSchool
    ) {
        maximumDate = new Date('10/05/2022');
    }
    if (location?.qbenchCustomerId === config.qbenchCustomerId.ProudBird) {
        maximumDate = new Date('11/19/2022');
    }
    if (location?.qbenchCustomerId === config.qbenchCustomerId.BoysGirlsClub) {
        maximumDate = new Date('10/30/2022');
    }
    if (
        location?.qbenchCustomerId ===
        config.qbenchCustomerId.LasVegas_TheOrleans
    ) {
        maximumDate = new Date('12/21/2022');
    }
		if (
        location?.qbenchCustomerId === config.qbenchCustomerId.BadenAdultSchool
    ) {
        maximumDate = new Date('03/15/2023');
    }
    if (location?.qbenchCustomerId === config.qbenchCustomerId.SFO_Airport) {
        maximumDate = new Date('04/30/2023');
    }
    if (location?.qbenchCustomerId === config.qbenchCustomerId.seattleAngleLake) {
        maximumDate = new Date('05/01/2023');
    }
    if(location?.qbenchCustomerId === config.qbenchCustomerId.SFOAirport_BARTStation){
        maximumDate = new Date('06/09/2023')
    }
    if(location?.qbenchCustomerId === config.qbenchCustomerId.RalstonMiddleSchool){
        maximumDate = new Date('06/09/2023')
    }
    if (
         location?.qbenchCustomerId ===
         config.qbenchCustomerId.CarlmontHighSchool
     ) {
         maximumDate = new Date('06/30/2023');
     }

     if (
         location?.qbenchCustomerId ===
         config.qbenchCustomerId.SequoiaHighSchool
     ) {
         maximumDate = new Date('06/30/2023');
     }

     if (
         location?.qbenchCustomerId ===
         config.qbenchCustomerId.WoodSideHighSchool
     ) {
         maximumDate = new Date('06/30/2023');
     }

     if (
         location?.qbenchCustomerId ===
         config.qbenchCustomerId.MenloAthertonHighSchool
     ) {
         maximumDate = new Date('06/30/2023');
     }
	return (
        <Container className={inline ? 'react-datepicker-inline' : ''}>
            <ReactDatePicker
                excludeDates={isblockOutDays}
                selected={value}
                onChange={onChange}
                dateFormat={
                    showTimeSelect ? config.dateTimeFormat : config.dateFormat
                }
                inline={inline}
                maxDate={maximumDate}
                minDate={minDate}
                showTimeSelect={showTimeSelect}
                formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
                customInput={customInput}
                disabled={disabled}
                filterDate={isWeekday}
                popperModifiers={{
                    // preventOverflow: {
                    //   enabled: true,
                    // },
                    flip: {
                        behavior: ['bottom'], // don't allow it to flip to be above
                    },
                    preventOverflow: {
                        enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                    },
                    hide: {
                        enabled: false, // turn off since needs preventOverflow to be enabled
                    },
                }}
            />
        </Container>
    );
};

interface DatepickerFieldProps {
    name: string;
    onChange?: (date: Date) => void;
    onChangeInput?: (date: Event) => void;
    isRequired?: boolean;
    validate?: FieldValidator;
    maxDate?: Date;
    minDate?: Date;
    showTimeSelect?: boolean;
    inputPlaceholder?: string;
    disabled?: boolean;
    dateAndTime?: boolean;
}

const CustomInput = ({
    value,
    onChange,
    onChangeInput,
    hasError,
    onClick,
    error,
    inputPlaceholder,
    disabled,
    dateAndTime,
}: any) => {
    const [draft, setDraft] = useState(value);
    const [focused, setFocused] = useState(false);

    useEffect(() => {
        if (!focused) {
            setDraft(value);
        }
    }, [focused, value]);

    return (
        <InputContainer>
            {dateAndTime ? (
                <StyledInput
                    hasError={hasError}
                    disabled={disabled}
                    onChange={(e) => {
                        if (onChangeInput) {
                            onChangeInput(e);
                        }

                        setDraft(e.target.value);

                        const parsedDate = parse(
                            e.target.value,
                            config.dateFormat,
                            new Date()
                        );

                        if (isValid(parsedDate)) {
                            onChange(e);
                        }
                    }}
                    onFocus={() => setFocused(true)}
                    onBlur={() => {
                        setFocused(false);
                        setDraft(value);
                    }}
                    value={draft}
                    placeholder={inputPlaceholder}
                    onClick={onClick}
                />
            ) : (
                <MaskedInput
                    className="masked-input"
                    disabled={disabled}
                    onChange={(e) => {
                        if (onChangeInput) {
                            onChangeInput(e);
                        }

                        setDraft(e.target.value);

                        const parsedDate = parse(
                            e.target.value,
                            config.dateFormat,
                            new Date()
                        );

                        if (isValid(parsedDate)) {
                            onChange(e);
                        }
                    }}
                    onFocus={() => setFocused(true)}
                    onBlur={() => {
                        setFocused(false);
                        setDraft(value);
                    }}
                    value={draft}
                    placeholder={inputPlaceholder}
                    onClick={onClick}
                    mask="11/11/1111"
                />
            )}

            <Error>
                <ErrorText hasError={hasError}>{error}</ErrorText>
            </Error>
        </InputContainer>
    );
};

export const DatepickerField: React.FC<DatepickerFieldProps> = ({
    name,
    onChange,
    onChangeInput,
    isRequired,
    validate,
    maxDate,
    minDate,
    showTimeSelect,
    inputPlaceholder,
    disabled,
    dateAndTime,
}) => {
    return (
        <Field
            name={name}
            validate={(value: Date | null) => {
                let error;

                if (validate) {
                    error = validate(value);
                }

                if (isRequired && !value) {
                    error = 'Required field';
                }

                return error;
            }}
        >
            {({
                field,
                form: { setFieldValue },
                meta: { error, touched },
            }: FieldProps) => {
                const hasError = touched && error !== undefined;

                return (
                    <DatePickerWrapper>
                        <Datepicker
                            onChange={(value: Date) => {
                                setFieldValue(name, value);

                                if (onChange) {
                                    onChange(value);
                                }
                            }}
                            disabled={disabled}
                            value={field.value}
                            minDate={minDate}
                            maxDate={maxDate}
                            showTimeSelect={showTimeSelect}
                            customInput={
                                <CustomInput
                                    onChangeInput={onChangeInput}
                                    hasError={hasError}
                                    error={error}
                                    inputPlaceholder={inputPlaceholder}
                                    disabled={disabled}
                                    dateAndTime={dateAndTime}
                                />
                            }
                        />
                    </DatePickerWrapper>
                );
            }}
        </Field>
    );
};

export default Datepicker;
