import * as React from 'react';
import { Form, Select } from 'antd';
import { format, isBefore, isEqual } from 'date-fns';
import AntDDatePicker from './AntDDatePicker';
import Loader from './Loader';
import Button from '@material-ui/core/Button';
import CardHeader from '../../admin/components/AppointmentList/Card/CardHeader';
import CardBody from '../../admin/components/AppointmentList/Card/CardBody';
import Card from '../../admin/components/AppointmentList/Card/Card.js';
import { Box, createStyles, LinearProgress, Theme, Typography } from '@material-ui/core';
import { getInsuranceAndStripeByDateLocation, getPaymentIntentId } from '../../admin/api';
import { config } from '../../../config';
import { useSharedState } from '../provider';
import { generateInsuranceAndStripeReport, getAllOpenedLocations } from '../api';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/styles';
function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const { Option } = Select;
const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 20,
      borderRadius: 5,
      width:'400px',
      marginLeft:'auto',
      marginRight:'auto'
    },
    colorPrimary: {
      backgroundColor: "#e0e0e0",
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#1a90ff',
    },
  }),
)(LinearProgress);

export interface InsuranceAndStripeRevenuetProps {}
export default function InsuranceAndStripeRevenue(
    props: InsuranceAndStripeRevenuetProps
) {
    const [locations, setLocations] = React.useState<Location[] | null>(null);
    React.useEffect(() => {
      getAllOpenedLocations().then((loc) => {
          setLocations(loc.filter((e:any)=>e?.isOpened !== false || e?.isOpened === false)?.filter((v:any,i:any,a:any)=>a.findIndex((v2:any)=>(v2.qbenchCustomerId===v.qbenchCustomerId))===i));
      });
    }, []);
    const [selectedFromDate, setSelectedFromDate] = React.useState(
        format(new Date(), config.dateFormat)
    );
    const [selectedToDate, setSelectedToDate] = React.useState(
        format(new Date(), config.dateFormat)
    );
    const [loader, setloader] = React.useState(false);
    const [listofdata, setlistofdata] = React.useState<[]>([]);
    const [searched, setsearched] = React.useState(false);
    const [locationId, setLocationId] = React.useState<any>(0);
    const [searchBy, setSearchBy] = React.useState<string>('createdDate');
    const [downloadProgresss, setdownloadProgresss] = React.useState<any>('')
    const [alertbox, setalertbox] = React.useState({open: false,type: '',msg: '',});
    const handleselectionfromdate = (date: any) => {
        var toDates = date;
        setSelectedFromDate(format(toDates, config.dateFormat));
        
    };
    const handleselectiontodate = (date: any) => {
        var toDates = date;
        setSelectedToDate(format(toDates, config.dateFormat));
    };
    let finalDatalength:any=[];
    const fetchdata:any = async(data:any,count:number,finalData:any,totalCount:number)=>{ 
        if (count>=data.length)
        {
            return finalData;
        }
        await getPaymentIntentId({id:data[count]}).then(async(result:any)=>{
            finalData.push(...result.data)
            finalDatalength.push(...result.data)
            setdownloadProgresss((e:any)=>{ 
                if(e<Math.round((finalDatalength.length/totalCount)*100))
                    return Math.round((finalDatalength.length/totalCount)*100)
                else
                    return e;
            });
            await fetchdata(data,count+1,finalData,totalCount);
        }).catch(async(e)=>{
            await fetchdata(data,count+1,finalData,totalCount);
        })
        return finalData;
    }
    const getSplitArray = (data:any)=>{
        let i=0;
        let finalarr:any[] = []
        let singlearr:any[] = []
        data.map(async(e:any)=>{
            if(i<90)
            {
                singlearr.push(e);
                i++;
            }
            else
            {
                finalarr.push(singlearr)
                singlearr=[]
                singlearr.push(e)
                i=1;
            } 
        })
        if(singlearr.length>0)
            finalarr.push(singlearr);
        return finalarr
    }
    const convertPricing = async(data:any)=>{
        let Stripe:any[]=[];
        let insurance:any[]=[];
        data.map(async(e:any)=>{
            if(e.paymentIntentId.length>=20)
            {
                Stripe.push(e);
            }
            else if(e.hasInsurance===true)
            {
                insurance.push(e)
            }
        })
        const SplitArr = getSplitArray(Stripe);
        setdownloadProgresss(0);
        let newData:any = await fetchdata(SplitArr,0,[],Stripe.length);
        setTimeout(() => {setdownloadProgresss('')}, 2000);
        let newdata = newData.concat(insurance)
        setlistofdata(newdata);        
        generateInsuranceAndStripeReport(
            newdata
        );
    }
    const handlesearch = async (e: any) => {
        if (
            selectedFromDate !== null &&
            selectedToDate !== null &&
            selectedFromDate !== undefined &&
            selectedToDate !== undefined 
            &&
            locationId !== 0
        ) {
            if(isBefore(new Date(selectedFromDate),new Date(selectedToDate))||isEqual(new Date(selectedFromDate),new Date(selectedToDate)))
            {
                setloader(true);
                const params = {
                    startDate: selectedFromDate,
                    endDate: selectedToDate,
                    locationId: locationId ==='all' ? null: Number(locationId),
                    searchBy:searchBy
                };
                const insuranceAndStripeRevenueData = await getInsuranceAndStripeByDateLocation(
                    params
                );
                if (insuranceAndStripeRevenueData?.data?.length>=0) {
                    let newdata = insuranceAndStripeRevenueData.data.map((e:any)=>e)
                    setloader(false);
                    console.log('newdata', newdata);
                    setlistofdata(newdata);
                    setsearched(true);

                }
                else if(insuranceAndStripeRevenueData?.data?.status===400)
                {
                    setsearched(false);
                    setloader(false);
                    setalertbox({open:true,type:'Error',msg:insuranceAndStripeRevenueData?.data?.message});
                }   
            }
            else
            {
                setsearched(false);
                setloader(false);
                setalertbox({open:true,type:'warning',msg:'From Date - To Date is Not Correct'})
            }
        }
        else
        {
            setalertbox({open:true,type:'warning',msg:'All Field Required'})
        }
    };

    React.useEffect(() => {
        var todate = new Date();
        var todateselect = new Date(todate.setDate(todate.getDate()));
        setSelectedToDate(format(todateselect, config.dateFormat));
        setSelectedFromDate(format(new Date(), config.dateFormat));
    }, []);
    const downloadInsuranceAndCCData = (data: any) => {
        convertPricing(data)
    };
   
    return (
        <>
            <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={alertbox.open}
            autoHideDuration={6000}
            onClose={() => setalertbox({ open: false, type: '', msg: '' })}
            >
                <Alert
                onClose={() => setalertbox({ open: false, type: '', msg: '' })}
                severity={alertbox.type}
                >
                {alertbox.msg}
                </Alert>
            </Snackbar>
            <div id="container">
                <div
                    style={{
                        float: 'left',
                        width: '18%',
                        paddingTop: '5px',
                        paddingBottom: '15px',
                    }}
                >
                    <Form style={{ marginLeft: '5%' }}>
                        <Form.Item
                            label="From Date"
                            name="fromdate"
                            style={{ width: '100%' }}
                        >
                            <AntDDatePicker
                                style={{ width: '95%' }}
                                disabled={false}
                                format={config.dateFormat}
                                defaultValue={new Date(selectedFromDate)}
                                showToday
                                onChange={(fromDate: any) =>
                                    handleselectionfromdate(fromDate)
                                }
                                allowClear={false}
                            />
                        </Form.Item>
                    </Form>
                </div>
                <div style={{ float: 'left', width: '18%', paddingTop: '5px' }}>
                    <Form style={{ marginLeft: '5%' }}>
                        <Form.Item label="To Date" name="todate">
                            <AntDDatePicker
                                style={{ width: '95%' }}
                                disabled={loader}
                                format={config.dateFormat}
                                defaultValue={new Date(selectedToDate)}
                                showToday
                                onChange={(toDate: any) =>
                                    handleselectiontodate(toDate)
                                }
                                allowClear={false}
                            />
                        </Form.Item>
                    </Form>
                </div>
                <div
                    style={{
                        float: 'left',
                        width: '24%',
                        paddingTop: '5px',
                        paddingBottom: '15px',
                    }}
                >
                    <Form style={{ marginLeft: '5%' }}>
                        <Form.Item label="Location" name="location">
                            <Select
                                loading={!locations}
                                allowClear
                                placeholder="Please select"
                                showArrow
                                aria-required
                                disabled={loader}
                                onChange={(e: any) => setLocationId(e)}
                            >
                                <Option key={0} value={'all'}>
                                    {'All'}
                                </Option>
                                {locations?.map((location:any) => (
                                    <Option
                                        disabled={searchBy==='createdDate'}
                                        key={location.qbenchCustomerId}
                                        value={location.qbenchCustomerId}
                                    >
                                        {location.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Form>
                </div>
                <div style={{ float: 'left', width: '18%', paddingTop: '5px' }}>
                    <Form style={{ marginLeft: '5%' }}>
                        <Form.Item label="Search By" name="searchBy">
                            <Select
                                allowClear={false}
                                defaultActiveFirstOption={true}
                                disabled={loader}
                                placeholder="Please select"
                                showArrow
                                aria-required
                                defaultValue={'createdDate'}
                                onChange={(e: any) => setSearchBy(e)}
                            >
                                <Option key={0} value={'createdDate'}>
                                    {'Created Date'}
                                </Option>
                                <Option key={1} value={'appointmentDate'}>
                                    {'Appointment Date'}
                                </Option>
                            </Select>
                        </Form.Item>
                    </Form>
                </div>
                <Button
                    onClick={handlesearch}
                    variant="contained"
                    // disabled={loader}
                    style={{
                        backgroundColor: 'rgb(0, 172, 193)',
                        color: 'white',
                        height: 33,
                        margin: '5px 0 0 17px',
                    }}
                >
                    {loader?'Searching...':'Search'}
                </Button>
            </div>
            <br />
            <Card>
                <CardHeader color="primary">
                    <Typography
                        variant="h2"
                        style={{ color: 'white', fontSize: '1.5rem' }}
                    >
                        Insurance And Stripe Revenue Report
                    </Typography>
                </CardHeader>
                <CardBody>
                    {loader ? (
                        <Loader />
                    ) : (
                        <>
                            {searched ? (
                                listofdata.length > 0 ? (
                                    <>
                                        <Box
                                            alignContent="center"
                                            alignItems="center"
                                            textAlign="center"
                                        >
                                            {
                                                <>
                                                    <Button
                                                        variant="contained"
                                                        onClick={(e: any) => {
                                                            downloadInsuranceAndCCData(
                                                                listofdata
                                                            );
                                                        }}
                                                        style={{
                                                            margin: '8px',
                                                        }}
                                                        disabled={downloadProgresss!==''}
                                                    >
                                                        Download Report for
                                                        Insurance AND Credit
                                                        Card
                                                    </Button>
                                                    {downloadProgresss!==''&&<BorderLinearProgress  variant="determinate" value={downloadProgresss} />}
                                                </>
                                            }
                                        </Box>
                                    </>
                                ) : (
                                    <>
                                        <Typography
                                            variant="h5"
                                            align="center"
                                            color="initial"
                                        >
                                            No Data Found.
                                        </Typography>
                                    </>
                                )
                            ) : (
                                ''
                            )}
                        </>
                    )}
                </CardBody>
            </Card>
        </>
    );
}
