import React, { useState } from 'react';
import { Modal, Select, Button, Form, Input, notification, Checkbox, Alert, Popconfirm } from 'antd';
import { addNewUser, validForm } from './utils';
import { useSharedState, useSharedDispatch } from '../../../shared/provider';
import { apiCallForDayWiseUsers } from './API';
import {MdDelete} from 'react-icons/md'
import { decryptString, encryptString } from '../../../../utils';
interface modalProp {
    title: string;
    open: boolean | undefined;
    closeModal: () => void;
    okBtn: string;
    id?: string;
}
const { Option } = Select;
const FormModal: React.FC<modalProp> = ({
    title,
    open,
    closeModal,
    id,
    okBtn,
}) => {
    let { allLocations, daywiseUsers } = useSharedState();
    const [selectOptions, setSelectOptions] = useState(false);
    const { setDaywiseUsers } = useSharedDispatch()
    const [error, setError] = useState('');
    const [appointmentError, setAppointmentError] = useState('');
    const [form, setForm] = useState<any>((state:any)=>{
        if(!id) return {}
        let data = daywiseUsers?.filter(e=>e.id===id)?.[0] ?? null;
        const password = decryptString(data?.password)
        data = {...data, password, repassword:password}
        return data;
    })     
    const [submit, setSubmit] = useState(false);
    const [loading, setLoading] = useState(false)
    const handleForm = ( key: string, value: any) => setForm((e: any) => ({ ...e, [key]: value }));
    const user: any = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('userDetails'))))
    const filterLocations : any = allLocations?.filter((e: any)=>(e?.isActive && e?.isOpened) ||(e?.schedularLite?.isActive))
    // allLocations = (
    //     allLocations?.filter(
    //         (v: any, i: any, a: any) =>
    //             a.findIndex((v2: any) =>
    //                 ['qbenchCustomerId'].every((k: any) => v2[k] === v[k])
    //             ) === i
    //     ) ?? []
    // ).map((e) => ({ label: e.name, value: e.qbenchCustomerId }));
    allLocations = (filterLocations?.filter(
        (v:any,i:any,a:any)=>a.findIndex((v2:any)=>(v2.qbenchCustomerId===v.qbenchCustomerId))===i)
        ).map((e: any) => ({ label: e.name, value: e.qbenchCustomerId }));
    const handleAddAndUpdateForm = async(type:any) =>{
        if(type.type === 'delete')
        {
            setLoading(true);
            const response = await apiCallForDayWiseUsers(type)
            if(response.data)
            {
                let arr:any[] = [];
                if(daywiseUsers)
                    arr = [...daywiseUsers];
                setDaywiseUsers(arr.filter((e:any)=>e.id !== id) ?? [])
                setLoading(false)
                closeModal()
            }
            else
            {
                notification['error']({
                    message: 'Try Again',
                    description:
                        'Somthing Went Wrong Please try again.',
                    })
                setLoading(false)
            }
            return null;
        }
        setSubmit(addNewUser.filter(e=>id ? e.edit : true).some((e) => !! validForm(e.name, form, id)));
        if(!(form.isWeeklyTesting || form.allSchedulerLite))
        {
            setAppointmentError('Required*');
            setSubmit(true);
            return null;
        }
        setError('')
        if (!addNewUser.filter(e=>id ? e.edit : true).some((e) => !!validForm(e.name, form ,id))) {
            setLoading(true)            
            const response = await apiCallForDayWiseUsers(type)
            if(response.data === 'Email Already Exist')
            {
                setError(`The Email Entered is Already Created`);
                setLoading(false);
                return null;
            }
            if(response.data)
            {
                let arr:any[] = [];
                if(daywiseUsers)
                    arr = [...daywiseUsers];
                if(type.type==='add')
                {
                    setDaywiseUsers([...arr, {...form,id:response.data}])
                }
                else if(type.type === 'update')
                {   
                    setDaywiseUsers(arr.map(record=>{
                        if(record?.id === id)
                        {
                            const password = encryptString(form?.password)
                            return {...form, password, repassword:password};
                        }
                        else
                            return record;
                    }))
                }
                setLoading(false)
                closeModal()
            }
            else
            {
                notification['error']({
                    message: 'Try Again',
                    description:
                        'Somthing Went Wrong Please try again.',
                    })
                setLoading(false)
            }
        }
        else{
            
        }
    }
    React.useEffect(() => {
        document.getElementsByClassName('ant-select-dropdown')[0]?.setAttribute('class',(open || selectOptions)?'':'hide');
    }, [open, selectOptions])
    return (
        <Modal
            visible={open}
            title={title}
            closable={false}
            footer={
                !okBtn
                    ? [
                          <Button key="back" type="ghost" onClick={closeModal}>
                              Cancel
                          </Button>
                      ]
                    : !id ? [
                          <Button key="back" type="ghost" onClick={closeModal} loading={loading}>
                              Cancel
                          </Button>,
                          <Button
                              loading ={loading}
                              key="submit"
                              type="primary"
                              onClick={async() => handleAddAndUpdateForm({data:{...form, name:form?.name?.toLowerCase(), email:form?.email?.toLowerCase()},type:'add',updatedBy:user?.email ?? '', userName:user?.name ??'', updatedFrom:`Special DayWise User Added- UserName : ${form?.name?.toLowerCase()}`})}
                          >
                              {okBtn}
                          </Button>,
                      ]
                      :
                      [
                        <Button key="back" type="ghost" onClick={closeModal} loading={loading}>
                            Cancel
                        </Button>,
                        <Popconfirm
                            title={'Are you sure want to Delete this User?'}
                            onConfirm={()=>handleAddAndUpdateForm({id:id,type:'delete',updatedBy:user?.email ?? '',
                            userName:user?.name ?? '',updatedFrom:`Special DayWise User Deleted- UserName : ${form?.name?.toLowerCase()}`})}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button icon={<MdDelete/>} key="back" type="default" style={{backgroundColor:'red'}} loading={loading}>
                                Delete
                            </Button>
                        </Popconfirm>,
                        <Button
                            loading ={loading}
                            key="submit"
                            type="primary"
                            onClick={() => handleAddAndUpdateForm({data:{...form, name:form?.name?.toLowerCase(), email:form?.email?.toLowerCase()},type:'update',id:id,updatedBy:user?.email ?? '',
                            userName:user?.name ?? '',updatedFrom:`Special DayWise User Updated - UserName : ${form?.name?.toLowerCase()}`})}
                                >
                            {okBtn}
                        </Button>,
                    ]
            }
            onCancel={()=>{closeModal(); setSelectOptions(e=>false);}}
            width={600}
        >
            {error && <Alert 
                message={error}
                type="error"
                style={{margin:'10px 0'}}
            />}
            <Form layout="vertical" autoComplete="off">
                {addNewUser.filter(e=>id ? e.edit : true).map((e, i: number) => (
                    <Form.Item
                        label={e.label}
                        name={e.name}
                        rules={[
                            {
                                required: e.required,
                                message: 'Please input your username!',
                            },
                        ]}
                        style={{ gap: 10 }}
                    >
                        {(e.type !== 'select') ? (
                            <>
                                <Input
                                    disabled={id ? e.name === 'email' : loading}
                                    name={e.name}
                                    onChange={(value) =>
                                        handleForm(e.name, value.target.value)
                                    }
                                    type={e.type}
                                    value={form?.[e.name] ?? ''}
                                />
                                {(submit && e.required && validForm(e.name, form)) ? <span style={{ color: 'red' }}>{validForm(e.name, form)}</span> :'' }
                            </>
                        ) : (
                            <>
                                <Select
                                    open={open && selectOptions}
                                    onSelect={()=>{
                                        const selectComponentId = document.getElementById('selectMultiple')
                                        selectComponentId?.blur();
                                        setSelectOptions(e=>false)}
                                    }
                                    onFocus={()=>setSelectOptions(e=>true)}
                                    id="selectMultiple"
                                    mode="multiple"
                                    disabled={loading}
                                    placeholder="Please select"
                                    showSearch
                                    optionFilterProp="children"
                                    value={form?.[e.name] ?? []}
                                    onChange={(value) =>
                                        {
                                            handleForm(e.name, value);
                                            handleForm('locationName',allLocations?.filter(ee=>value.includes(ee.value)).map(ee=>ee.label))
                                        }
                                    }
                                >
                                    {allLocations?.map(
                                        (
                                            column: { value: any; label: any },
                                            index: number
                                        ) => (
                                            <Option
                                                key={index}
                                                value={column.value}
                                            >
                                                {column.label}
                                            </Option>
                                        )
                                    )}
                                </Select>
                                {submit && e.required && validForm(e.name, form) && (
                                    <span style={{ color: 'red' }}>
                                        {validForm(e.name, form)}
                                    </span>
                                )}
                                <br />
                                <br />
                                <p>Access Appointment Type</p>
                                <Checkbox style={{marginTop:15}} disabled={form.allAppointments} checked={form?.['allSchedulerLite'] ? true : false} onChange={(value)=>{
                                    setForm((state: any) => ({
                                        ...state,
                                        allSchedulerLite: value.target.checked,
                                        isWeeklyTesting:value.target.checked
                                    }));
                                    if(submit)
                                        setAppointmentError(value.target.checked ? '' : 'Required*')
                                }}> All Scheduler Lite Appointments ?</Checkbox>
                                <br/>
                                <Checkbox disabled={form.allSchedulerLite} style={{marginTop:15}} checked={form?.['isWeeklyTesting'] ? true : false} onChange={(value)=>{
                                    setForm((state: any) => ({
                                        ...state,
                                        isWeeklyTesting: value.target.checked,
                                    }));
                                    if(submit && !form.allSchedulerLite)
                                        setAppointmentError(value.target.checked ? '' : 'Required*')
                                }}> Only Weekly Testing Appointments ?</Checkbox>
                                <br />

                                <Checkbox  style={{marginTop:15}} checked={form?.['allAppointments'] ? true : false} onChange={(value)=>{
                                    setForm((state: any) => ({
                                        ...state,
                                        allAppointments: value.target.checked,
                                        allSchedulerLite: value.target.checked,
                                        isWeeklyTesting:value.target.checked
                                    }));
                                    if(submit && !form.allSchedulerLite)
                                        setAppointmentError(value.target.checked ? '' : 'Required*')
                                }}> All Appointments ?</Checkbox>
                                <br />

                                {submit && appointmentError ? <span style={{ color: 'red' }}>{appointmentError}</span> : ''}
                                <br />
                                <Checkbox style={{marginTop:15}} checked={form?.['isActive'] ? true : false} onChange={(value)=>{
                                    setForm((state:any)=>({...state,isActive:value.target.checked }))
                                }}> User Active ?</Checkbox>
                                <br/>
                                <Checkbox style={{marginTop:15}} checked={form?.['isEditAccess'] ? true : false} onChange={(value)=>{
                                    setForm((state: any) => ({
                                        ...state,
                                        isEditAccess: value.target.checked,
                                    }));
                                }}> Edit Access ?</Checkbox>
                                <br />
                                <Checkbox style={{marginTop:15}} checked={form?.['isConfiguration'] ? true : false} onChange={(value)=>{
                                    setForm((state: any) => ({
                                        ...state,
                                        isConfiguration: value.target.checked,
                                    }));
                                }}> Configuration Access?</Checkbox>
                            </>
                        )}
                    </Form.Item>
                ))}
            </Form>
        </Modal>
    );
};

export default FormModal;
