import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useFormikContext } from 'formik';
import { useAsync } from 'react-async-hook';
import { PostalCodes } from './PostalCode';
import Input from '../../../shared/components/form/Input';
import FormLabel from '../../../shared/components/form/FormLabel';
import { AnyObject } from '../../../shared/models';
import { colors } from '../../../../styles/colors';

import { getPostalCodeDetails } from "../../../shared/providers/schedule/api";

const Container = styled.div`
  position: relative;
  margin-bottom: 45px;
`;

const Address = styled.div`
  position: absolute;
  bottom: -30px;
  font-size: 14px;
  line-height: 22px;
  opacity: ${({ show }: { show: boolean }) => (show ? 1 : 0)};
  transition: opacity 0.15s ease-in-out;
  margin-top: -10px;
`;

const PopConfirmStyle = createGlobalStyle`
  .customized-popover-confirm {
    .ant-popover-content {
      .ant-popover-inner {
        border-radius: 10px;

        .ant-popover-inner-content {
          .ant-popover-message-title {
            font-size: 14px;
          }

          .ant-popover-buttons {
            .ant-btn {
              box-sizing: content-box;
              font-size: 14px;
              line-height: 1.6;
              border-radius: 5px;
              padding: 4px 15px;
              font-weight: normal;
              cursor: pointer;

              &.ant-btn-sm{
                border: 1px solid ${colors.darkBlue};
                color: ${colors.darkBlue};
                background: ${colors.white};
              }

              &.ant-btn-primary.ant-btn-sm {
                border: 1px solid ${colors.darkBlue};
                color: ${colors.white};
                background: ${colors.darkBlue};
              }
            }
          }
        }
      }
    }
  }
`;

interface Props {
  updateAddressValue: (newValue: any) => void;
  updateCountyValue: (newValue: any) => void;
  onChange:(data:any)=>void;
  countryname?:any;
  value?:any;
  isEdit: boolean;
}

const fetchAddressByZipCode = async (zipCode: string) =>
  // (
  //   await fetch(
  //     `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${zipCode}|country:US&key=${process.env.REACT_APP_GEOCODE_API_KEY}`
  //   )
  // ).json();
  await getPostalCodeDetails(zipCode)

const ZipCodeInput: React.FC<Props> = ({
  updateAddressValue,
  updateCountyValue,
  countryname,
  value,
  onChange,
  isEdit,
}) => {
  const {
    values: { address },
    errors,
  } = useFormikContext<{ address: AnyObject }>();
  const { values : {
    travelType
  } } = useFormikContext<{travelType: string}>();
  const [popupVisible, setPopupVisible] = useState(false);
  const [city, setCity] = useState('');
  const [state, setState] = useState({
    longName: '',
    shortName: '',
  });

  const [county, setCounty] = useState(String);


  const { result }: any = useAsync(fetchAddressByZipCode, [address.zipCode]);

  const hasResult =
  address.zipCode && result !== undefined &&
  result!.length > 0 &&
  Array.isArray(result)

  useEffect(() => {
    if (hasResult) {
      // const { results } = result;

      for ( const component of result) {
        if (component.ZipCode && component.County) {
            updateCountyValue({ county: component.County })
        }
      }
    }
  }, [hasResult, result]);

  return (
    <Container style={{ marginBottom: isEdit ? '0px' : '45px' }} >
      <FormLabel label="ZIP Code">
        <PopConfirmStyle />
        <Input
        defaultValue={value}
          name="address.zipCode"
            onChange={(value) => {
                updateAddressValue({ zipCode: value });
                onChange(value)
            }}
          validate={(value) => {
            let error;

            let InterisValidZIP:any;
            PostalCodes.map((codes)=>{
              //  console.log("codes.state",codes.Country)
               
              if(codes.Country==countryname){
                console.log("codes.Country",codes.Regex)
                InterisValidZIP=new RegExp(codes.Regex)
              }
            })

            if (!InterisValidZIP?.test(value)) {
              error = `Please enter valid ${countryname} postal code`;
            }

            if (!value) {
              error = 'Required field';
            }

            return error;
          }}
        />
        {/* </Popconfirm> */}
      </FormLabel>
      {/* <Address show={hasResult && errors?.address?.zipCode === undefined}>
        {result?.results?.[0]?.formatted_address}
      </Address> */}
    </Container>
  );
};

export default ZipCodeInput;
