import React, { useState } from 'react';
import styled from 'styled-components';

import {
    Button,
    Container,
    Content,
    PageHeader,
    PageTitle,
} from '../../../../shared/components/styled';
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import { Breakpoints } from '../../../../../dictionaries';
import { AdminManagePage } from '../dictionaries';
import { cancelAppointment } from '../../../../shared/providers/schedule/api';
import { colors } from '../../../../../styles/colors';
import { useAdminDispatch, useAdminState } from '../../../provider';
import { IoPersonOutline } from 'react-icons/io5';
import Grid from '@material-ui/core/Grid';

const CancelBtn = styled(Button)`
  width: 100%;
`;

const Text = styled.p`
  margin-bottom: 45px;
`;

const Error = styled.div`
  color: ${colors.red};
  margin-top: 15px;
  text-align: left;
`;

export const Input = styled.input`
    height: 19px;
    width: 19px;
    border: 1px solid ${colors.blueLight};
    border-radius: 0px;
    outline: none;
    transition-duration: 0.3s;
    cursor: pointer;
    background-color: ${colors.white};
`;

const Flexbox = styled.div`
    display: flex;
    align-items: center;
    margin-top: -23px;
    width: 100%;
    &:nth-child(1) {
        width: max-content;
        margin-right: 5px;
    }
    &:nth-child(2) {
        border: none;

        margin-right: 0px;
        padding-right: 0px;
    }
    &:nth-child(3) {
        margin-left: 50px;
    }
    &:nth-child(4) {
        margin-left: 50px;
    }
    @media (max-width: ${Breakpoints.sm}px) {
        margin-bottom: 20px;
        &:nth-child(1),
        &:nth-child(2) {
            width: 100%;
            border-right: none;
        }
        &:nth-child(4) {
            margin-left: 0px;
            border-right: none;
        }
        &:nth-child(3) {
            width: 100%;
            margin-left: 0px;
            border-right: none;
        }
    }
`;

const FlexboxIcon = styled.div`
    position: relative;
    color: ${colors.grey};
    display: inline-flex;
`;
const FlexboxText = styled.div`
    display: block;
    font-size: 14px;
    font-weight: 500;
    padding-left: 0px;
    word-break: break-all;
`;

const ManageCancel: React.FC = () => {
    const { manageAppointment } = useAdminState();
    const { goToPage } = useAdminDispatch();
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(true);
    const [partChecked, setPartChecked] = useState(false);
    
    const getallData = () => {
        let index = [];
        if (manageAppointment?.registeringFor === 'myself') {
            index.push(-1);
        } else if (manageAppointment?.registeringFor === 'myselfAndMinor') {
            index.push(-1);
            manageAppointment?.minors.map((minorindex: any, i: number) => {
                if(!minorindex.isCanceled){    
                    index.push(i);
                    }
            });
        } else if (manageAppointment?.registeringFor === 'minorOnly') {
            
            manageAppointment?.minors.map((minorindex: any, i: number) => {
                if(!minorindex.isCanceled){    
                index.push(i);
                }
            });
        }
        return index;
    };

    const [param, setParam] = useState(getallData());
    const cancelAppointmentHandler = async () => {
        setLoading(true);
        setError(null);
        try {
            if (manageAppointment?.qbenchId) {
                let params: any = {id:manageAppointment?.id};
                if (param.length === getallData().length) {
                    setParam([]);
                    params['index'] = [];
                } else {
                    params['index'] = param;
                }
                await cancelAppointment(params);
                goToPage(AdminManagePage.ManageCancelConfirm);
            } else {
                // @ts-ignore
                throw new Error('Appointment data is incomplete');
            }
        } catch (e) {
            setLoading(false);
            setError(e.message);
            console.error(e);
        }
    }

    const spliceIndex = (type: boolean, method: any) => {
        let array: any[] = param;
        if (type === true) {
            array.splice(1, 0, method);
        } else if (type === false) {
            let splice: any;
            if (array.includes(method)) {
                splice = array.indexOf(method);
            }
            array.splice(splice, 1);
        }
        return array;
    };

    return (
        <Container size="md">
            <Content>
                <PageHeader>
                    <PageTitle>
                        Are you sure you want to cancel your appointment?
                    </PageTitle>
                </PageHeader>
                {getallData().length > 1 ? (
                            <DialogContent>
                                <DialogContentText>
                                    <Text>
                                    If you’d like to cancel your appointment, please
                                select and click on the link below.
                                    </Text>
                                </DialogContentText>
                                <div style={{ display: 'flex' }}>
                                    <Input
                                        defaultChecked={checked}
                                        onChange={(value) => {
                                            console.log(
                                                'value',
                                                value.target.checked
                                            );
                                            setPartChecked(
                                                !value.target.checked
                                            );
                                            setChecked(value.target.checked);
                                            if (!value.target.checked) {
                                                setParam([]);
                                            } else {
                                                setParam(getallData());
                                            }
                                        }}
                                        type="checkbox"
                                    />
                                    <Text>
                                        click here to cancel complete
                                        appointment
                                    </Text>
                                </div>
                                {(manageAppointment?.registeringFor === 'myself' ||
                                    manageAppointment?.registeringFor ===
                                        'myselfAndMinor') && (
                                    <div style={{ display: 'flex' }}>
                                        <Input
                                            disabled={checked}
                                            type="checkbox"
                                            onChange={(value) => {
                                                console.log(
                                                    'value.target.checked',
                                                    value.target.checked
                                                );
                                                setParam(
                                                    spliceIndex(
                                                        value.target.checked,
                                                        -1
                                                    )
                                                );
                                            }}
                                        />
                                        &nbsp; &nbsp;
                                        <Flexbox>
                                            <FlexboxIcon>
                                                <IoPersonOutline size={25} />
                                            </FlexboxIcon>
                                            <FlexboxText>
                                                <b>{'Name : '}</b>
                                                {manageAppointment?.firstName}{' '}
                                                {manageAppointment?.middleName}{' '}
                                                {manageAppointment?.lastName}
                                            </FlexboxText>
                                        </Flexbox>
                                    </div>
                                )}
                                <br />
                                {manageAppointment?.minors.length>0 &&
                                    manageAppointment?.minors.map(
                                        (minorindex: any, i: number) => {
                                            return (
                                                !minorindex.isCanceled && <>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                        }}
                                                    >
                                                        <Flexbox>
                                                            <Input
                                                                disabled={
                                                                    checked
                                                                }
                                                                onChange={(
                                                                    value: any
                                                                ) => {
                                                                    console.log(
                                                                        'value.target.checked',
                                                                        value
                                                                            .target
                                                                            .checked,
                                                                        i
                                                                    );
                                                                    setParam(
                                                                        spliceIndex(
                                                                            value
                                                                                .target
                                                                                .checked,
                                                                            i
                                                                        )
                                                                    );
                                                                }}
                                                                type="checkbox"
                                                            />{' '}
                                                            &nbsp; &nbsp;
                                                        </Flexbox>
                                                        <Flexbox>
                                                            <FlexboxIcon>
                                                                <IoPersonOutline
                                                                    size={25}
                                                                />
                                                            </FlexboxIcon>
                                                            <FlexboxText>
                                                                <b>
                                                                    {'Name : '}
                                                                </b>
                                                                {
                                                                    minorindex?.firstName
                                                                }{' '}
                                                                {
                                                                    minorindex?.middleName
                                                                }{' '}
                                                                {
                                                                    minorindex?.lastName
                                                                }
                                                            </FlexboxText>
                                                        </Flexbox>
                                                    </div>
                                                    <br />
                                                </>
                                            );
                                        }
                                    )}
                            </DialogContent>
                        ) : (
                            <DialogContent>
                                <DialogContentText>
                                    <Text>
                                    If you’d like to cancel your appointment, please
                                click on the link below.
                                    </Text>
                                </DialogContentText>
                            </DialogContent>
                        )}
                <Grid container spacing={4}>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <CancelBtn
                            type="button"
                            libraryType="default"
                            disabled={loading}
                            onClick={() => goToPage(AdminManagePage.MangeResults)}>
                            {loading ? 'Cancelling...' : 'Return'}
                        </CancelBtn>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <CancelBtn
                            type="button"
                            libraryType="primary"
                            disabled={loading}
                            onClick={() => cancelAppointmentHandler()}>
                            {loading ? 'Cancelling...' : 'Cancel Appointment'}
                        </CancelBtn>
                    </Grid>
                </Grid>
                {error && <Error>{error}</Error>}
            </Content>
        </Container>
    );
};

export default ManageCancel;
