import { format } from "date-fns";
import { config } from "../../../../config";
import { firebase } from "../../../../firebase";
import { initialSetUpforSchedule } from "./schedularUtils";

export const updateSchedularLite = async (data:any) => 
{
    const user:any = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('userDetails'))))
    if(user?.email)
    {
        const dateAndTime = format(new Date(), config.dateTimeFormat)
        if(data.type === 'add')
        {
            data.email = user?.email ?? '';
            data.time = dateAndTime ?? '';
            data.schedularLiteIntialValue = { 
                schedularLite: { ...initialSetUpforSchedule.schedularLite, createdBy: { user: user?.email ?? '', time: dateAndTime ?? '' }, updatedBy: { user: null, time: null } } , updatedBy : user?.email ?? '',
                userName: user?.name ?? '',
                updatedFrom : 'SchedularLite'
            }
        }
        if(data?.type)
        {
            return firebase
                .functions()
                .httpsCallable('schedularLite')(data);
        }else{
            const db = firebase.firestore();
            let responceSnapShot:any = await db.collection(config.firestoreCollections.locations).where('qbenchCustomerId','==',data.qbenchCustomerId).get();
                responceSnapShot = responceSnapShot.docs.map((e:any)=>({...e.data(),id:e.id}))
                for(var j = 0; j < (responceSnapShot?.length ?? 0); j++){
                    const schedularLite = { ...data.data.schedularLite, updatedBy: { user: user?.email ?? '', time: dateAndTime }, userName: user?.name ?? '' }
                    await db.collection(config.firestoreCollections.locations).doc(responceSnapShot[j]?.id).update({
                        schedularLite, updatedBy: user?.email ?? '',
                        userName: user?.name ?? '',
                        updatedFrom: 'SchedularLite'
                    });
                }
        }
    }    
}

export const getAllClientPartner = async () => {
    try {
        const db = firebase.firestore();
        const querySnapshot = await db
            .collection(config.firestoreCollections.clientpartner)
            .get();
        const data = querySnapshot.docs.map((doc: any) => doc.data());
        return data
    } catch (err) {

    }
}
export const getUpdatedLocationOnChange =  (id: any, allLocations:any, setLocation:any) => {
    return firebase
        .firestore().collection(config.firestoreCollections.locations)
        .where('id', '==', id)
        .onSnapshot((snapshot) => parseSnapshot(snapshot,allLocations,setLocation));
}
const parseSnapshot = (snapshot: any,allLocations:any,setLocation:any) => {
    let responseData: any = snapshot.docs.map((doc: any) => doc.data())[0]
    let newLocation = allLocations?.map((e: any) => {
        if (
            e.id === responseData.id &&
            responseData.qbenchCustomerId === e.qbenchCustomerId &&
            e.cPartnerID === responseData.cPartnerID
        ) {
            return { ...e, schedularLite:{...responseData.schedularLite} };
        }
        return e;
    });
    console.log("responseData", newLocation)
    setLocation(newLocation);
}