import React, { useState, useEffect, useMemo } from 'react';
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    Layout,
    Row,
    Space,
    Select,
    Table,
    Typography,
    Tooltip,
    DatePicker,
    Spin,
    Switch,
} from 'antd';
import {
    MoreOutlined,
    UpOutlined,
    DownOutlined,
    RedoOutlined,
} from '@ant-design/icons';
import { format, parse } from 'date-fns';
// import Highlighter from "react-highlight-words";
import { SearchOutlined, FilterFilled } from '@ant-design/icons';
import AntDDatePicker from '../../../shared/components/AntDDatePicker';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import {
    getAppointmentsByDate,
    downloadResultsPDF,
    getAppointmentsByconfirmationId,
    generateALDDReport,
    getALDDReportByDate,
    getAdminConfigDaywise,
    insertAdminConfigDaywise,
    getDayWiseAppointmentsSearchData,
    userCheckin,
    getAppointmentdata,
    updateIscheckedIn,
} from '../../api';
import { useAdminDispatch, useAdminState, useMediaQuery } from '../../provider';
import {
    getPeriodsFromSchedule,
    compareStrings,
    comparePhone,
    decryptString,
} from '../../../../utils';
import { AppointmentListItem, AppointmentsListParams } from '../../models';
import { config } from '../../../../config';
import { useSharedState } from '../../../shared/provider';

import DownloadCSV from './components/DownloadCSV';
import EditAppointmentModal from './components/EditAppointmentModal';
import EditAppointment from './components/EditAppointment';
import MoreActions from './components/MoreActions';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
    formatTableColumn,
    formItemLayout,
    searchBarActionLayout,
} from './constants';
import { styles } from './styles';
import ActionBox from './components/ActionBox';
import PrintModal from './components/PrintModal';
import CheckinSinglePrintSample from './components/CheckInSinglePrintSample';
import Check_In from './components/Check_In';
import styled from 'styled-components';
import { includes } from 'lodash';
import { Field, Formik, Form as ColumnFilterForm } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import Modal from '../../../shared/components/Modal';
import CheckInModal from './components/CheckInModal';
import PrintSampleLabels from './components/PrintSampleLabels';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import CheckinTimeLeft from './CheckinTimeLeft';
import Notes from './components/Notes';
import { getAllOpenedLocations } from '../../../shared/api';

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

const periods = getPeriodsFromSchedule(config.schedule);
const rapidPeriods = getPeriodsFromSchedule(config.rapidSchedule);

const TableActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  gap: 20px;
  float: right;
`;

function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cancel:{
            background:'red',
            '&:hover':{
                '& > td': {
                    backgroundColor: 'red !important' 
                }
            }
        },
        odd: {
            background: '#a6d0e3',
            '&:hover': {
                '& > td': {
                    backgroundColor: '#a6d0e3 !important',
                    '&:last-child': {
                        backgroundColor: '#f8f9fa !important',
                    },
                },
            },
        },

        even: {
            background: '#f8f9fa',
            '&:hover': {
                '& > td': {
                    backgroundColor: '#f8f9fa !important',
                },
            },
        },
        antigen: {
            background: 'yellow',
            '&:hover': {
                '& > td': {
                    backgroundColor: 'yellow !important',
                },
            },
        },
        express: {
            background: '#a6d0e3',
            '&:hover': {
                '& > td': {
                    backgroundColor: '#a6d0e3 !important',
                    '&:last-child': {
                        backgroundColor: '#f8f9fa !important',
                    },
                },
            },
        },

        destination: {
            background: '#a5a39f',
            '&:hover': {
                '& > td': {
                    backgroundColor: '#a5a39f !important',
                },
            },
        },

        standard: {
            background: '#f8f9fa',
            '&:hover': {
                '& > td': {
                    backgroundColor: '#f8f9fa !important',
                },
            },
        },

        rapid: {
            background: '#FFD580',
            '&:hover': {
                '& > td': {
                    backgroundColor: '#FFD580 !important',
                },
            },
        },
        customfilterdropdown: {
            padding: '12px',
            borderRadius: '4px',
            background: '#fff',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
        },
    })
);

interface Props {
    email: string;
    confirmationId: string;
    appt: AppointmentListItem;
    qbenchId: number[];
}

const sortByPeriod = (a: AppointmentListItem, b: AppointmentListItem) => {
    if (a.period !== null && b.period !== null) {
        return a.period - b.period;
    }
    return 0;
};


const renderSymptoms = (symptoms: any) => {
    return symptoms.length
        ? symptoms.map !== undefined
            ? symptoms.map((symptom: any, symptomIndex: number) => (
                <div key={symptomIndex}>{symptom}</div>
            ))
            : symptoms
        : 'None';
};



const renderResults = (results: any, record: any) => {
    if (record.isPanel) {
        return (results && record.tests.filter((e: any) => e?.result).length === record.tests.length) ? (
            <Tooltip title={() => {
                return <ul>
                    {record.tests.map((e: any) => <li style={{ marginTop: 15 }}>{e.name} - {e.id} <br /> {e.result}</li>)}
                </ul>
            }}>
                <Button
                    type="link"
                    onClick={() => {
                        downloadResultsPDF(record.sampleId);
                    }}>
                    View Results
                </Button>
            </Tooltip>
        ) : (
            <></>
        );
    } else {
        return results ? (
            <Button
                type="link"
                onClick={() => {
                    downloadResultsPDF(record.sampleId);
                }}>
                {results}
            </Button>
        ) : (
            <></>
        );
    }
}
    

const renderActionsMenuTitle = () => <MoreOutlined />;
const sleep = (ms: any) => new Promise((r) => setTimeout(r, ms));
function AppointmentList() {
    const classes = useStyles();
    const { params, appointments, Editappointments, toggle } = useAdminState();
    const { updateParams, setAppointments, setToggleData } = useAdminDispatch();
    const [locations, setLocations] = React.useState<any>(null);
    React.useEffect(() => {
      getAllOpenedLocations().then((loc) => {
          setLocations(loc.filter((e: any)=>(e?.isActive && e?.isOpened) ||(e?.schedularLite?.isActive)).filter((v:any,i:any,a:any)=>a.findIndex((v2:any)=>(v2.qbenchCustomerId===v.qbenchCustomerId))===i));
      });
    }, []);
    // For filter locations according to isActive and isOpened
    const filterLocations = locations?.filter((e: any)=>(e?.isActive && e?.isOpened) ||(e?.schedularLite?.isActive))
    // End
    const [loading, setLoading] = useState(false);
    const [ShowEditAppointmentModal, setShowEditAppointmentModal] = useState(
        false
    );

    const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>(
        'checkbox'
    );
    const [alddloading, setalddLoading] = useState(false);
    const [mouseover, setmouseover] = useState(false);
    const [expand, setExpand] = useState(true);
    const [openPrintModal, setOpenPrintModal] = useState(false);

    const [searchInput, setsearchInput] = useState<any[]>([]);
    const [searchText, setsearchText] = useState('');

    const [intialSearch, setIntialSearch] = useState(false);
    const [empty, setEmpty] = useState(false)
    const isLargeScreen = useMediaQuery('(min-width: 600px)');

    const [openDialogModal, setOpenDialogModal] = useState(false);
    const [searchKeys, setSearchKeys] = useState<AppointmentsListParams>({
        firstName: '',
        lastName: '',
        confirmationId: '',
        phone: '',
        birthDate: '',
        email: '',
        date: '',
        locationQbenchId: null,
        sampleId: null,
        thirdPartyAppointments: '',
        toggle: '',
        status: '',
    });

    const toggler = () => {
        toggle ? setToggleData(false) : setToggleData(true);
        handleSearch();
    }

    const [dayWiseLocalData, setDayWiseLocalData] = useState<
        AppointmentsListParams
    >({
        firstName: '',
        lastName: '',
        confirmationId: '',
        phone: '',
        birthDate: '',
        email: '',
        date: '',
        locationQbenchId: null,
        sampleId: null,
        thirdPartyAppointments: '',
        status: '',
    });

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const { SetPrintSampleLabel, SetPrintID, setEditAppointment } = useAdminDispatch();
    SetPrintID('');
    const [qbenchData, setQbenchData] = useState<number[]>([]);
    const dateFormat = 'YYYY-DD-MM';
    const renderActionsMenu = (action: any, record: any) => (
        <MoreActions
            record={record}
            onEditAppointment={setEditAppointment}
            //closeModal={() => setShowEditAppointmentModal(false)}
            handleDrawerToggle={setShowEditAppointmentModal}
            handleSearch={handleSearch}
        />
    );
    const renderNotes = (action: any, record: any) => (
        <>
            <Notes
                record={record}
                handleSearch={handleSearch}
            />
        </>
    );
    const sortByDate = (a: any, b: any) => {
        console.log('params.toggle', params.toggle);
        if (params.toggle === 'all') {
            if (a.checkInTime !== null && a.checkInTime !== null) {
                const date1: any = new Date(a.checkInTime);
                const date2: any = new Date(b.checkInTime);
                return date2 - date1;
            }
        } else if (a.date !== null && b.date !== null) {
            const date1: any = new Date(a.date);
            const date2: any = new Date(b.date);
            return date2 - date1;
        }
        return 0;
    };

    const sortByTime = (a:any , b: any) => {
        if (a?.slot?.label !== undefined && b?.slot?.label !== undefined) {
            const date1:any = parse(a?.slot?.label.split('-')[0] ?? '', 'h:mm a', new Date()); 
            const date2:any = parse(b?.slot?.label.split('-')[0] ?? '', 'h:mm a', new Date()); 
            return date2-date1;
        }
        return 0;
    }
    const renderCheckin = (status: any, record: any) => {
        return (
            <div
                onClick={() => {
                    setQbenchData([record.qbenchId]);
                    SetPrintSampleLabel([record]);
                }}
            >
                {record.isCheckedIn ? (
                    <h4>Checked In</h4>
                ) : (
                    <Check_In
                        selectedRowKeys={selectedRowKeys}
                        SerachAppointment={SerachAppointment}
                        params={params}
                        rowCheckIn={true}
                        qbenchId={
                            selectedRowKeys.length ? qbenchData : [record.qbenchId]
                        }
                        disabledCheckin={
                            record.disable || (status === 'Canceled' || status === 'Cancelled' || record?.isCanceled) ? true : false
                        }
                        handlePrintModal={handlePrintModal}
                        handleSearch={handleSearch}
                        childComp={
                            <EditAppointment
                                isCheckIn={true}
                                appointment={Editappointments}
                                handleDrawerToggle={handleEditAppointment}
                                apptType={false}
                                fromApptList={false}
                                handleSearch={handleSearch}
                            />
                        }
                    />
                )}
            </div>
        );
    };
    const renderTat = (record: any, data: any) => {

        if (data.isCheckedIn && !data?.isRapidTest && !data?.isAntigen && !data?.isPanel) {           //1st task 3

            if (data?.checkInExpTime && data?.checkInTime) {
                return (
                    <CheckinTimeLeft checkInExpTime={data?.checkInExpTime} result={data.results} />
                );
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    // const renderTableColumn = formatTableColumn(
    // 	renderSymptoms,
    // 	renderResults,
    // 	renderActionsMenuTitle,
    // 	renderActionsMenu,
    // 	renderCheckin
    // );

    const handlePrintModal = () => {
        if (openPrintModal) {
            setOpenPrintModal(false);
        } else {
            setOpenPrintModal(true);
        }
    };

    const handleEditAppointment = () => {
        // handleDrawerToggle(false)
        //handleDrawerToggle(false)\
        setShowEditAppointmentModal(false);
        //SerachAppointment()
    };

    const getColumnSearchProps = (dataIndex: any) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }: any) => (
            <div className={classes.customfilterdropdown}>
                <Input
                    ref={(node: any) => {
                        setsearchInput(node);
                    }}
                    // placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleFilterSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Tooltip placement="top" title={'Search'}>
                    <Button
                        type="primary"
                        onClick={() => handleFilterSearch(selectedKeys, confirm)}
                        // icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8, border: '#fff' }}
                    >
                        <SearchOutlined style={{ color: '#fff' }} />
                    </Button>
                </Tooltip>
                <Tooltip placement="top" title={'Reset'}>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90, borderRadius: '5px' }}
                    >
                        <RedoOutlined style={{ color: '#000' }} />
                    </Button>
                </Tooltip>
            </div>
        ),
        filterIcon: (filtered: any) => (
            <FilterFilled style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value: any, record: any) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible: any) => {
            if (visible) {
                // setTimeout(() =>searchInput.select());
            }
        },
        // render: (text:any) => (
        // 	<Highlighter
        // 		highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        // 		searchWords={searchText}
        // 		autoEscape
        // 		textToHighlight={text.toString()}
        // 	/>
        // )
    });

    const SerachAppointment = (inputdata: any) => {
        console.log('data', params);

        if (inputdata.locationQbenchId == null) {
            inputdata.locationQbenchId = 0;
        }

        if (inputdata.sampleId == null) {
            inputdata.sampleId = 0;
        }

        if (
            inputdata.locationQbenchId !== 0 ||
            inputdata.firstName !== '' ||
            inputdata.lastName !== '' ||
            inputdata.confirmationId !== '' ||
            inputdata.birthDate !== '' ||
            inputdata.email !== '' ||
            inputdata.phone !== '' ||
            inputdata.sampleId  ||
            inputdata.date !== '' ||
            inputdata.status !== '' ||
            (inputdata.toggle !== '' && toggle) ||
            inputdata.thirdPartyAppointments !== ''
        ) {
            setLoading(true);
            getDayWiseAppointmentsSearchData(inputdata)
                .then(({ data }) => {
                    const updatedAppointments = data
                        .sort(sortByDate)
                        .map((item: any) => ({
                            ...item,
                            period:
                            (item?.isSchedularLite ||  item?.isWalkUpTest|| item?.isQbenchAppt) ? item?.slot?.label ?? '' :
                                item.isRapidTest || item.isRapidTest == 'TRUE'
                                    ? item.period !== null && item.period >= 0
                                        ? rapidPeriods[item.period].label
                                        : '-'
                                    : item.period !== null && item.period >= 0
                                        ? periods[item.period].label
                                        : item.isSolvHealth
                                        ? item.slot.label
                                        : '-',
                            // isExpressSameDayTest: item.isExpressSameDayTest ? 'Yes' : 'No'
                            testType: item.isRapidTest
                                ? 'Rapid PCR Test'
                                : item.isExpressSameDayTest
                                    ? 'Express PCR Test'
                                    : item?.isAntigen
                                        ? 'Antigen Test'
                                        : item?.isPanel
                                          ? 'Flu A&B/Covid-19'
                                        : 'Standard PCR Test',

                            status: item.isCanceled ? 'Cancelled' :item.results===null?'': item.results !== '' ? 'Completed' : '',
                            isRapidTestText: item.isRapidTest ? 'YES' : '',
                            SolvHealth:item.isSolvHealth
                            ? 'SolvHealth'
                            : item.isReelHealth
                                ? 'ReelHealth'
                                : item.isPrimary ? '911 Covid' : item.isCLXHealth ? 'CLXHealth' : '',
                        }));

                    console.log(
                        'updatedAppointmentsupdatedAppointments',
                        updatedAppointments
                    );

                    setAppointments(updatedAppointments);
                    setLoading(false);
                })
                .catch((err) => {
                    console.error(err);
                    setLoading(false);
                });
        }
    };

    const dataSourceAppointments = useMemo(
        () =>
            appointments
                ?.filter((appointment: any) => {
                    const filterList = [
                        'firstName',
                        'lastName',
                        'confirmationId',
                        'phone',
                        'birthDate',
                        'email',
                    ];
                    for (const filterItem of filterList) {
                        const searchKey = searchKeys[filterItem as keyof typeof searchKeys];
                        const value = appointment[filterItem];

                        if (
                            filterItem == 'phone' &&
                            searchKey &&
                            !comparePhone(value, searchKey.toString())
                        ) {
                            return false;
                        }else if (
                            searchKey &&
                            !compareStrings(value, searchKey.toString())
                        ) {
                            return false;
                        }
                    }
                    return true;
                })
                .map((appointment: any, appIndex: number) => ({
                    ...appointment,
                    key: appIndex,
                })),

        [appointments, searchKeys]
    );

    const [renderTableColumn, setRenderTableColumn] = useState<any[]>([]);
    const [initialColumnList, setInitialColumnList] = useState<any[]>(
        formatTableColumn(
            renderResults,
            renderActionsMenuTitle,
            renderActionsMenu,
            renderCheckin,
            renderTat,
            params.date,
            sortByDate,
            renderNotes,
            getColumnSearchProps
        ).map((entry: any) => entry.title)
    );
    const [alertbox, setalertbox] = React.useState({
        open: false,
        type: '',
        msg: '',
    });
    const [qRCodeModel, setQRCodeModel] = useState<boolean>(false);
    const handleCancelQRCode = () => setQRCodeModel(false);
    const handleCancelCheckIn = () => setcheckIn(false);
    const [QRCodeData, setQRCodeData] = useState<{
        id: any;
        autocheckinprogress: boolean;
    }>({ id: null, autocheckinprogress: false });
    const handleScan = () => setQRCodeModel(true);
    // hide/show column
    const [dataList,setdataList]=useState([])
    // hide show column end
    const search = useLocation().search;
    const [checkIn, setcheckIn] = useState<boolean>(false);
    const loadTableViaQRScanner = async (
        qbenchId: string | undefined,
        autoCheckIn: boolean
    ) => {
        setLoading(true);
        const resData: any = await updateIscheckedIn({
            id: null,
            qbench: qbenchId,
            autoCheckIn
        });
        let data = resData.data.data.data;
        const updatedAppointments = data
            .sort(sortByDate)
            .map((item: any) => ({
                ...item,
                isCheckedIn: autoCheckIn
                    ? true
                    : item?.isCheckedIn
                        ? true
                        : false,
                testType: item.isRapidTest
                    ? 'Rapid PCR Test'
                    : item.isExpressSameDayTest
                        ? 'Express PCR Test'
                        : item.isAntigen
                            ? 'Antigen Test'
                            : item?.isPanel
                             ? 'Flu A&B/Covid-19'
                            : 'Standard PCR Test',
                period:
                    (item?.isSchedularLite ||  item?.isWalkUpTest|| item?.isQbenchAppt) ? item?.slot?.label ?? '' :item.isRapidTest || item.isRapidTest == 'TRUE'
                        ? item.period !== null && item.period >= 0
                            ? rapidPeriods[item.period].label
                            : '-'
                        : item.period !== null && item.period >= 0
                            ? periods[item.period].label
                            : item.isSolvHealth
                            ? item.slot.label
                            : '-',
                isCanceled: item.isCanceled ? 'Cancelled' : '',
                isRapidTestText: item.isRapidTest ? 'YES' : '',
                SolvHealth:item.isSolvHealth
                            ? 'SolvHealth'
                            : item.isReelHealth
                                ? 'ReelHealth'
                                : item.isPrimary ? '911 Covid' : item.isCLXHealth ? 'CLXHealth' : '',
            }));
        SetPrintSampleLabel(updatedAppointments)
        setAppointments(updatedAppointments);
        setLoading(false);
    };
    const CheckinFunctions = async (
        id: any[],
        qbenchId: string | undefined
    ) => {
        setLoading(true);
        await userCheckin({ qbenchIds: id })
            .then(async (e: any) => {
                loadTableViaQRScanner(qbenchId, true);
            })
            .catch((e) => setLoading(false));
    };
    const GetAllAppointmentDataHear = async (id: any) => {
        updateParams({ ...params, confirmationId: id });
        setcheckIn(true);
        const response: any = await getAppointmentdata({ confirmationId: id });
        if (response.data.length > 0) {
            if (response.data && response.data.length) {
                response.data[0]?.minors?.map((e: any) => {
                    e.birthDate =
                        e.birthDate !== null &&
                            e.birthDate !== undefined &&
                            Object.keys(e.birthDate).length > 0
                            ? parse(e.birthDate, config.dateFormat, new Date())
                            : new Date();
                });
            }
            setEditAppointment(response.data);
            setSelectedRowKeys(response.data);
            return response.data;
        }
        else {
            setalertbox({ open: true, type: 'error', msg: 'Appointment Not Found' });
            setEditAppointment([]);
            setAppointments([]);
            setSelectedRowKeys([]);
            setcheckIn(false);
            return response.data;
        }
    };
    const handlQRCode = (data: any) => {
        if (data !== null && data !== undefined && data !== '') {
            let myArray = data.split('=')[1];
            myArray = decryptString(myArray?.replaceAll(' ', '+'));
            myArray && GetAllAppointmentDataHear(myArray);
            setQRCodeModel(false);
        }
    };
    const filderNullDate = (e: any) => e.date || params.confirmationId !== '';
    const history = useHistory();
    useEffect(() => {
        let name: any = new URLSearchParams(search).get('Id');
        name = name?.replaceAll(' ', '+');
        if (name !== undefined && name !== null && name !== '') {
            GetAllAppointmentDataHear(decryptString(name));
            let localCheckIn = window.localStorage.getItem('checkInPath');
            if (localCheckIn === 'true') {
                history.push('/dashboard/DayWiseAppointments');
                window.localStorage.setItem('checkin', '');
            } else {
                history.push('/');
            }
        }
    }, []);

    useEffect(() => {
        if (!toggle) {
            params.toggle = '';
        } else {
            params.toggle = 'all';
        }
    }, [toggle]);

    // toggle button enable & disable condition
    useEffect(() => {

        if (searchKeys.locationQbenchId == null) {
            searchKeys.locationQbenchId = 0;
        }

        if (searchKeys.sampleId == null) {
            searchKeys.sampleId = 0;
        }
        if (searchKeys.locationQbenchId == 0 &&
            searchKeys.firstName == '' &&
            searchKeys.lastName == '' &&
            searchKeys.confirmationId == '' &&
            searchKeys.birthDate == '' &&
            searchKeys.email == '' &&
            searchKeys.phone == '' &&
            !searchKeys.sampleId &&
            searchKeys.date == '' &&
            searchKeys.thirdPartyAppointments == '' &&
            searchKeys.status == '') {
            setToggleData(false)
            setEmpty(true)
            handleSearch()
        } else {
            setEmpty(false)
        }
    }, [searchKeys])

    useEffect(() => {

        if (params.locationQbenchId == null) {
            params.locationQbenchId = 0;
        }
        if(params.sampleId == NaN){
            params.sampleId = 0
        }
        if (params.sampleId == null) {
            params.sampleId = 0;
        }

        if (params.thirdPartyAppointments == null) {
            params.thirdPartyAppointments = ""
        }
        if (
            params.locationQbenchId !== 0 ||
            params.firstName !== '' ||
            params.lastName !== '' ||
            params.confirmationId !== '' ||
            params.birthDate !== '' ||
            params.email !== '' ||
            params.phone !== '' ||
            params.sampleId ||
            params.date !== '' ||
            (params.toggle !== '' && toggle) ||
            params.status !== '' ||
            params.thirdPartyAppointments !== ''
        ) {
            if(!intialSearch) {
                setIntialSearch(false)
            }
            setLoading(true);
            getDayWiseAppointmentsSearchData(params)
                .then(({ data }) => {
                    const updatedAppointments = data?.map((e:any) => ({
                        ...e, airlineCode:e?.airlineCode ? e?.airlineCode : '', 
                        departureDateAndTime:e?.departureDateAndTime ? e?.departureDateAndTime : '', 
                        checkInTime: e?.checkInTime ? e?.checkInTime : '',
                        noShow: e?.noShow ? e?.noShow : '',
                        phone: e?.phone ? e?.phone :'',
                        results: e?.results ? e?.results : '',
                        room: e?.room ? e?.room : '',
                        collectionmethod: e?.collectionmethod ? e?.collectionmethod : '',
                        travelType:e?.travelType ? e?.travelType : '',
                        destination: e?.destination ? typeof(e?.destination) === 'string' ? e?.destination : e?.destination.value ?? '' :'',
                        }))
                        .filter(filderNullDate)
                        .sort(sortByTime)
                        .sort(sortByDate)
                        .map((item: any) => ({
                            ...item,
                            period:
                                // item.isRapidTest || item.isRapidTest == 'TRUE'
                                //     ? item.period !== null && item.period >= 0
                                //         ? rapidPeriods[item.period]?.label
                                //         : '-'
                                //     : item.period !== null && item.period >= 0
                                //         ? periods[item.period]?.label
                                //         : '-',
                                (item?.isSchedularLite ||  item?.isWalkUpTest|| item?.isQbenchAppt) ? item?.slot?.label ?? '' :
                                item !== null &&
                                    item !== undefined &&
                                    item?.slot !== null &&
                                    item?.slot !== undefined
                                    ? item?.slot?.label
                                    : '-',
                            // isExpressSameDayTest: item.isExpressSameDayTest ? 'Yes' : 'No'
                            testType: item.isRapidTest
                                ? 'Rapid PCR Test'
                                : item.isExpressSameDayTest
                                    ? 'Express PCR Test'
                                    : item?.isAntigen
                                        ? 'Antigen Test'
                                        : item?.isPanel
                                         ? 'Flu A&B/Covid-19'
                                        : 'Standard PCR Test',
                            status: item.isCanceled ? 'Cancelled' :item.results===null?'': item.results !== '' ? 'Completed' : '',
                            isRapidTestText: item.isRapidTest ? 'YES' : '',
                            SolvHealth:item.isSolvHealth
                            ? 'SolvHealth'
                            : item.isReelHealth
                                ? 'ReelHealth'
                                : item.isPrimary ? '911 Covid' : item.isCLXHealth ? 'CLXHealth' : '',
                        }));

                    console.log('updatedAppointments', updatedAppointments);
                    setAppointments(updatedAppointments);
                    setLoading(false);
                })
                .catch((err) => {
                    if(intialSearch === false){
                        console.log("pop-up")
                    }
                    else{
                        alert(config.daywiseLocationSearchAlert)
                    }
                    console.error("pop-up",err);
                    setLoading(false);
                });
        } else {
            setToggleData(false)
        }

        async function getAdminConfigDaywiseColumn() {
            const tableColumnData = formatTableColumn(
                renderResults,
                renderActionsMenuTitle,
                renderActionsMenu,
                renderCheckin,
                renderTat,
                params.date,
                sortByDate,
                renderNotes,
                getColumnSearchProps
            );
            setRenderTableColumn(tableColumnData);
            let response = await getAdminConfigDaywise({
                email: JSON.parse(window.localStorage.getItem('userDetails')!)
                    .email,
            });

            if (response.data.status == 200) {
                if (response.data.data.length > 0) {
                    if (
                        response.data.data[0].daywiseconfig.hideandshowcolumn
                            .length > 0
                    ) {
                        setRenderTableColumn(
                            formatTableColumn(
                                renderResults,
                                renderActionsMenuTitle,
                                renderActionsMenu,
                                renderCheckin,
                                renderTat,
                                params.date,
                                sortByDate,
                                renderNotes,
                                getColumnSearchProps
                            ).filter((entry) =>
                                includes(
                                    response.data.data[0].daywiseconfig
                                        .hideandshowcolumn,
                                    entry.title
                                )
                            )
                        );
                        setInitialColumnList(
                            response.data.data[0].daywiseconfig
                                .hideandshowcolumn
                        );
                        if(response.data.data[0].daywiseconfig
                            .hideandshowcolumn) {
                            setdataList(response.data.data[0].daywiseconfig
                            .hideandshowcolumn)

                        }
                    }
                } else {
                    const tableColumnData = formatTableColumn(
                        renderResults,
                        renderActionsMenuTitle,
                        renderActionsMenu,
                        renderCheckin,
                        renderTat,
                        params.date,
                        sortByDate,
                        renderNotes,
                        getColumnSearchProps
                    );
                    setRenderTableColumn(tableColumnData);
                }
            } else {
                const tableColumnData = formatTableColumn(
                    renderResults,
                    renderActionsMenuTitle,
                    renderActionsMenu,
                    renderCheckin,
                    renderTat,
                    params.date,
                    sortByDate,
                    renderNotes,
                    getColumnSearchProps
                );
                setRenderTableColumn(tableColumnData);
            }
        }

        getAdminConfigDaywiseColumn();
    }, [params, setAppointments, Editappointments]);


    useEffect(() => {
        const dayWiseDataRetain = () => {
            const searchFieldQueries = `[${localStorage.getItem(
                'dayWiseSearchKeys'
            )}]`;

            try {
                const dayWiseFormData: AppointmentsListParams[] = JSON.parse(
                    searchFieldQueries
                );
                if (dayWiseFormData) {
                    setDayWiseLocalData(dayWiseFormData[0]);
                }
            } catch (error) {
                console.log(error);
            }
        };

        dayWiseDataRetain();
    }, []);

    const getDay = () => {
        setLoading(true);
        // handleSearch();
        // setLoading(false);
        setSelectedRowKeys([]);

        getDayWiseAppointmentsSearchData(params)
            .then(({ data }: any) => {
                // console.log('updatedAppointments',data)

                const updatedAppointments = data
                    .sort(sortByDate)
                    .map((item: any) => ({
                        ...item,
                        period:
                        (item?.isSchedularLite ||  item?.isWalkUpTest|| item?.isQbenchAppt) ? item?.slot?.label ?? '' :
                            item.isRapidTest || item.isRapidTest == 'TRUE'
                                ? item.period !== null && item.period >= 0
                                    ? rapidPeriods[item.period].label
                                    : '-'
                                : item.period !== null && item.period >= 0
                                    ? periods[item.period].label
                                    : item.isSolvHealth
                                    ? item.slot.label
                                    : '-',
                        // isExpressSameDayTest: item.isExpressSameDayTest ? 'Yes' : 'No'
                        testType: item.isRapidTest
                            ? 'Rapid PCR Test'
                            : item.isExpressSameDayTest
                                ? 'Express PCR Test'
                                : item?.isAntigen
                                    ? 'Antigen Test'
                                    : item?.isPanel
                                     ? 'Flu A&B/Covid-19'
                                    : 'Standard PCR Test',
                        status: item.isCanceled ? 'Cancelled' :item.results===null?'': item.results !== '' ? 'Completed' : '',
                        isRapidTestText: item.isRapidTest ? 'YES' : '',
                        SolvHealth:item.isSolvHealth
                            ? 'SolvHealth'
                            : item.isReelHealth
                                ? 'ReelHealth'
                                : item.isPrimary ? '911 Covid' : item.isCLXHealth ? 'CLXHealth' : '',
                    }));
                setAppointments(updatedAppointments);

                setLoading(false);
            })
            .catch((err: any) => {
                console.error(err);
                setLoading(false);
            });
    };

    const handleSearchKeysUpdate = (key: string) => (value: string | number) => {
        setSearchKeys({
            ...searchKeys,
            [key]: value,
        });

        localStorage.setItem(
            'dayWiseSearchKeys',
            JSON.stringify({ ...searchKeys, [key]: value })
        );
    };

    const handleHover = () => {
        setmouseover(true);
    };

    const handleHoverout = () => {
        setmouseover(false);
    };

    const getALDDReport = () => {
        let params = {
            date: searchKeys.date,
        };
        setalddLoading(true);
        getALDDReportByDate(searchKeys)
            .then(({ data }) => {
                const updatedAppointments = data
                    .sort(sortByDate)
                    .map((item: any) => ({
                        ...item,
                        period:
                            item.isRapidTest || item.isRapidTest == 'TRUE'
                                ? item.period !== null && item.period >= 0
                                    ? (item?.location?.qbenchCustomerId ===220 || item.period>15) ? periods[item.period].label : rapidPeriods[item.period].label
                                    : '-'
                                : item.period !== null && item.period >= 0
                                    ? periods[item.period].label
                                    : item.isSolvHealth
                                    ? item.slot.label
                                    : '-',
                        hadContact: item.hadContact ? 'Yes' : 'No',
                        testType: item.isRapidTest
                            ? 'Rapid PCR Test'
                            : item.isExpressSameDayTest
                                ? 'Express PCR Test'
                                : item?.isAntigen
                                    ? 'Antigen Test'
                                    : item?.isPanel
                                    ? 'Flu A&B/Covid-19'
                                    : 'Standard PCR Test',
                        isCanceled: item.isCanceled ? 'Cancelled' : '',
                        isRapidTestText: item.isRapidTest ? 'YES' : '',
                        SolvHealth:item.isSolvHealth
                            ? 'SolvHealth'
                            : item.isReelHealth
                                ? 'ReelHealth'
                                : item.isPrimary ? '911 Covid' : item.isCLXHealth ? 'CLXHealth' : '',
                    }));
                setalddLoading(false);
                generateALDDReport(updatedAppointments);
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
            });
    };
    const handleSearch = () => {
        setIntialSearch(true)
        updateParams(toggle ? searchKeys : { ...searchKeys, toggle: '' });
        setSelectedRowKeys([]);
        setQbenchData([]);
        SetPrintSampleLabel([]);
        setAppointments([]);
    };

    const rowSelectionData = {
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            console.log(
                'updatedAppointments',
                selectedRowKeys,
                selectedRowKeys.length
            );
            if (selectedRowKeys.length >= 2) {
                const data: any = appointments?.map((item: any, index: any) => {
                    if (selectedRowKeys.length >= 2) {
                        return {
                            ...item,
                            disable: true,
                        };
                    } else {
                        return {
                            ...item,
                            disable: false,
                        };
                    }
                });
                setAppointments(data);
            } else {
                const data: any = appointments?.map((item: any, index: any) => {
                    console.log('check', selectedRowKeys.length >= 2);
                    if (selectedRowKeys.length >= 2) {
                        return {
                            ...item,
                            disable: true,
                        };
                    } else {
                        return {
                            ...item,
                            disable: false,
                        };
                    }
                });
                setAppointments(data);
            }
            console.log('updatedAppointments', appointments);

            let qbenchArray: number[] = [];
            {
                appointments &&
                    selectedRowKeys.map((data: any) =>
                        qbenchArray.push(Number(appointments[data].qbenchId))
                    );
            }
            setSelectedRowKeys(selectedRowKeys);
            setQbenchData(qbenchArray);
            SetPrintSampleLabel(selectedRows);
        },
        getCheckboxProps: (record: any) => ({
            disabled:
                record.isCanceled === 'Canceled' ||
                record.isCanceled === 'Cancelled',
            name: record.confirmationId,
        }),
        getButtonProps: (record: any) => ({
            disabled: true,
        }),
    };

    const handleFilterSearch = (selectedKeys: any, confirm: any) => {
        confirm();
        setsearchText(selectedKeys[0]);
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
        setsearchText('');
    };

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const [switchCamera, setswitchCamera] = useState<boolean>(false);

    const [form] = Form.useForm();

    const onFill = () => {
        let locationValue;
        let bookedDate;
        let selectedBirthDate;
        let enteredSampleId;

        if (dayWiseLocalData.locationQbenchId !== null &&
            dayWiseLocalData.locationQbenchId !== undefined &&
            dayWiseLocalData.locationQbenchId !== 0) {
            locationValue =
                locations !== null &&
                locations !== undefined &&
                locations?.filter(
                    (place:any) =>
                        place.qbenchCustomerId ===
                        dayWiseLocalData.locationQbenchId
                )?.[0]?.name;
        }

        if (dayWiseLocalData.date !== '') {
            bookedDate = parse(
                dayWiseLocalData.date,
                config.dateFormat,
                new Date()
            );
        }
        if (dayWiseLocalData.birthDate !== '') {
            selectedBirthDate = parse(
                dayWiseLocalData.birthDate,
                config.dateFormat,
                new Date()
            );
        }

        if (dayWiseLocalData.sampleId !== 0 &&
            dayWiseLocalData.sampleId !== null &&
            dayWiseLocalData.sampleId !== undefined) {
            enteredSampleId = dayWiseLocalData.sampleId
        }

        form.setFieldsValue({
            date: bookedDate,
            location: locationValue,
            confirmationId: dayWiseLocalData.confirmationId,
            firstName: dayWiseLocalData.firstName,
            lastName: dayWiseLocalData.lastName,
            email: dayWiseLocalData.email,
            phone: dayWiseLocalData.phone,
            birthDate: selectedBirthDate,
            sampleId: enteredSampleId,
            thirdPartyAppointments: dayWiseLocalData.thirdPartyAppointments,
            status: dayWiseLocalData.status,
        });

        setSearchKeys({
            ...searchKeys,
            date: dayWiseLocalData.date,
            locationQbenchId: dayWiseLocalData.locationQbenchId,
            confirmationId: dayWiseLocalData.confirmationId,
            firstName: dayWiseLocalData.firstName,
            lastName: dayWiseLocalData.lastName,
            email: dayWiseLocalData.email,
            phone: dayWiseLocalData.phone,
            birthDate: dayWiseLocalData.birthDate,
            sampleId: dayWiseLocalData.sampleId,
            thirdPartyAppointments: dayWiseLocalData.thirdPartyAppointments,
            status: dayWiseLocalData.status,
        });
    };

    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={alertbox.open}
                autoHideDuration={6000}
                onClose={() => setalertbox({ open: false, type: '', msg: '' })}
            >
                <Alert
                    onClose={() =>
                        setalertbox({ open: false, type: '', msg: '' })
                    }
                    severity={alertbox.type}
                >
                    {alertbox.msg}
                </Alert>
            </Snackbar>
            {!ShowEditAppointmentModal ? (
                <Layout
                    style={{
                        marginTop: '100px',
                        backgroundColor: '#FFF',
                        border: '1px solid #f3eeee',
                        boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
                    }}
                >
                    <div
                        style={{
                            borderRadius: '3px',
                            background: 'linear-gradient(60deg, #00acc1, #00acc1)',
                            boxShadow:
                                '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)',
                            margin: '0px 25px',
                            marginTop: '-25px',
                        }}
                    >
                        <Title level={4} style={{ margin: '24px 16px', color: '#FFF' }}>
                            Day Wise Appointments
                        </Title>
                    </div>
                    {qRCodeModel && (
                        <>
                            <Modal
                                maxWidth={600}
                                open={qRCodeModel ? qRCodeModel : false}
                                onClose={handleCancelQRCode}
                                closeOnOverlayClick={true}
                                closeOnEsc
                            >
                                <h3>QR Code Auto Check IN</h3>
                                <hr />
                                <br />
                                {qRCodeModel && (
                                    <>
                                        <BarcodeScannerComponent
                                            onError={(err: any) => {
                                                setswitchCamera(true);
                                            }}
                                            facingMode={
                                                switchCamera
                                                    ? 'user'
                                                    : 'environment'
                                            }
                                            delay={100}
                                            onUpdate={(
                                                err: any,
                                                result: any
                                            ) => {
                                                if (result)
                                                    handlQRCode(
                                                        result.text.replaceAll(
                                                            ' ',
                                                            '+'
                                                        )
                                                    );
                                                else
                                                    console.log(
                                                        'camera Err',
                                                        err
                                                    );
                                            }}
                                        />
                                    </>
                                )}
                                <hr />
                                <div
                                    style={{
                                        textAlign: 'right',
                                        marginRight: '-8px',
                                    }}
                                >
                                    <Button
                                        type="primary"
                                        onClick={() => setQRCodeModel(false)}
                                        size="middle"
                                        style={{ margin: '0pc 10px 0px 10px' }}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </Modal>
                        </>
                    )}
                    {checkIn && (
                        <>
                            {Editappointments !== null &&
                                Editappointments !== undefined &&
                                Editappointments?.length > 0 ? (
                                <CheckInModal
                                    modalVisible={checkIn}
                                    setModalVisible={setcheckIn}
                                    handleModalConfirm={() => {
                                        setcheckIn(true);
                                        setQRCodeData({
                                            ...QRCodeData,
                                            autocheckinprogress: true,
                                        });
                                        setEditAppointment(null);
                                        let data: any[] = [];
                                        data.push(
                                            Editappointments[0]?.qbenchId
                                        );
                                        CheckinFunctions(
                                            data,
                                            Editappointments[0]?.qbenchId
                                        ).then((e) => {
                                            setcheckIn(false);
                                        });
                                        setOpenPrintModal(true)
                                    }}
                                    handleModalCancel={() => {
                                        loadTableViaQRScanner(
                                            Editappointments[0]?.qbenchId,
                                            false
                                        );
                                        setcheckIn(false);
                                        setEditAppointment(null);
                                        setSelectedRowKeys([]);
                                        setQbenchData([]);
                                        SetPrintSampleLabel([]);
                                        setAppointments([]);
                                    }}
                                    childComp={
                                        <EditAppointment
                                            isCheckIn={true}
                                            appointment={Editappointments}
                                            handleDrawerToggle={
                                                handleEditAppointment
                                            }
                                            apptType={false}
                                            fromApptList={false}
                                            handleSearch={handleSearch}
                                        />
                                    }
                                    proceedLogin={true}
                                ></CheckInModal>
                            ) : (
                                <Modal
                                    maxWidth={600}
                                    open={checkIn ? checkIn : false}
                                    onClose={handleCancelCheckIn}
                                    closeOnOverlayClick={false}
                                    closeOnEsc
                                >
                                    <h2>
                                        <b>Verification for Check In</b>
                                    </h2>
                                    <hr />
                                    <br />
                                    <h2 style={{ textAlign: 'center' }}>
                                        Processing{' '}
                                        {QRCodeData.autocheckinprogress
                                            ? ' Check In'
                                            : ' Appointment...'}{' '}
                                        <br /> Please Wait... <br />
                                        <Spin />
                                    </h2>
                                </Modal>
                            )}
                        </>
                    )}
                    <Content style={styles.content}>
                        <Space direction="vertical" style={styles.spaceWidth} size="middle">
                            <Card bodyStyle={styles.card}>
                                <Form form={form} layout={'vertical'}>
                                    <Row
                                        gutter={{
                                            xs: 8,
                                            sm: 16,
                                            md: 24,
                                            lg: 32,
                                            xl: 40,
                                            xxl: 48,
                                        }}
                                    >
                                        <Col
                                            xs={{ span: 24 }}
                                            sm={{ span: 24 }}
                                            md={{ span: 12 }}
                                            lg={{ span: 8 }}
                                        >
                                            <Form.Item label="Date" name="date">
                                                <AntDDatePicker
                                                    style={styles.fullWidth}
                                                    disabled={loading}
                                                    showToday
                                                    allowClear
                                                    format={dateFormat}
                                                    placeholder="YYYY-DD-MM"
                                                    onChange={(newDate) =>
                                                        handleSearchKeysUpdate(
                                                            'date'
                                                        )(
                                                            newDate
                                                                ? format(
                                                                    newDate!,
                                                                    config.dateFormat
                                                                )
                                                                : ''
                                                        )
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col
                                            xs={{ span: 24 }}
                                            sm={{ span: 24 }}
                                            md={{ span: 12 }}
                                            lg={{ span: 8 }}
                                        >
                                            <Form.Item label="Location" name="location">
                                                <Select
                                                    disabled={loading || !locations}
                                                    allowClear
                                                    loading={!locations}
                                                    placeholder="Please select"
                                                    listHeight={200}
                                                    showArrow
                                                    showSearch
                                                    optionFilterProp="children"
                                                    virtual={false}
                                                    filterOption={(input: any, option: any) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    filterSort={(optionA: any, optionB: any) =>
                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                    }
                                                    onChange={handleSearchKeysUpdate('locationQbenchId')}
                                                >
                                                    {filterLocations?.map((location:any, i:number) => (
                                                        <Option
                                                            key={i}
                                                            value={location.qbenchCustomerId}
                                                        >
                                                            {location.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col
                                            xs={{ span: 24 }}
                                            sm={{ span: 24 }}
                                            md={{ span: 12 }}
                                            lg={{ span: 8 }}
                                        >
                                            <Form.Item label="Confirmation No" name="confirmationId">
                                                <Input
                                                    value={
                                                        params.confirmationId
                                                    }
                                                    allowClear
                                                    disabled={loading}
                                                    onChange={(event) =>
                                                        handleSearchKeysUpdate('confirmationId')(
                                                            event.target.value
                                                        )
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                        {expand && (
                                            <>
                                                <Col
                                                    xs={{ span: 24 }}
                                                    sm={{ span: 24 }}
                                                    md={{ span: 12 }}
                                                    lg={{ span: 8 }}
                                                >
                                                    <Form.Item label="First Name" name="firstName">
                                                        <Input
                                                            value={
                                                                params.firstName
                                                            }
                                                            disabled={loading}
                                                            allowClear
                                                            onChange={(event) =>
                                                                handleSearchKeysUpdate('firstName')(
                                                                    event.target.value
                                                                )
                                                            }
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col
                                                    xs={{ span: 24 }}
                                                    sm={{ span: 24 }}
                                                    md={{ span: 12 }}
                                                    lg={{ span: 8 }}
                                                >
                                                    <Form.Item label="Last Name" name="lastName">
                                                        <Input
                                                            value={
                                                                params.lastName
                                                            }
                                                            disabled={loading}
                                                            allowClear
                                                            onChange={(event) =>
                                                                handleSearchKeysUpdate('lastName')(
                                                                    event.target.value
                                                                )
                                                            }
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col
                                                    xs={{ span: 24 }}
                                                    sm={{ span: 24 }}
                                                    md={{ span: 12 }}
                                                    lg={{ span: 8 }}
                                                >
                                                    <Form.Item label="Email" name="email">
                                                        <Input
                                                            value={params.email}
                                                            disabled={loading}
                                                            allowClear
                                                            onChange={(event) =>
                                                                handleSearchKeysUpdate('email')(
                                                                    event.target.value
                                                                )
                                                            }
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col
                                                    xs={{ span: 24 }}
                                                    sm={{ span: 24 }}
                                                    md={{ span: 12 }}
                                                    lg={{ span: 8 }}
                                                >
                                                    <Form.Item label="Phone" name="phone">
                                                        <Input
                                                            value={params.phone}
                                                            allowClear
                                                            disabled={loading}
                                                            onChange={(event) =>
                                                                handleSearchKeysUpdate('phone')(
                                                                    event.target.value
                                                                )
                                                            }
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col
                                                    xs={{ span: 24 }}
                                                    sm={{ span: 24 }}
                                                    md={{ span: 12 }}
                                                    lg={{ span: 8 }}
                                                >
                                                    <Form.Item label="Date of birth" name="birthDate">
                                                        <AntDDatePicker
                                                            style={
                                                                styles.fullWidth
                                                            }
                                                            disabled={loading}
                                                            showToday
                                                            allowClear
                                                            format={dateFormat}
                                                            placeholder="YYYY-DD-MM"
                                                            onChange={(
                                                                newDate: any
                                                            ) =>
                                                                handleSearchKeysUpdate(
                                                                    'birthDate'
                                                                )(
                                                                    newDate
                                                                        ? format(
                                                                            new Date(
                                                                                newDate
                                                                            )!,
                                                                            config.dateFormat
                                                                        )
                                                                        : ''
                                                                )
                                                            }
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col
                                                    xs={{ span: 24 }}
                                                    sm={{ span: 24 }}
                                                    md={{ span: 12 }}
                                                    lg={{ span: 8 }}
                                                >
                                                    <Form.Item label="SampleID" name="sampleId">
                                                        <Input
                                                            disabled={loading}
                                                            allowClear
                                                            onChange={(event) =>
                                                                handleSearchKeysUpdate('sampleId')(
                                                                    parseInt(event.target.value ? event.target.value : "0")
                                                                )
                                                            }
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col
                                                    xs={{ span: 24 }}
                                                    sm={{ span: 24 }}
                                                    md={{ span: 12 }}
                                                    lg={{ span: 8 }}
                                                >
                                                    <Form.Item
                                                        label="Third-Party Appointments"
                                                        name="thirdPartyAppointments"
                                                    >
                                                        <Select
                                                            disabled={loading}
                                                            allowClear
                                                            showArrow
                                                            placeholder="Please Select"
                                                            onChange={handleSearchKeysUpdate(
                                                                'thirdPartyAppointments'
                                                            )}
                                                        >
                                                            <Option value="isPrimary">
                                                                {' '}
                                                                911 Covid
                                                            </Option>
                                                            <Option value="isSolvHealth">
                                                                SolvHealth
                                                            </Option>
                                                            {/* <Option value="isSolvHealth">
                                                                SolvHealth
                                                            </Option>
                                                            <Option value="isReelHealth">
                                                                Reel Health
                                                            </Option> */}
                                                            {/* <Option value="isCLXHealth">
                                                                CLX Health
                                                            </Option> */}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col
                                                    xs={{ span: 24 }}
                                                    sm={{ span: 24 }}
                                                    md={{ span: 12 }}
                                                    lg={{ span: 8 }}
                                                >
                                                    <Form.Item
                                                        label="Status"
                                                        name="status"
                                                    >
                                                        <Select
                                                            disabled={loading}
                                                            allowClear
                                                            showArrow
                                                            placeholder="Please Select"
                                                            onChange={handleSearchKeysUpdate(
                                                                'status'
                                                            )}
                                                        >
                                                            <Option value="completed">
                                                                Completed
                                                            </Option>
                                                            <Option value="cancelled">
                                                                Cancelled
                                                            </Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </>
                                        )}
                                    </Row>
                                    <Row>
                                        <Col
                                            xs={{ span: 24 }}
                                            sm={{ span: 24 }}
                                            md={{ span: 12 }}
                                            lg={{ span: 8 }}
                                        >
                                            <div style={{ display: 'flex' }}>
                                                <Switch
                                                    disabled={loading || empty}
                                                    onClick={toggler}
                                                    checked={toggle}
                                                    onChange={() => {
                                                        handleSearchKeysUpdate(
                                                            'toggle'
                                                        )('all');
                                                    }}
                                                />
                                                <p
                                                    style={{
                                                        paddingLeft: '15px',
                                                    }}
                                                >
                                                    View Checked In Appointments
                                                </p>
                                            </div>
                                        </Col>

                                        <Col
                                            span={18}
                                            style={{ marginLeft: expand ? '0%' : '65%' }}
                                        ></Col>

                                        <Col span={6} style={{ marginLeft: expand ? '0%' : '65%' }}>
                                            <Form.Item {...searchBarActionLayout}>
                                                <Space size="small" style={styles.searchBarActions}>
                                                    {/* <Button
                                type="primary"
                                loading={loading}
                                onClick={handleScan}
                            >
                                Scan QR Code
                            </Button> */}
                                                    <Button
                                                        type="primary"
                                                        loading={loading}
                                                        onClick={handleSearch}
                                                    >
                                                        Search
                                                    </Button>
                                                    <Button
                                                        type="link"
                                                        htmlType="button"
                                                        onClick={onFill}
                                                    >
                                                        Refill form
                                                    </Button>
                                                    {/* <Button
													type="link"
													onClick={() => {
														setExpand(!expand);
													}}
												>
													{expand ? (
														<>
															Less <UpOutlined />
														</>
													) : (
														<>
															More <DownOutlined />
														</>
													)}
												</Button> */}
                                                </Space>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card>

                            <Card>
                                <Button
                                    type="primary"
                                    onClick={showModal}
                                    size="middle"
                                    style={{ margin: '0pc 10px 0px 10px' }}
                                >
                                    Show/Hide Column
                                </Button>
                                {/* <div style={{margin:"0pc 10px 0px 10px"}} /> */}
                                <Button
                                    type="primary"
                                    loading={alddloading}
                                    onClick={getALDDReport}
                                    onMouseOver={handleHover}
                                    onMouseDown={handleHoverout}
                                    onMouseOut={handleHoverout}
                                    onMouseEnter={handleHover}
                                    style={{ margin: '0pc 10px 0px 10px' }}
                                >
                                    {mouseover ? 'All Locations Daily Download' : 'ALDD'}
                                </Button>
                            </Card>

                            <Modal
                                maxWidth={1000}
                                open={isModalVisible ? isModalVisible : false}
                                onClose={handleCancel}
                                closeOnOverlayClick={false}
                                closeOnEsc
                            >
                                <h3>Show/Hide Column</h3>
                                <hr />
                                <br />
                                <Formik
                                    initialValues={{
                                        // showColumns: formatTableColumn(renderResults, renderActionsMenuTitle, renderActionsMenu, renderCheckin).filter(entry => entry.key != "actions").map((entry: any) => entry.title),
                                        showColumns: initialColumnList,
                                    }}
                                    onSubmit={async (values) => { }}
                                >
                                    {({ values }) => (
                                        <ColumnFilterForm>
                                            <div
                                                role="group"
                                                aria-labelledby="checkbox-group"
                                                style={{
                                                    display: 'grid',
                                                    gridGap: '1em',
                                                    gridTemplateColumns: isLargeScreen
                                                        ? '1fr 1fr 1fr'
                                                        : '1fr 1fr',
                                                    justifyContent: 'space-evenly',
                                                }}
                                            >
                                                {formatTableColumn(
                                                    renderResults,
                                                    renderActionsMenuTitle,
                                                    renderActionsMenu,
                                                    renderCheckin,
                                                    renderTat,
                                                    params.date,
                                                    sortByDate,
                                                    renderNotes,
                                                    getColumnSearchProps
                                                )
                                                    .map((entry: any) => entry.title)
                                                    .map((element) => {
                                                        // console.log("element", element);
                                                        return (
                                                            <label
                                                                onChange={(e:any)=>{
                                                                    setInitialColumnList((array:any[]):any[]=>{
                                                                        if(array.includes(element))
                                                                            return array.filter(e=>e!==element)
                                                                        return [...array,element]
                                                                    })
                                                                }}
                                                            >
                                                                <Field
                                                                    type="checkbox"
                                                                    name="showColumns"
                                                                    value={element}
                                                                />
                                                                &nbsp;&nbsp;&nbsp;
                                                                {element}&nbsp;&nbsp;&nbsp;
                                                            </label>
                                                            // <Checkbox checked={false} onChange={()=>{}}>{element}</Checkbox>
                                                        );
                                                    })}
                                            </div>
                                            <span
                                                style={{ display: 'flex', justifyContent: 'flex-end' }}
                                            >
                                                <Button
                                                    style={{ margin: '10px' }}
                                                    type="default"
                                                    // loading={loading}
                                                    onClick={() => {
                                                        setIsModalVisible(false);
                                                        setInitialColumnList(dataList)
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    type="primary"
                                                    style={{ margin: '10px' }}
                                                    loading={loading}
                                                    onClick={async () => {
                                                        setLoading(true)
                                                        await sleep(500);
                                                        let displayColumns: any = [];
                                                        let displayColumnsEntry: any = [];
                                                        formatTableColumn(
                                                            renderResults,
                                                            renderActionsMenuTitle,
                                                            renderActionsMenu,
                                                            renderCheckin,
                                                            renderTat,
                                                            params.date,
                                                            sortByDate,
                                                            renderNotes,
                                                            getColumnSearchProps
                                                        ).forEach((entry: any) => {
                                                            if (entry.key == 'actions') {
                                                                if (typeof entry.title === 'string') {
                                                                    displayColumns.push(entry);
                                                                    displayColumnsEntry.push(entry.title);
                                                                }
                                                            } else {
                                                                if (values.showColumns.includes(entry.title)) {
                                                                    entry['visible'] = true;
                                                                    displayColumns.push(entry);
                                                                    displayColumnsEntry.push(entry.title);
                                                                }
                                                            }
                                                        });
                                                        await insertAdminConfigDaywise({
                                                            email: JSON.parse(
                                                                window.localStorage.getItem('userDetails')!
                                                            ).email,
                                                            role: 'admin',
                                                            hideandshowcolumn: displayColumnsEntry,
                                                        });
                                                        setdataList(displayColumnsEntry)
                                                        setRenderTableColumn(displayColumns);
                                                        setIsModalVisible(false);
                                                        setLoading(false)
                                                    }}
                                                >
                                                    Apply
                                                </Button>
                                            </span>
                                        </ColumnFilterForm>
                                    )}
                                </Formik>
                            </Modal>

                            <Card>
                                {/* {(!params.confirmationId && !params.locationQbenchId) && (
								<Alert
									message="Please select a location or Enter ConfirmationNo"
									type="warning"
									showIcon
									closable
								/>
							)} */}

                                <div style={{ paddingBottom: '25px' }}>
                                    <ActionBox getDay={()=>{setSelectedRowKeys([])}} handleSearch={handleSearch} />

                                    <TableActionContainer>
                                        {!!appointments && appointments.length > 0 && (
                                            <Check_In
                                                selectedRowKeys={selectedRowKeys}
                                                qbenchId={qbenchData}
                                                disabledCheckin={false}
                                                handlePrintModal={handlePrintModal}
                                                SerachAppointment={SerachAppointment}
                                                rowCheckIn={false}
                                                params={params}
                                                handleSearch={handleSearch}
                                            />
                                        )}
                                        <DownloadCSV columns={renderTableColumn.map(e=>e.title)}/>
                                    </TableActionContainer>

                                    <Table
                                        sticky={true}
                                        size="small"
                                        columns={renderTableColumn as any}
                                        dataSource={(dataSourceAppointments as any) || []}
                                        rowSelection={{
                                            type: selectionType,
                                            selectedRowKeys,
                                            ...rowSelectionData,
                                        }}
                                        onChange={(value) => {
                                            // console.log('valueappt', value);
                                        }}
                                        loading={loading}
                                        scroll={{ x: 1500 }}
                                        rowKey={(record: any) => record.key}
                                        // rowSelection={rowSelection}
                                        rowClassName={(record: any) =>
                                            record.status ===
                                                'Cancelled'
                                                ? classes.cancel
                                                : record.testType ===
                                                    'Rapid PCR Test'
                                                    ? classes.rapid
                                                    : record.status ===
                                                        'Cancelled'
                                                        ? classes.cancel
                                                        : record.testType ===
                                                            'Express PCR Test'
                                                            ? classes.express
                                                            : record.status ===
                                                                'Cancelled'
                                                                ? classes.cancel
                                                                : record.testType ===
                                                                    'Antigen Test'
                                                                    ? classes.antigen
                                                                    : record.status ===
                                                                        'Cancelled'
                                                                        ? classes.cancel
                                                                        : classes.standard
                                        }
                                        pagination={{
                                            pageSizeOptions:['10','25','50','100','150','200','250','300','350','400'],
                                            showSizeChanger: true
                                        }}
                                    />
                                    <>
                                        <PrintSampleLabels
                                            btnShow={false}
                                            open={openDialogModal}
                                            setOpen={(data) =>
                                                setOpenDialogModal(data)
                                            }
                                            handleSearch={handleSearch}
                                        />
                                        <PrintModal
                                            open={openPrintModal}
                                            // handleDialogModal={handleDialogModal}
                                            handleClosed={() => {
                                                setSelectedRowKeys([]);
                                                SetPrintSampleLabel([]);
                                                setOpenPrintModal(false);
                                            }}
                                            handleCancel={() => {
                                                setOpenPrintModal(false);
                                            }}
                                            qbenchId={qbenchData}
                                            setOpenSampleModal={setOpenDialogModal}
                                            getDay={()=>{setSelectedRowKeys([])}}
                                            handleSearch={handleSearch}
                                        />
                                    </>
                                </div>
                            </Card>
                        </Space>
                    </Content>
                </Layout>
            ) : (
                <div>
                    <EditAppointment
                        isCheckIn={false}
                        appointment={Editappointments}
                        handleDrawerToggle={handleEditAppointment}
                        apptType={false}
                        fromApptList={true}
                        returnBtn={true}
                        handleSearch={handleSearch}
                    />
                </div>
            )}
        </div>
    );
}

export default AppointmentList;